<template>
  <div>
    <template v-for="(level, index) in levels" :key="index">
      <LevelComponent :info="levelInfo.getLevelInfo(level.id)" :level="level" />
    </template>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import LevelComponent from "../LevelComponent.vue";
import constants from "@/constants";

export default {
  components: { LevelComponent },
  setup() {
    return {
      levelInfo: constants.LEVELS,
    };
  },
  computed: {
    ...mapGetters(["getDataLevels"]),
    levels() {
      return this.getDataLevels;
    },
  },
  beforeMount() {
    this.getLevels();
  },
  data() {
    return {};
  },
  methods: {
    ...mapActions(["getLevels"]),
  },
};
</script>
<style lang="scss" scoped></style>
