<template>
  <footer class="footer">
    <div class="d-flex justify-content-between flex-1 pe-3">
      <router-link to="/app/" class="nav-link">
        <IconBuildingStore />
        {{ $t("menu.home") }}
      </router-link>
      <router-link to="/app/record" class="nav-link">
        <IconCategory />
        {{ $t("menu.record") }}
      </router-link>
    </div>
    <div class="center-button">
      <router-link to="/app/order" class="logo-link"> </router-link>
    </div>
    <div class="d-flex justify-content-between flex-1 ps-3">
      <router-link to="/app/support" class="nav-link">
        <IconHeadphonesFilled />
        {{ $t("menu.support") }}
      </router-link>
      <router-link to="/app/account" class="nav-link">
        <IconUserCog />
        {{ $t("menu.account") }}
      </router-link>
    </div>
  </footer>
</template>
<script>
import {
  IconBuildingStore,
  IconCategory,
  IconHeadphonesFilled,
  IconUserCog,
} from "@tabler/icons-vue";

export default {
  name: "FooterComponent",
  components: { IconBuildingStore, IconCategory, IconHeadphonesFilled, IconUserCog },
};
</script>
<style lang="scss" scoped>
.footer {
  padding: 8px;
  position: fixed;
  bottom: 0;
  width: 100%;
  max-width: 580px;
  margin: auto;
  display: flex;
  justify-content: space-between;
  padding: 16px 0;
  background: #212932;
}

.center-button {
  position: relative;
  width: 75px;
}

.logo-link {
  width: 75px;
  height: 75px;
  background: url("@/assets/logo.png");
  background-size: cover;
  background-position: center center;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 0;
  box-shadow: inset -3px 3px 6px rgba(255, 255, 255, 0.5);

  img {
    width: 38px;
  }
}

.nav-link {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 50%;
  font-size: 12px;
}

.flex-1 {
  flex: 1;
}
</style>
