// state.js
export default {
  users: [],
  products: [],
  levels: [],
  withdrawHistories: [],
  depositHistories: [],
  messages: [],
  conversations: [],
  orders: [],
  bankAccount: null,
  bankList: [],
  user: null,
  loading: false,
  childLoading: false,
  specialOrders: [],
  banners: [],

  //For UpdateData
  updatedData: undefined,
  orderData: undefined,
  reports: null,
  errors: null,
}
