<template>
  <div class="product-img lg border mb-3">
    <img :src="previewImg || defaultImage" />
  </div>
  <div class="mb-3">
    <label for="uploadImage" class="form-label">{{ $t("select_picture") }}</label>
    <input
      class="form-control"
      id="uploadImage"
      type="file"
      accept="image/png, image/jpg, image/jpeg"
      @change="readUrl($event)"
    />
  </div>
</template>
<script>
export default {
  props: {
    defaultImage: String,
  },
  computed: {},
  data() {
    return {
      previewImg: null,
    };
  },
  methods: {
    readUrl(event) {
      const input = event.target;
      if (input.files?.[0]) {
        const file = input.files[0];
        let reader = new FileReader();
        reader.onloadend = () => {
          this.previewImg = reader.result;
        };
        reader.readAsDataURL(file);
        this.$emit("update:image", file);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.product-img {
  width: 60px;
  height: 60px;
  overflow: hidden;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;

  &.lg {
    width: 150px;
    height: 150px;
  }

  img {
    object-fit: cover;
  }
}
</style>
