export default {
  auth: {
    phone_number: "Số điện thoại",
    email: "Email",
  },
  share: "Chia sẻ",
  share_message: "Hãy liên hệ với admin để lấy mã mời!",
  share_description:
    "Tham gia nhóm của tôi mỗi ngày nửa giờ. Giành được 10.000 mỗi tháng chỉ là khởi đầu! Mỗi lời mời là một hạt giống của cơ hội, một bậc thang của sự giàu có và tự do. Hãy bắt đầu hành trình để trở nên giàu có!",
  copy: "Sao chép",
  placeholder: {
    email: "Nhập địa chỉ email của bạn",
    phone: "Nhập số điện thoại của bạn",
    password: "Nhập mật khẩu của bạn",
    account_name: "Tên tài khoản",
    account_number: "Số tài khoản",
    receiver: "Người nhận",
    bank_name: "Tên ngân hàng",
    branch: "Chi nhánh",
    password_confirm: "Xác nhận mật khẩu",
    invite_code: "Mã mời",
    invite_code_input: "Nhập mã mời đối tác",
    old_password: "Mật khẩu cũ",
    new_password: "Mật khẩu mới",
  },
  login: "Đăng nhập",
  register: "Đăng ký",
  close: "Đóng",
  cancel: "Hủy",
  notification: "Thông báo",
  welcome_modal_content:
    `
      Nhân dịp tuần lễ Vàng. Hệ thống đang triển khai chương trình khuyến mại lớn dành cho thành viên khi đăng ký tài khoản thành viên mới thưởng 20$
      Thưởng 28$ cho bên bảo lãnh,khi đăng ký tài khoản ghép đôi bảo lãnh trên hệ thống, và nhận nhiều ưu đãi cùng chương trình. Cảm ơn bạn đã đồng hành cùng Michael - Kors.!!!
    `,
  welcome_content:
    "Chào mừng thành viên đến với nền tảng mua sắm trực tuyến hàng đầu Việt Nam.",
  balance: "Số dư của tôi",
  available_balance: "Số dư khả dụng",
  freeze_balance: "Số dư đóng băng",
  deposit: "Nạp tiền",
  withdraw: "Rút tiền",
  products: "Sản phẩm",
  free_products: "Sản phẩm miễn phí",
  product: {
    price: "Giá",
  },
  invite: "Mời",
  commission_today: "Hoa hồng hôm nay",
  total_revenue: "Tổng doanh thu",
  total_commission: "Tổng hoa hồng",
  team_size: "Kích thước nhóm",
  stay_tuned: "Hãy chờ đợi, nhóm đang lên kế hoạch...",
  minium_balance: "Số dư tối thiểu",
  orders: "Đơn hàng",
  success_order: "đơn thành công",
  push: "đơn liên kết.",
  order: {
    title: "Đơn hàng",
    amount: "Số tiền đơn hàng",
    return_amount: "Số tiền trả lại",
    submit: "Gửi",
    get_commissions: "Nhận hoa hồng",
    holding: "Đang giữ",
    quantity: "Số lượng đơn hàng",
    available: "Khả dụng",
    available_at: "Xuất hiện tại",
    start: "Bắt đầu lấy đơn hàng",
    number: "Số đơn hàng",
    processing: "Đang xử lý đơn hàng",
    connecting: "Đang kết nối với đối tác",
    order_success: "Tạo đơn hàng thành công.",
    order_failed: "Tạo đơn hàng không thành công",
    order_details: "Chi tiết đơn hàng",
    limit_reached: "Các bạn đã hết lượt gửi đơn hàng cho MICHAEL-Kors trong ngày hôm nay...",
    blocked:
      "Hãy thanh toán $ {freeze_amount} số dư đóng băng để được tạo đơn mới !!",
    current_orders: "Số đơn hiện tại",
  },
  commission: "Hoa hồng",
  join: "Tham gia",
  you_here: "Bạn đang ở đây",
  amount: "Số tiền",
  daily_income: "Đơn được gửi về hệ thống MICHAEL-KORS",
  platform_introduction: {
    title: "Giới thiệu nền tảng",
    about_us: "Về chúng tôi",
    company: "Công ty của chúng tôi",
    rule: "Quy tắc",
    proxy: "Ủy quyền",
    policy: "Chính sách",
  },
  partner: "Đối tác",
  menu: {
    home: "Trang chủ",
    record: "Lưu trữ",
    support: "Hỗ trợ",
    account: "Tài khoản",
    bank_account: "Tài khoản ngân hàng",
  },
  status: {
    pending: "Đang chờ",
    success: "Thành công",
    freeze: "Đóng băng",
    title: "Trạng thái",
  },
  date: "Ngày",
  service: {
    customer: "Dịch vụ khách hàng trực tuyến",
    working_time: "Thời gian làm việc",
  },
  recharge: "Nạp tiền",
  history: {
    withdraw: "Lịch sử rút tiền",
    recharge: "Lịch sử nạp tiền",
    order_num: "Mã đơn hàng",
    trading_code: "Mã giao dịch",
  },
  update_info: "Cập nhật thông tin",
  password_change: "Thay đổi mật khẩu",
  password_withdraw_change: "Thay đổi mật khẩu rút tiền",
  language: "Ngôn ngữ",
  logout: "Đăng xuất",
  bank_account: "Tài khoản ngân hàng",
  team_report: "Báo cáo nhóm",
  update: "Cập nhật",
  submit: "Gửi",
  advice: "Lời khuyên",
  advice_content:
    "Thành viên lưu ý: Để bảo vệ tài khoản của bạn, xin vui lòng không cung cấp mật khẩu và thông tin ngân hàng cho bất kỳ ai. Chúng tôi sẽ không yêu cầu bạn cung cấp mật khẩu ngân hàng OTP / PIN / mã bảo mật 4 chữ số cho bất kỳ ai.",
  recharge_note: "* Khi ví được chấp thuận, bạn cần đăng nhập để rút tiền",
  instruct: "Hướng dẫn",
  recharge_page: {
    note: "Bạn cần nhập mã khách hàng của bạn",
    note1:
      "Nhập nội dung chuyển tiền và vui lòng đợi trong một thời gian ngắn để hệ thống thêm tiền vào tài khoản của bạn.",
    note2: "* Yêu cầu nội dung chuyển tiền phải có mã khách hàng",
    note3:
      "Hoặc bạn có thể sử dụng mã QR sau đây để chuyển tiền, quét mã sau và nhập nội dung chuyển tiền là mã khách hàng bên dưới.",
  },
  withdraw_page: {
    title: "Lịch sử rút tiền",
    note1: "Vui lòng thanh toán",
    note2: "để có thể thực hiện rút tiền!",
    note3: "* Khi ví được chấp thuận, bạn cần đăng nhập để rút tiền",
    password: "Mật khẩu rút tiền",
    update_info: "Vui lòng cập nhật thông tin ngân hàng của bạn!",
  },
  report: {
    all_levels: "Tất cả cấp độ",
    level: "Cấp độ",
    yesterday: "Hôm qua",
    today: "Hôm nay",
    this_week: "Tuần này",
  },
  select_chat: "Chọn một đoạn chat để hiển thị",
  search: "Tìm kiếm",
  new: "Tạo mới",
  users: "Người dùng",
  user: "Người dùng",
  name: "Tên",
  description: "Mô tả",
  image: "Ảnh",
  password: "Mật khẩu",
  address: "Địa chỉ",
  data_popup: {
    about_us: {
      title: "Về chúng tôi",
      description: `
        Chào mừng bạn đến với Michaelkorsshopvn - Thiên đường phụ kiện thời trang cao cấp
        Michaelkorsshopvn tự hào là nhà cung cấp chính hãng các sản phẩm phụ kiện thời trang cao cấp của thương hiệu Michael Kors lừng danh thế giới. Chúng tôi cam kết mang đến cho bạn trải nghiệm mua sắm đẳng cấp với những sản phẩm chất lượng hoàn hảo và dịch vụ khách hàng chuyên nghiệp.
        Tại Michaelkorsshopvn, bạn có thể tìm thấy:
          - Bộ sưu tập đa dạng: Túi xách, ví da, đồng hồ, trang sức và nhiều phụ kiện thời trang khác cho nam và nữ, từ phong cách cổ điển thanh lịch đến hiện đại trẻ trung.
          - Sản phẩm chính hãng: Michaelkorsshopvn cam kết chỉ cung cấp các sản phẩm Michael Kors chính hãng 100%, được nhập khẩu trực tiếp từ nhà sản xuất.
          - Chất lượng hoàn hảo: Mỗi sản phẩm Michael Kors đều được chế tác tỉ mỉ từ chất liệu cao cấp nhất, đảm bảo độ bền đẹp và sang trọng.
          - Dịch vụ chuyên nghiệp: Michaelkorsshopvn cung cấp dịch vụ tư vấn khách hàng tận tình, hỗ trợ đổi trả hàng hóa linh hoạt và chế độ bảo hành chính hãng.
        Michaelkorsshopvn - Nơi khẳng định đẳng cấp thời trang của bạn:
          - Đẳng cấp thương hiệu: Michael Kors là thương hiệu thời trang cao cấp được yêu thích bởi các ngôi sao Hollywood và giới thượng lưu trên toàn thế giới.
          - Phong cách sang trọng: Các sản phẩm Michael Kors được thiết kế với phong cách thanh lịch, tinh tế và hiện đại, giúp bạn khẳng định đẳng cấp thời trang của bản thân.
          - Chất lượng hoàn hảo: Michaelkorsshopvn cam kết chỉ cung cấp các sản phẩm Michael Kors chính hãng với chất lượng hoàn hảo nhất.
        Ngoài ra, Michaelkorsshopvn còn cung cấp dịch vụ dropshipping dành cho những ai muốn kinh doanh online phụ kiện thời trang Michael Kors mà không cần đầu tư vốn lớn.
        Với dịch vụ dropshipping của Michaelkorsshopvn, bạn sẽ được hưởng nhiều lợi ích:
          - Bán hàng không cần vốn: Bạn không cần phải đầu tư vốn để nhập hàng, chỉ cần đăng tải sản phẩm lên website hoặc mạng xã hội của bạn.
          - Lợi nhuận cao: Michaelkorsshopvn cung cấp mức chiết khấu hấp dẫn cho các đối tác dropshipping.
          - Hỗ trợ toàn diện: michaelkorsshopvn hỗ trợ bạn từ khâu đăng tải sản phẩm, xử lý đơn hàng, cho đến vận chuyển và chăm sóc khách hàng.
        Hãy đăng ký tham gia dịch vụ dropshipping của michaelkorsshopvn ngay hôm nay để bắt đầu kinh doanh online phụ kiện thời trang Michael Kors một cách dễ dàng và hiệu quả!
        Liên hệ với chúng tôi:
          - Website: https://michael-kors-shopvn.com/
          - Email: support{'@'}michaelkorsshopvn.com
        Michaelkorsshopvn - Nơi đẳng cấp thời trang được tôn vinh!
      `,
    },
    company: {
      title: "Giới thiệu công ty",
      description: `
        MichaelKorsshopvn tự hào là nhà cung cấp chính hãng các sản phẩm phụ kiện thời trang cao cấp của thương hiệu Michael Kors lừng danh thế giới tại Việt Nam. Chúng tôi cam kết mang đến cho bạn trải nghiệm mua sắm đẳng cấp với những sản phẩm chất lượng hoàn hảo và dịch vụ khách hàng chuyên nghiệp.
        Tại MichaelKorsshopvn, bạn có thể tìm thấy:
          - Bộ sưu tập đa dạng: Túi xách, ví da, đồng hồ, trang sức và nhiều phụ kiện thời trang khác cho nam và nữ, từ phong cách cổ điển thanh lịch đến hiện đại trẻ trung.
          - Sản phẩm chính hãng: MichaelKorsshopvn cam kết chỉ cung cấp các sản phẩm Michael Kors chính hãng 100%, được nhập khẩu trực tiếp từ nhà sản xuất.
          - Chất lượng hoàn hảo: Mỗi sản phẩm Michael Kors đều được chế tác tỉ mỉ từ chất liệu cao cấp nhất, đảm bảo độ bền đẹp và sang trọng.
          - Dịch vụ chuyên nghiệp: MichaelKorsshopvn cung cấp dịch vụ tư vấn khách hàng tận tình, hỗ trợ đổi trả hàng hóa linh hoạt và chế độ bảo hành chính hãng.
        MichaelKorsshopvn - Nơi khẳng định đẳng cấp thời trang của bạn:
          - Đẳng cấp thương hiệu: Michael Kors là thương hiệu thời trang cao cấp được yêu thích bởi các ngôi sao Hollywood và giới thượng lưu trên toàn thế giới.
          - Phong cách sang trọng: Các sản phẩm Michael Kors được thiết kế với phong cách thanh lịch, tinh tế và hiện đại, giúp bạn khẳng định đẳng cấp thời trang của bản thân.
          - Chất lượng hoàn hảo: MichaelKorsshopvn cam kết chỉ cung cấp các sản phẩm Michael Kors chính hãng với chất lượng hoàn hảo nhất.
        Ngoài ra, MichaelKorsshopvn còn cung cấp dịch vụ dropshipping dành cho những ai muốn kinh doanh online phụ kiện thời trang Michael Kors mà không cần đầu tư vốn lớn.
        Với dịch vụ dropshipping của MichaelKorsshopvn, bạn sẽ được hưởng nhiều lợi ích:
          - Bán hàng không cần vốn: Bạn không cần phải đầu tư vốn để nhập hàng, chỉ cần đăng tải sản phẩm lên website hoặc mạng xã hội của bạn.
          - Lợi nhuận cao: MichaelKorsshopvn cung cấp mức chiết khấu hấp dẫn cho các đối tác dropshipping.
          - Hỗ trợ toàn diện: MichaelKorsshopvn hỗ trợ bạn từ khâu đăng tải sản phẩm, xử lý đơn hàng, cho đến vận chuyển và chăm sóc khách hàng.
        Hãy đăng ký tham gia dịch vụ dropshipping của MichaelKorsshopvn ngay hôm nay để bắt đầu kinh doanh online phụ kiện thời trang Michael Kors một cách dễ dàng và hiệu quả!
        Liên hệ với chúng tôi:
          - Website: https://michael-kors-shopvn.com/
          - Email: support{'@'}michaelkorsshopvn.com
      `,
    },
    rule: {
      title: "Giới thiệu các quy định",
      description: `
            Chào mừng bạn đến với MichaelKorsshopvn!
            Để đảm bảo trải nghiệm mua sắm tốt nhất cho khách hàng, MichaelKorsshopvn áp dụng một số quy định chung như sau:
            1. Quy định về sản phẩm:
              - MichaelKorsshopvn cam kết chỉ cung cấp các sản phẩm Michael Kors chính hãng 100%, được nhập khẩu trực tiếp từ nhà sản xuất.
              - Mọi sản phẩm đều được kiểm tra kỹ lưỡng trước khi giao đến tay khách hàng.
              - MichaelKorsshopvn cung cấp đầy đủ thông tin chi tiết về sản phẩm, bao gồm hình ảnh, mô tả, chất liệu, kích thước, v.v.
            2. Quy định về giá cả:
              - Giá bán của các sản phẩm tại MichaelKorsshopvn được cập nhật liên tục và đảm bảo cạnh tranh nhất thị trường.
              - MichaelKorsshopvn thường xuyên triển khai các chương trình khuyến mãi, giảm giá và ưu đãi dành cho khách hàng.
            3. Quy định về thanh toán:
              - MichaelKorsshopvn hỗ trợ nhiều phương thức thanh toán tiện lợi cho khách hàng, bao gồm thanh toán trực tuyến, thanh toán khi nhận hàng (COD), thanh toán bằng thẻ ngân hàng, v.v.
            4. Quy định về vận chuyển:
              - MichaelKorsshopvn giao hàng trên toàn quốc với thời gian giao hàng nhanh chóng.
              - Miễn phí vận chuyển cho tất cả các đơn hàng trên [số tiền] đồng.
            5. Quy định về đổi trả hàng:
              - MichaelKorsshopvn hỗ trợ đổi trả hàng trong vòng [số ngày] ngày kể từ ngày nhận hàng.
              - Khách hàng được đổi trả hàng nếu sản phẩm bị lỗi do nhà sản xuất hoặc không đúng như mô tả trên website.
            6. Quy định về bảo mật thông tin:
              - MichaelKorsshopvn cam kết bảo mật thông tin cá nhân của khách hàng theo đúng quy định của pháp luật.
            7. Quy định về khiếu nại:
              - MichaelKorsshopvn luôn sẵn sàng tiếp nhận và giải quyết mọi khiếu nại của khách hàng một cách nhanh chóng và hiệu quả.
            Để biết thêm thông tin chi tiết về các quy định của MichaelKorsshopvn, vui lòng truy cập website https://michael-kors-shopvn.com.
            Chúc bạn có những trải nghiệm mua sắm thú vị tại MichaelKorsshopvn!
        `,
    },
    poxy: {
      title: "Chính sách",
      description: `
        Chính sách uỷ quyền của MichaelKorsshopvn
        MichaelKorsshopvn hiện đang áp dụng chính sách uỷ quyền bán hàng cho các đối tác có nhu cầu kinh doanh các sản phẩm phụ kiện thời trang cao cấp của thương hiệu Michael Kors.
        Dưới đây là một số thông tin cơ bản về chính sách uỷ quyền của MichaelKorsshopvn:
        1. Đối tượng uỷ quyền:
          - Các doanh nghiệp, cá nhân có đủ năng lực pháp lý và điều kiện kinh doanh theo quy định của pháp luật.
          - Có kinh nghiệm kinh doanh trong lĩnh vực thời trang cao cấp.
          - Có địa điểm kinh doanh phù hợp với yêu cầu của MichaelKorsshopvn.
          - Có đội ngũ nhân viên bán hàng chuyên nghiệp và am hiểu về sản phẩm Michael Kors.
        2. Quyền lợi của đại lý uỷ quyền:
          - Được hưởng mức chiết khấu hấp dẫn từ MichaelKorsshopvn.
          - Được hỗ trợ về mặt marketing, quảng bá và đào tạo bán hàng.
          - Được tham gia vào các chương trình khuyến mãi, ưu đãi dành cho đại lý.
        3. Trách nhiệm của đại lý uỷ quyền:
          - Tuân thủ các quy định của MichaelKorsshopvn về giá cả, sản phẩm, dịch vụ khách hàng, v.v.
          - Bán hàng theo đúng cam kết của MichaelKorsshopvn về chất lượng sản phẩm và dịch vụ.
          - Có trách nhiệm bảo vệ thương hiệu Michael Kors.
        4. Thủ tục uỷ quyền:
          - Các đối tác quan tâm đến việc uỷ quyền bán hàng cho MichaelKorsshopvn vui lòng liên hệ qua website https://michael-kors-shopvn.com/.
          - MichaelKorsshopvn sẽ xem xét hồ sơ uỷ quyền và thông báo kết quả cho các đối tác trong vòng [số ngày] ngày làm việc.
        5. Hợp đồng uỷ quyền:
          - Sau khi được chấp thuận uỷ quyền, hai bên sẽ ký kết hợp đồng uỷ quyền, trong đó quy định cụ thể về quyền lợi và trách nhiệm của các bên.
        6. Thời hạn uỷ quyền:
          - Hợp đồng uỷ quyền có thời hạn [số năm] năm và có thể được gia hạn theo thỏa thuận của hai bên.
        7. Giải quyết tranh chấp:
          - Mọi tranh chấp phát sinh trong quá trình thực hiện hợp đồng uỷ quyền sẽ được giải quyết theo quy định của pháp luật Việt Nam.
        MichaelKorsshopvn tin rằng chính sách uỷ quyền sẽ giúp chúng tôi mở rộng thị trường và mang đến cho khách hàng những trải nghiệm mua sắm tốt nhất.
        Để biết thêm thông tin chi tiết về chính sách uỷ quyền của MichaelKorsshopvn, vui lòng liên hệ với chúng tôi qua website https://michael-kors-shopvn.com/.
        Chúc bạn thành công với việc kinh doanh cùng MichaelKorsshopvn!
      `,
    },
    policy: {
      title: "Chính sách riêng tư",
      description: `
        Chính sách riêng tư của MichaelKorsshopvn
        MichaelKorsshopvn cam kết bảo vệ quyền riêng tư của khách hàng và tuân thủ các quy định của pháp luật Việt Nam về bảo vệ thông tin cá nhân.
        Dưới đây là một số thông tin cơ bản về chính sách riêng tư của MichaelKorsshopvn:
        1. Loại thông tin thu thập:
          - MichaelKorsshopvn thu thập thông tin cá nhân của khách hàng khi khách hàng đăng ký tài khoản, đặt hàng hoặc liên hệ với chúng tôi.
          - Các thông tin thu thập có thể bao gồm: tên, địa chỉ email, số điện thoại, địa chỉ giao hàng, thông tin thanh toán, v.v.
        2. Mục đích sử dụng thông tin cá nhân của khách hàng để:
          - Xử lý đơn hàng và giao hàng.
          - Cung cấp dịch vụ khách hàng.
          - Gửi thông tin về sản phẩm, dịch vụ và chương trình khuyến mãi.
          - Thực hiện các hoạt động marketing và quảng bá.
          - Phân tích dữ liệu và cải thiện chất lượng dịch vụ.
        3. Chia sẻ thông tin:
          - MichaelKorsshopvn cam kết không chia sẻ thông tin cá nhân của khách hàng với bất kỳ bên thứ ba nào mà không có sự đồng ý của khách hàng.
          - Tuy nhiên, MichaelKorsshopvn có thể chia sẻ thông tin cá nhân của khách hàng với các bên thứ ba trong các trường hợp sau:
            - Khi được yêu cầu bởi pháp luật.
            - Khi cần thiết để thực hiện dịch vụ cho khách hàng (ví dụ: chia sẻ thông tin với công ty vận chuyển để giao hàng).
            - Khi khách hàng đồng ý chia sẻ thông tin.
        4. Bảo mật thông tin:
          - MichaelKorsshopvn áp dụng các biện pháp kỹ thuật và tổ chức phù hợp để bảo vệ thông tin cá nhân của khách hàng khỏi bị truy cập trái phép, sử dụng sai mục đích hoặc tiết lộ.
        5. Quyền của khách hàng:
          - Khách hàng có quyền truy cập, sửa đổi, xóa thông tin cá nhân của mình.
          - Khách hàng có quyền yêu cầu MichaelKorsshopvn ngừng sử dụng thông tin cá nhân của mình cho mục đích marketing.
          - Khách hàng có quyền phản đối việc MichaelKorsshopvn chia sẻ thông tin cá nhân của mình với các bên thứ ba.
        6. Liên hệ:
          - Nếu khách hàng có bất kỳ câu hỏi nào về chính sách riêng tư của MichaelKorsshopvn, vui lòng liên hệ với chúng tôi qua website https://michael-kors-shopvn.com/.
        MichaelKorsshopvn cam kết bảo vệ quyền riêng tư của khách hàng và tuân thủ các quy định của pháp luật Việt Nam về bảo vệ thông tin cá nhân.
        Chúc bạn có những trải nghiệm mua sắm an toàn và tin cậy tại MichaelKorsshopvn!
        Chúc bạn thành công!
      `
    },
  },
  select_picture: "Chọn ảnh",
  levels: {
    1: "Thành viên bạc",
    2: "Thành viên vàng",
    3: "Thành viên kim cương",
    4: "Thành viên vương miện",
    0: "No ranking",
  },
  prize:
    "Quý khách đã là người may mắn trúng thưởng đơn có % hoa hồng lớn nhất tháng này!!",
  logout_message: "Bạn muốn đăng xuất chứ ?",
  yes: "Có",
  no_data: "KHÔNG CÓ DỮ LIỆU",
  coming_soon: "Sắp ra mắt",
  chat_with_admin: "Bấm vào đây để chat với Admin",
  freeze_alert:
    "Hoàn thành đơn hàng bạn sẽ nhận được <br /> <span class='fw-bold'>${balance}</span> <br /> cùng với <br /> <span class='fw-bold text-success'>${profit}</span> hoa hồng",
  setting: "Cài đặt",
  delete_message: "Bạn chắc chắn muốn xoá item này chứ ?",
  rules: `
    Điều khoản sử dụng trang web.
    Chào mừng bạn đến với Michael Kors Shopvn!
    Cảm ơn bạn đã dành thời gian truy cập trang web chính thức của MichaelKorsShopvn.
    Trước khi bạn bắt đầu khám phá thế giới thời trang đa dạng và phong cách của chúng tôi, vui lòng dành ít phút để đọc và hiểu các điều khoản sử dụng trang web. Việc truy cập, duyệt nội dung hoặc sử dụng bất kỳ hình ảnh nào trên trang web này đồng nghĩa với việc bạn (người sử dụng) đã đồng ý tuân thủ các điều khoản này. Nếu bạn không đồng ý với bất kỳ điều khoản nào, vui lòng không sử dụng trang web này.

    1. QUY ĐỊNH CHUNG
    Điều khoản Sử dụng quy định các điều khoản ràng buộc bạn khi sử dụng các Dịch vụ. Thuật ngữ “Bạn” và/hoặc “Người sử dụng” sau đây được gọi chung để chỉ tới những người sử dụng các Dịch vụ. Vui lòng nghiên cứu kỹ và lưu lại một bản Điều khoản Sử dụng này.
    Bằng việc ghé thăm website michaelkorsshopvn.com và sử dụng các Dịch vụ, Bạn đồng ý bị ràng buộc với Điều khoản Sử dụng này, Chính sách Quảng cáo và Chính sách Bảo mật của chúng tôi.
    Chúng tôi thực hiện Điều khoản Sử dụng này, Chính sách Quảng cáo và Chính sách bảo mật theo quy định của pháp luật hiện hành và không nội dung nào trong các tài liệu trên cản trở quyền của chúng tôi tuân thủ các quy định hoặc yêu cầu của chính phủ, tòa án, cơ quan thi hành án liên quan đến việc Bạn sử dụng các Dịch vụ hoặc thông tin do chúng tôi nhận được hoặc thu được từ việc sử dụng các Dịch vụ của Bạn.
    2. QUYỀN SỞ HỮU TRÍ TUỆ
    Mọi nội dung trên Trang web, bao gồm văn bản, hình ảnh, video, phần mềm, v.v., đều thuộc quyền sở hữu trí tuệ của Công ty hoặc các bên cấp phép cho Công ty. Bạn không được phép sao chép, sửa đổi, phân phối, truyền tải, hiển thị công khai, hoặc sử dụng bất kỳ nội dung nào trên Trang web này cho mục đích thương mại mà không có sự đồng ý bằng văn bản của Công ty.

    3. TỪ CHỐI BẢO ĐẢM
    Website michaelkorsshopvn.com và các Dịch vụ được cung cấp dựa trên nguyên tắc không bảo hành, trên cơ sở dịch vụ và tính năng sẵn có mà Chúng tôi không đảm bảo rằng các tính năng trên website michaelkorsshopvn.com hoặc các Dịch vụ sẽ đáp ứng các yêu cầu của Bạn hoặc đảm bảo rằng sự vận hành các phần mềm hoặc các Dịch vụ sẽ không bị gián đoạn hoặc bị lỗi.
    Chúng tôi không chịu trách nhiệm đối với bất kỳ sự mất mát dữ liệu, tổn thất lợi ích nào hoặc các vấn đề khác liên quan đến việc truy cập website michaelkorsshopvn.com và sử dụng các Dịch vụ, bao gồm nhưng không giới hạn đến tất cả các thông tin, dữ liệu, văn bản, phần mềm, âm nhạc, âm thanh, hình ảnh, đồ họa, video, thông điệp hoặc các nguyên liệu khác (“Nội dung”) mà Bạn có thể lưu giữ, đưa lên, hoặc truyền tải thông qua Dịch vụ. Bạn đồng ý sử dụng các Dịch vụ với tất cả sự rủi ro.
    4. SỬ DỤNG TRANG WEB
    Mua hàng: Bạn có thể mua hàng trên Trang web bằng cách lựa chọn sản phẩm, thêm vào giỏ hàng và thanh toán. Công ty sẽ xử lý đơn hàng của bạn và giao hàng đến địa chỉ bạn cung cấp.
    Dropshipping: Bạn có thể đăng ký sử dụng dịch vụ dropshipping của Công ty để bán các sản phẩm của Công ty trên trang web của riêng bạn. Công ty sẽ xử lý đơn hàng và giao hàng đến khách hàng của bạn.
    Hạn chế: Bạn không được phép sử dụng Trang web này cho bất kỳ mục đích nào bất hợp pháp hoặc vi phạm các Điều khoản này. Bạn cũng không được phép sử dụng Trang web này để:
    Gửi hoặc truyền tải bất kỳ nội dung nào bất hợp pháp, khiêu dâm, hoặc xúc phạm.
    Sử dụng bất kỳ phần mềm hoặc chương trình nào có thể gây hại cho Trang web hoặc máy tính của người dùng khác.
    Thu thập hoặc lưu trữ thông tin cá nhân của người dùng khác mà không có sự đồng ý của họ.

    5. LIÊN KẾT ĐẾN TRANG WEB KHÁC
    Trang web này có thể chứa các liên kết đến các trang web khác không thuộc sở hữu hoặc kiểm soát của Công ty. Công ty không chịu trách nhiệm cho nội dung hoặc tính bảo mật của các trang web khác.

    6. Quyền riêng tư
    Công ty cam kết bảo vệ quyền riêng tư của bạn. Vui lòng tham khảo Chính sách bảo mật của chúng tôi để biết thêm thông tin về cách chúng tôi thu thập, sử dụng và bảo vệ thông tin cá nhân của bạn.

    7. Giới hạn trách nhiệm
    Công ty không chịu trách nhiệm cho bất kỳ thiệt hại nào phát sinh từ việc sử dụng Trang web này, bao gồm nhưng không giới hạn ở thiệt hại do lỗi, virus, hoặc gián đoạn dịch vụ.
    Công ty không chịu trách nhiệm cho bất kỳ thiệt hại nào phát sinh từ việc sử dụng dịch vụ dropshipping, bao gồm nhưng không giới hạn ở thiệt hại do lỗi sản phẩm, chậm trễ giao hàng, hoặc mất hàng.
    Công ty không chịu trách nhiệm cho bất kỳ thiệt hại nào do bạn vi phạm các Điều khoản này.
    8. THAY ĐỔI ĐIỀU KHOẢN SỬ DỤNG
    Chúng tôi giữ quyền thay đổi và/hoặc sửa đổi mà không cần báo trước bất kỳ điều khoản nào trong Điều khoản Sử dụng này tùy từng thời điểm. Những sự thay đổi và/hoặc sửa đổi như vậy sẽ có hiệu lực ngay khi đăng lên website michaelkorsshopvn.com. Nếu Bạn tiếp tục truy cập michaelkorsshopvn.com hoặc sử dụng các Dịch vụ sau khi các thay đổi và/hoặc sửa đổi được đăng lên, Bạn đã chấp nhận và đồng ý với các thay đổi và/hoặc sửa đổi đó.
    9. QUY ĐỊNH VỀ ĐĂNG KÝ, SỬ DỤNG TÀI KHOẢN VÀ THANH TOÁN PHÍ DỊCH VỤ
    Chúng tôi cung cấp các Dịch vụ cho Bạn hoàn toàn thông qua hệ thống trực tuyến của Website michaelkorsshopvn.com từ khi Bạn bắt đầu đăng ký tài khoản, lựa chọn dịch vụ cũng như tiến hành thanh toán phí dịch vụ mà Bạn sử dụng Dịch vụ của Chúng tôi.
    Bạn xác nhận và đồng ý rằng Bạn đã nghiên cứu kỹ càng và sẽ tuân thủ đầy đủ các quy định về đăng ký tài khoản, phương thức tính phí và thanh toán chi phí được đăng trên Website michaelkorsshopvn.com của chúng tôi (“Quy định về Đăng ký và Sử dụng Dịch vụ”). Bằng việc Bạn xác nhận hoàn thành thủ tục đăng ký tài khoản trên website michaelkorsshopvn.com, Bạn đã chấp nhận bị ràng buộc thực hiện đối với các Quy định về Đăng ký và Sử dụng Dịch vụ như vậy khi Bạn sử dụng các Dịch vụ.
    Chúng tôi luôn thay đổi và/hoặc sửa đổi nội dung Điều khoản Sử dụng này cũng như các Quy định về Đăng ký và Sử dụng Dịch vụ với mục đích hoàn thiện tốt nhất nội dung các quy định và đáp ứng tối đa sự tiện dụng và hiệu quả khi Bạn sử dụng các Dịch vụ cũng như đảm bảo nâng cao lợi ích của Chúng tôi khi cung cấp các Dịch vụ.
    Chúng tôi giữ quyền nhưng không có nghĩa vụ thông báo tới Bạn đối với bất kỳ sự thay đổi và/hoặc sửa đổi các Quy định về Đăng ký và Sử dụng Dịch vụ. Những sự thay đổi và/hoặc sửa đổi như vậy sẽ có hiệu lực ngay khi đăng lên Website michaelkorsvn.com. Nếu Bạn tiếp tục sử dụng các Dịch vụ sau khi các thay đổi và/hoặc sửa đổi được đăng lên, Bạn đã chấp nhận và đồng ý với các thay đổi và/hoặc sửa đổi đó. Chúng tôi khuyến nghị Bạn thường xuyên kiểm tra trên Website michaelkorsshopvn.com và liên hệ với nhân viên hỗ trợ của Chúng tôi để có được bản cập nhật các Quy định về Đăng ký và Sử dụng Dịch vụ mới nhất.
    10. GIỚI HẠN TRÁCH NHIỆM
    Bạn xác nhận và đồng ý rằng chúng tôi chỉ sẵn sàng cung cấp các Dịch vụ nếu Bạn đồng ý giới hạn trách nhiệm của chúng tôi đối với Bạn và các bên thứ ba. Bạn đồng ý rằng Bạn chịu trách nhiệm bồi thường duy nhất và toàn bộ đối với bất kỳ khiếu nại, khiếu kiện nào tới chúng tôi liên quan đến bất kỳ sự vi phạm quy định sử dụng nào do việc Bạn sử dụng các Dịch vụ hoặc ngừng sử dụng Dịch vụ của chúng tôi.
    Bạn đồng ý tự chịu trách nhiệm với các nội dung và thông tin cung cấp cho chúng tôi
    Bạn đồng ý bảo vệ, bồi thường và giữ cho chúng tôi và các công ty liên kết của chúng tôi và mỗi nhân viên, giám đốc, người lao động, đại lý, đại diện, người cung cấp thông tin và bên cấp phép của chúng tôi không bị tổn hại bởi bất kỳ khiếu nại, khiếu kiện, chi phí, tổn thất, thiệt hại, phán quyết của tòa án và phí tổn nào, bao gồm nhưng không giới hạn phí luật sư, phí bồi thường thiệt hại, chi phí tố tụng, lãi chậm trả liên quan đến hoặc phát sinh từ bất kỳ khiếu nại, mâu thuẫn, tranh chấp, thủ tục tố tụng pháp lý tại Tòa án hoặc các tổ chức Trọng tài, tổ chức Hòa giải, các quyết định của cơ quan Nhà nước có thẩm quyền … liên quan đến hoặc phát sinh từ các sản phẩm và dịch vụ. Chúng tôi giữ quyền, bằng chi phí của mình, đảm nhận hoàn toàn việc bảo vệ và kiểm soát (nhưng không có trách nhiệm) đối với bất kỳ vấn đề nào phát sinh như vậy tùy thuộc vào sự bồi thường của Bạn.
    11. ĐIỀU KHOẢN KHÁC
    Luật điều chỉnh
    Điều khoản Sử dụng này được điều chỉnh bởi và giải thích phù hợp với pháp luật Việt Nam. Nếu bất kỳ điều khoản nào trong Điều khoản Sử dụng này không hợp pháp, vô hiệu hoặc vì bất kỳ lý do nào không thể thực hiện được, thì điều khoản đó sẽ được xem là tách rời khỏi Điều khoản Sử dụng này và không ảnh hưởng đến hiệu lực và tính thực thi của bất kỳ điều khoản còn lại nào của Điều khoản Sử dụng này.
    Chấm dứt
    Điều khoản Sử dụng này có hiệu lực cho đến khi bị chấm dứt bởi Bạn hoặc bởi chúng tôi theo các trường hợp sau đây:
    Chấm dứt bởi Bạn: Bạn có thể chấm dứt Điều khoản Sử dụng này bằng cách không sử dụng các Dịch vụ nữa.
    Chấm dứt bởi chúng tôi: Điều khoản Sử dụng này chấm dứt ngay lập tức mà chúng tôi không cần phải thông báo trước nếu (i) theo sự quyết định của chúng tôi, Bạn không tuân thủ bất kỳ điều khoản nào của Điều khoản Sử dụng này hoặc (ii) Pháp luật hoặc quyết định của chính phủ, tòa án, cơ quan thi hành án yêu cầu chúng tôi chấm dứt Điều khoản Sử dụng.
    Liên hệ
    Nếu Bạn có bất kỳ câu hỏi nào về Điều khoản Sử dụng này, về hoạt động của các Dịch vụ, hoặc sự kết nối của Bạn với chúng tôi, vui lòng liên hệ với chúng tôi qua email: info{'@'}michaelkorsshopvn.com. Tất cả các vấn đề hoặc mâu thuẫn sẽ được giải quyết nhanh chóng và hợp lý.
  `,
  privacy_policy: `
    Chính sách bảo mật
    1. GIỚI THIỆU
    **1.1. Chào mừng bạn đến với ỨNG DỤNG michaelkorsshopvn (bao gồm website và ứng dụng di động) được điều hành bởi CÔNG TY CỔ PHẦN ĐẦU TƯ VÀ CÔNG NGHỆ Michaelkors Shopvn. michaelkorsshopvn nghiêm túc thực hiện trách nhiệm của mình liên quan đến bảo mật thông tin theo các quy định về bảo vệ bí mật thông tin cá nhân của pháp luật Việt Nam ("Luật riêng tư") và cam kết tôn trọng quyền riêng tư và sự quan tâm của tất cả người dùng đối với các dịch vụ michaelkorsshopvn. Chúng tôi nhận biết tầm quan trọng của dữ liệu cá nhân mà bạn đã tin tưởng giao cho chúng tôi và tin rằng chúng tôi có trách nhiệm quản lý, bảo vệ và xử lý dữ liệu cá nhân của bạn một cách thích hợp. Chính sách bảo mật này ("Chính sách bảo mật" hay "Chính sách") được thiết kế để giúp bạn hiểu cách chúng tôi thu thập, sử dụng, tiết lộ và/hoặc xử lý dữ liệu cá nhân mà bạn đã cung cấp cho chúng tôi và/hoặc lưu giữ về bạn, cho dù là hiện nay hoặc trong tương lai, cũng như để giúp bạn đưa ra quyết định sáng suốt trước khi cung cấp cho chúng tôi bất kỳ dữ liệu cá nhân nào của bạn.
    **1.2. "Dữ Liệu Cá Nhân" hay "dữ liệu cá nhân" có nghĩa là dữ liệu, dù đúng hay không, về một cá nhân mà thông qua đó có thể được xác định danh tính, hoặc từ dữ liệu đó và thông tin khác mà một tổ chức có hoặc có khả năng tiếp cận. Các ví dụ thường gặp về dữ liệu cá nhân có thể gồm có tên, số chứng minh nhân dân và thông tin liên hệ.
    **1.3. Bằng việc sử dụng Các Dịch Vụ, đăng ký một tài khoản với chúng tôi hoặc truy cập Nền tảng, bạn xác nhận và đồng ý rằng bạn chấp nhận các phương pháp, yêu cầu, và/hoặc chính sách được mô tả trong Chính sách bảo mật này, và theo đây bạn đồng ý cho phép chúng tôi thu thập, sử dụng, tiết lộ và/hoặc xử lý dữ liệu cá nhân của bạn như mô tả trong đây. NẾU BẠN KHÔNG ĐỒNG Ý CHO PHÉP XỬ LÝ DỮ LIỆU CÁ NH N CỦA BẠN NHƯ MÔ TẢ TRONG CHÍNH SÁCH NÀY, VUI LÒNG KHÔNG SỬ DỤNG CÁC DỊCH VỤ CỦA CHÚNG TÔI HOẶC TRUY CẬP NỀN TẢNG HOẶC TRANG WEB CỦA CHÚNG TÔI. Nếu chúng tôi thay đổi Chính sách bảo mật của mình, chúng tôi sẽ đăng những thay đổi đó hoặc Chính sách bảo mật sửa đổi trên Nền tảng của chúng tôi. Chúng tôi có quyền sửa đổi bổ sung Chính sách bảo mật này vào bất kỳ lúc nào.
    2. Michaelkors Shopvn SẼ THU THẬP NHỮNG DỮ LIỆU GÌ?
    2.1. Michaelkors Shopvn có thể thu thập bao gồm, nhưng không giới hạn:
    a. Họ tên;
    b. Địa chỉ email;
    c. Địa chỉ thanh toán;
    d. Số điện thoại;
    e. Thông tin được gửi bởi hoặc liên quan đến (các) thiết bị được sử dụng để truy cập vào Các Dịch Vụ hoặc Nền tảng của chúng tôi.
    f. Bất kỳ thông tin nào khác về người dùng khi người dùng đăng nhập để sử dụng Các Dịch Vụ hoặc Nền tảng của chúng tôi, và khi người dùng sử dụng Các Dịch Vụ hoặc Nền tảng, cũng như thông tin về việc người dùng sử dụng Các Dịch Vụ hoặc Nền tảng của chúng tôi như thế nào;
    2.2. Bạn đồng ý không cung cấp cho chúng tôi bất cứ thông tin nào không chính xác hoặc gây hiểu nhầm và bạn đồng ý sẽ thông báo cho chúng tôi về bất cứ thông tin nào không chính xác hoặc khi có sự thay đổi thông tin. Chúng tôi bảo lưu quyền theo quyết định riêng của chúng tôi được yêu cầu các tài liệu cần thiết khác để xác minh thông tin được bạn cung cấp.
    2.3. Nếu bạn không muốn chúng tôi thu thập thông tin/dữ liệu cá nhân nói trên, bạn có thể chọn không tham gia vào bất kỳ lúc nào bằng cách thông báo bằng văn bản đến Nhân Viên Bảo Vệ Dữ Liệu của chúng tôi. Có thể tìm thấy thêm thông tin về nội dung trong mục "Bạn có thể rút lại sự cho phép xóa tên, yêu cầu truy cập hoặc điều chỉnh thông tin bạn đã cung cấp cho chúng tôi bằng cách nào?" dưới đây. Tuy nhiên, lưu ý rằng việc từ chối hoặc hủy bỏ cho phép chúng tôi thu thập, sử dụng hoặc xử lý dữ liệu cá nhân của bạn có thể ảnh hưởng đến việc bạn sử dụng Các Dịch Vụ và Nền tảng. Ví dụ như dịch vụ xác định vị trí sẽ không hoạt động nếu bạn không cho phép ứng dụng truy cập vị trí của bạn
    3. CHÚNG TÔI SỬ DỤNG THÔNG TIN BẠN CUNG CẤP CHO CHÚNG TÔI NHƯ THẾ NÀO?
    3.1. Chúng tôi có thể thu thập, sử dụng, tiết lộ và/hoặc xử lý dữ liệu cá nhân của bạn vì một hoặc nhiều mục đích sau đây:
    a. Để xem xét và/hoặc xử lý đơn đăng ký/giao dịch của bạn với chúng tôi hoặc giao dịch hay thư từ của bạn với các bên thứ ba qua Các Dịch Vụ;
    b. Để quản lý, điều hành, cung cấp và/hoặc quản lý việc bạn sử dụng và/hoặc truy cập Các Dịch Vụ và các Nền tảng của chúng tôi (bao gồm nhưng không giới hạn, các sở thích của bạn), cũng như quan hệ và tài khoản người dùng của bạn với chúng tôi;
    c. Để đáp ứng, xử lý, giải quyết hoặc hoàn tất một giao dịch và/hoặc đáp ứng các yêu cầu của bạn đối với các sản phẩm và dịch vụ nhất định và thông báo cho bạn về các vấn đề dịch vụ và các hoạt động tài khoản bất thường;
    d. Để thực thi các Điều Khoản Dịch Vụ của chúng tôi hoặc bất kỳ thỏa thuận giấy phép người dùng cuối nào áp dụng;
    e. Để bảo vệ sự an toàn cá nhân và các quyền, tài sản hoặc sự an toàn của người khác;
    f. Để nhận dạng và/hoặc xác minh;
    g. Để duy trì và quản lý bất kỳ bản cập nhật phần mềm nào và/hoặc các bản cập nhật khác và sự hỗ trợ có thể được yêu cầu tùy lúc nhằm đảm bảo Các Dịch Vụ của chúng tôi hoạt động suôn sẻ;
    h. Để giải quyết hoặc tạo điều kiện dịch vụ khách hàng, thực hiện các chỉ thị của bạn, giải quyết hoặc trả lời bất kỳ thắc mắc nào được gửi bởi (hoặc nhằm được gửi bởi) bạn hoặc thay mặt bạn
    i. Để liên hệ với bạn hoặc liên lạc với bạn qua điện thoại, tin nhắn văn bản và/hoặc tin nhắn fax, email và/hoặc thư hoặc cách khác nhằm mục đích quản trị và/hoặc quản lý quan hệ của bạn với chúng tôi hoặc việc bạn sử dụng Các Dịch Vụ của chúng tôi, chẳng hạn như nhưng không giới hạn ở việc truyền đạt thông tin hành chính cho bạn liên quan đến Các Dịch Vụ của chúng tôi. Bạn xác nhận và đồng ý rằng sự liên lạc như thế của chúng tôi có thể là theo cách gửi thư qua đường bưu điện, tài liệu hoặc thông báo cho bạn, có thể gồm có tiết lộ dữ liệu cá nhân nhất định về bạn để cung cấp các tài liệu đó cũng như trên bao bì/phong bì;
    j. Để cho phép các người dùng khác tương tác hoặc liên lạc với bạn thông qua Nền tảng, bao gồm để thông báo cho bạn khi một người dùng khác đã gửi cho bạn một tin nhắn riêng tư hoặc đăng nhận xét cho bạn trên Nền tảng;
    k. Để tiến hành các hoạt động nghiên cứu, phân tích và phát triển (bao gồm, nhưng không giới hạn ở, phân tích dữ liệu, khảo sát, phát triển và/hoặc lập đặc tính sản phẩm và dịch vụ), để phân tích cách thức bạn sử dụng Các Dịch Vụ của chúng tôi, để cải thiện Các Dịch Vụ hoặc sản phẩm của chúng tôi và/hoặc để cải thiện trải nghiệm khách hàng của bạn;
    4. michaelkorsshopvn BẢO VỆ VÀO LƯU TRỮ THÔNG TIN KHÁCH HÀNG BẰNG CÁCH NÀO?
    4.1. Chúng tôi thực hiện các biện pháp bảo mật khác nhau và luôn nỗ lực để đảm bảo sự an toàn của dữ liệu cá nhân của bạn trên các hệ thống của chúng tôi. Dữ liệu cá nhân của người dùng được lưu trữ đằng sau các mạng bảo mật và chỉ có thể được truy cập bởi một số nhân viên có quyền truy cập đặc biệt đến các hệ thống của chúng tôi. Tuy nhiên, chắc chắn không thể có sự đảm bảo an ninh tuyệt đối.
    4.2.Chúng tôi sẽ duy trì dữ liệu cá nhân tuân theo các quy định của pháp luật về bảo vệ bí mật thông tin cá nhân và/hoặc các điều luật hiện hành khác. Có nghĩa là, chúng tôi sẽ hủy hoặc xóa thông tin nhận dạng ra khỏi dữ liệu cá nhân của bạn khi chúng tôi nhận được yêu cầu của bạn phù hợp với các quy định của Luật riêng tư hiện hành hoặc khi chúng tôi có lý do hợp lý để xác định rằng (i) việc lưu trữ dữ liệu cá nhân đó không còn phục vụ mục đích thu thập dữ liệu cá nhân đó nữa; (ii) việc lưu giữ không còn cần thiết cho bất kỳ mục đích hợp pháp hay mục đích kinh doanh nào và (iii) không còn các lợi ích hợp pháp nào khác để tiếp tục lưu giữ các dữ liệu cá nhân này. Nếu bạn ngừng sử dụng Nền tảng của chúng tôi, hoặc quyền của bạn được sử dụng Nền tảng và/hoặc Các Dịch Vụ bị chấm dứt hoặc hủy bỏ, chúng tôi có thể tiếp tục lưu, sử dụng và/hoặc tiết lộ dữ liệu cá nhân của bạn tuân theo Chính sách bảo mật này và các nghĩa vụ của chúng tôi theo các quy định của pháp luật về bảo vệ bí mật thông tin cá nhân. Tùy thuộc vào quy định của pháp luật, chúng tôi có thể tiêu hủy dữ liệu cá nhân của bạn một cách an toàn mà không cần thông báo trước cho bạn.
    4.3. Chúng tôi bảo lưu quyền từ chối xóa, tiêu hủy dữ liệu trong trường hợp yêu cầu của bạn không phù hợp với quy định của Luật riêng tư hiện hành, hoặc mặc dù phù hợp nhưng thuộc trường hợp Chúng tôi không được xóa, tiêu hủy theo quy định của Luật riêng tư hiện hành.
    5. Michaelkorsshopvn CÓ TIẾT LỘ THÔNG TIN THU THẬP TỪ NGƯỜI TRUY CẬP HAY KHÔNG?
    5.1. Trong quá trình thực hiện hoạt động kinh doanh, chúng tôi sẽ/có thể cần phải tiết lộ dữ liệu cá nhân của bạn cho các nhà cung cấp dịch vụ bên thứ ba, đại lý và/hoặc các công ty liên kết hoặc công ty liên quan của chúng tôi, và/hoặc các bên thứ ba khác, vì một hay nhiều Mục Đích nói trên. Các nhà cung cấp dịch vụ bên thứ ba, đại lý và/hoặc các công ty liên kết hoặc công ty liên quan và/hoặc các bên thứ ba khác như thế sẽ xử lý dữ liệu cá nhân của bạn hoặc thay mặt chúng tôi hoặc khác, vì một hoặc nhiều Mục Đích nói trên. Các bên thứ ba như thế bao gồm, nhưng không giới hạn ở:
    a. Công ty con, công ty liên kết và công ty liên quan của chúng tôi;
    b. Nhà thầu, đại lý, nhà cung cấp dịch vụ và các bên thứ ba khác mà chúng tôi thuê để hỗ trợ hoặc bổ sung cho hoạt động kinh doanh của chúng tôi. Những bên này bao gồm, nhưng không giới hạn ở những bên cung cấp các dịch vụ quản trị hoặc các dịch vụ khác cho chúng tôi chẳng hạn như công ty bưu chính, công ty viễn thông, công ty công nghệ thông tin, các tổ chức hoạt động thương mại điện tử, và trung tâm dữ liệu;
    c. Người mua hoặc người thừa nhiệm khác trong trường hợp sáp nhập, thoái vốn, tái cơ cấu, tái tổ chức, giải thể hoặc bán hay chuyển nhượng một phần hoặc tất cả tài sản của michaelkorsshopvn, cho dù là vấn đề đang diễn ra hay đang trong thủ tục phá sản, thanh lý hoặc thủ tục tương tự, trong đó dữ liệu cá nhân michaelkorsshopvn lưu giữ về người dùng của chúng tôi nằm trong các tài sản được chuyển nhượng; hoặc cho một bên đối tác trong một giao dịch tài sản kinh doanh mà michaelkorsshopvn hoặc bất kỳ công ty liên kết hay công ty liên quan nào của nó có tham gia giao dịch;
    d. Bên thứ ba mà chúng tôi tiết lộ thông tin vì một trong các Mục Đích và các bên thứ ba đó ngược lại họ sẽ thu thập và xử lý dữ liệu cá nhân của bạn vì một hoặc nhiều Mục Đích
    5.2. Trường hợp này có thể yêu cầu, ngoài các vấn đề khác, chia sẻ thông tin thống kê và nhân khẩu học về Người Dùng cũng như việc sử dụng Các Dịch Vụ của người dùng với đối tác cung cấp dịch vụ quảng cáo và lập trình. Chúng tôi cũng sẽ chia sẻ thông tin thống kê và thông tin nhân khẩu học về người dùng của chúng tôi và việc họ sử dụng Các Dịch Vụ với các nhà cung cấp dịch vụ quảng cáo và lập trình. Trường hợp này sẽ không bao gồm bất kỳ thông tin nào có thể được sử dụng để xác định danh tính cụ thể của bạn hoặc tiết lộ thông tin cá nhân về bạn.
    5.3. Để tránh nghi ngờ, trong trường hợp các quy định của pháp luật về bảo vệ bí mật thông tin cá nhân hoặc các điều luật hiện hành khác cho phép một tổ chức chẳng hạn như chúng tôi thu thập, sử dụng hoặc tiết lộ dữ liệu cá nhân của bạn mà không cần sự đồng ý của bạn, sự cho phép như thế của pháp luật sẽ tiếp tục áp dụng.
    5.4. Các bên thứ ba có thể chặn hoặc truy cập trái phép dữ liệu cá nhân được gửi đến hoặc có trên trang web, các công nghệ có thể hoạt động không chính xác hoặc không hoạt động như dự kiến, hoặc có người có thể truy cập, lạm dụng hoặc sử dụng sai trái thông tin mà không phải lỗi của chúng tôi. Tuy nhiên chúng tôi sẽ triển khai các biện pháp bảo mật hợp lý để bảo vệ dữ liệu cá nhân của bạn theo quy định của các quy định của pháp luật về bảo vệ bí mật thông tin cá nhân; tuy nhiên không thể đảm bảo sự bảo mật tuyệt đối chẳng hạn như nhưng không giới hạn ở khi trường hợp tiết lộ trái phép phát sinh từ hoạt động tin tặc vì ý đồ xấu hoặc phức tạp của các nội dung có độc không phải lỗi của chúng tôi.
    6. THÔNG TIN THU THẬP BỞI CÁC BÊN THỨ BA
    6.1. Nền tảng của chúng tôi sử dụng Google Analytics, một dịch vụ phân tích web được cung cấp bởi Google, Inc. ("Google"). Google Analytics sử dụng cookie, là các tập tin văn bản trên thiết bị của bạn, để giúp Nền tảng phân tích cách thức người dùng sử dụng Nền tảng của chúng tôi. Thông tin được tạo bởi cookie về việc bạn sử dụng Nền tảng (bao gồm địa chỉ IP của bạn) sẽ được gửi đến và lưu bởi Google trên các máy chủ tại Hoa Kỳ. Google sẽ sử dụng thông tin này để đánh giá việc bạn sử dụng Nền tảng của chúng tôi, soạn báo cáo về hoạt động trang web dành cho các nhà điều hành trang web và cung cấp các dịch vụ khác liên quan đến hoạt động trang web và việc sử dụng Internet. Google cũng có thể gửi thông tin này cho các bên thứ ba trong trường hợp luật pháp có quy định như thế, hoặc trường hợp các bên thứ ba đó xử lý thông tin thay mặt Google. Google sẽ không liên kết địa chỉ IP của bạn với bất kỳ dữ liệu nào khác mà Google nắm giữ
    6.2. Chúng tôi, và các bên thứ ba, có thể trong từng thời điểm cung cấp các bản tải về ứng dụng phần mềm cho bạn sử dụng bởi Nền tảng hoặc thông qua Các Dịch Vụ. Những ứng dụng này có thể truy cập riêng, và cho phép một bên thứ ba xem, thông tin nhận dạng của bạn, chẳng hạn như tên, ID người dùng của bạn, Địa chỉ IP của thiết bị của bạn hoặc thông tin khác chẳng hạn như game bạn đang chơi trong bất kỳ phiên truy cập cụ thể nào, và bất kỳ cookie nào trước đây bạn có thể đã cài đặt hoặc đã được cài đặt cho bạn bởi một ứng dụng phần mềm hoặc trang web của bên thứ ba. Ngoài ra, các ứng dụng này có thể yêu cầu bạn cung cấp thêm thông tin trực tiếp cho các bên thứ ba. Các sản phẩm hoặc dịch vụ của bên thứ ba được cung cấp thông qua các ứng dụng này không thuộc sở hữu hay quyền kiểm soát của michaelkorsshopvn. Bạn nên đọc các điều khoản và các chính sách khác được công bố bởi các bên thứ ba đó trên trang web của họ hoặc nơi khác.
    7. LOẠI TRỪ TRÁCH NHIỆM VỀ NGHĨA VỤ BẢO MẬT VÀ CÁC TRANG WEB BÊN THỨ BA
    7.1. CHÚNG TÔI KHÔNG ĐẢM BẢO TÍNH BẢO MẬT ĐỐI VỚI DỮ LIỆU CÁ NH N VÀ/HOẶC THÔNG TIN KHÁC MÀ BẠN CUNG CẤP TRÊN CÁC TRANG WEB CỦA BÊN THỨ BA. Chúng tôi thực hiện các biện pháp bảo mật khác nhau để duy trì sự an toàn của dữ liệu cá nhân của bạn mà chúng tôi lưu giữ hoặc kiểm soát. Dữ liệu cá nhân của bạn được lưu đằng sau các mạng bảo mật và chỉ có thể được truy cập bởi một số cá nhân giới hạn có quyền truy cập đặc biệt đến các hệ thống của chúng tôi, và đã được yêu cầu bảo mật dữ liệu cá nhân đó. Khi bạn đặt hàng hoặc truy cập dữ liệu cá nhân của bạn, chúng tôi đề nghị sử dụng một máy chủ bảo mật. Tất cả dữ liệu cá nhân hoặc thông tin cá nhân bạn cung cấp sẽ được mã hóa vào các cơ sở dữ liệu của chúng tôi để chỉ được truy cập như mô tả bên trên.
    7.2. Nhằm cung cấp cho bạn giá trị gia tăng, chúng tôi có thể chọn các trang web hoặc ứng dụng hoặc dịch vụ của bên thứ ba khác nhau để liên kết, và đóng khung bên trong Nền tảng. Chúng tôi cũng có thể tham gia các quan hệ cùng tiếp thị và các quan hệ khác để cung cấp dịch vụ thương mại điện tử và các dịch vụ và tính năng khác cho khách truy cập. Những trang được liên kết này có các chính sách về quyền riêng tư cũng như các biện pháp bảo mật riêng và độc lập. Ngay cả khi bên thứ ba đó có liên kết với chúng tôi, chúng tôi cũng không kiểm soát các trang web/ứng dụng/dịch vụ được liên kết này, mỗi trang đó có các phương pháp bảo vệ quyền riêng tư và thu thập dữ liệu riêng biệt, độc lập với chúng tôi. Dữ liệu thu thập bởi các đối tác cùng tiếp thị của chúng tôi hoặc các trang web/ứng dụng/dịch vụ của bên thứ ba (ngay cả khi được cung cấp trên hoặc thông qua Nền tảng của chúng tôi) có thể không được chúng tôi tiếp cận và/hoặc lưu giữ.
    7.3. Do đó chúng tôi không chịu trách nhiệm hay trách nhiệm pháp lý đối với nội dung, các biện pháp bảo mật (hoặc sự thiếu biện pháp bảo mật) và các hoạt động của các trang web/ứng dụng/dịch vụ được liên kết này. Những trang web/ứng dụng/dịch vụ được liên kết này chỉ vì sự thuận tiện cho bạn và do đó bạn tự chịu trách nhiệm khi truy cập chúng. Tuy nhiên, chúng tôi tìm cách bảo vệ tính toàn vẹn của Nền tảng của chúng tôi và các liên kết được đặt trên từng trang web đó và do đó chúng tôi hoan nghênh ý kiến phản hồi về các trang web được liên kết này (bao gồm nếu một trang web cụ thể không hoạt động).
  `,
  terms_of_use: "Điều khoản sử dụng",
  rules_title: "Điều khoản",
  privacy_title: "Chính sách bảo mật",
  delete: "Xoá",
  contact_info: "Thông tin liên hệ",
};
