<template>
  <div class="modal fade" tabindex="-1" :id="`modal${modalId}`">
    <div class="modal-dialog" :class="customClassDialog">
      <div class="modal-content" :class="customClass">
        <slot name="modal-header">
          <div class="modal-header">
            <h5 class="modal-title">{{ title || "Title" }}</h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
        </slot>
        <div class="modal-body">
          <slot name="modal-body"></slot>
        </div>
        <slot name="modal-footer">
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
              {{ $t("close") }}
            </button>
            <button type="button" class="btn btn-primary" @click="submitAction">
              {{ $t("submit") }}
            </button>
          </div>
        </slot>
      </div>
    </div>
  </div>
</template>
<script>
import emitter from "@/mitt";
import { Modal } from "bootstrap";

export default {
  props: {
    submitAction: {
      type: Function,
      default: () => {},
    },
    title: String,
    modalId: String,
    showOnDefault: Boolean,
    customClass: Object,
    customClassDialog: Object,
  },
  created() {
    emitter.on(`show-modal${this.modalId}`, () => {
      this.modal.show();
    });
    emitter.on(`close-modal${this.modalId}`, () => {
      this.modal.hide();
    });
  },
  data() {
    return {
      modal: undefined,
    };
  },
  mounted() {
    this.modal = new Modal(document.getElementById(`modal${this.modalId}`));
    if (this.showOnDefault) {
      this.modal.show();
    }
  },
  methods: {},
  unmounted() {
    emitter.off(`close-modal${this.modalId}`);
    emitter.off(`show-modal${this.modalId}`);
  },
};
</script>
<style lang="scss" scoped></style>
