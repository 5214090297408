import axios from 'axios';
import statusCode from './statusCode';
import { useToast } from 'vue-toast-notification';
import routes from '@/routes';
import constants from './constants';

const $toast = useToast({
	position: 'top-right',
});

const HTTP = axios.create({
	baseURL: process.env.VUE_APP_API_URL,
	headers: {
		// 'Content-type': 'application/json',
    // "Accept-Language": "en-US,en;"
	},
	showToast: false,
	showToastError: true,
	customToastLocation: '',
});

HTTP.interceptors.request.use(function (config) {
	const token = localStorage.getItem('access_token');
	if (token) {
		config.headers = {
			...config.headers,
			Authorization: `Bearer ${token}`,
      "lang": localStorage.getItem('country')?.slice(0, 2) == 'en' ? 'en' : 'vi',
		};
	}
	return config;
});

HTTP.interceptors.response.use(
	(response) => {
		const data = response?.data;
		const showToast = response.config.showToast;
		const customToastLocation = response.config.customToastLocation;
    const toastConfig = {
      position: 'top-right',
    }

    if (customToastLocation) {
      toastConfig.position = customToastLocation;
    }
		if (response?.status === statusCode.UN_AUTHORIZED) {
			constants.clearStorages(['access_token', 'displayedModal'])
			if (!window.location.pathname.includes('/login')) routes.push({ name: 'login' });
		} else if (data || [statusCode.OK, statusCode.CREATED].includes(data?.status)) {
			if (data?.message && showToast) {
				$toast.success(data?.message, toastConfig);
			} else if (showToast) {
				$toast.success('Thành công!', toastConfig);
			}
		} else if (data?.error || [statusCode.BAD_REQUEST, statusCode.INTERNAL_SERVER_ERROR].includes(data?.status)) {
			$toast.error(data?.message || 'Error!');
			if (data?.status === statusCode.UN_AUTHORIZED) {
				constants.clearStorages(['access_token', 'displayedModal'])
				if (!window.location.pathname.includes('/login')) routes.push({ name: 'login' });
			}
		}
		return data;
	},
	(error) => {
		if (error.response) {
			const { message } = error.response.data;
			if (error.response.status === statusCode.UN_AUTHORIZED) {
				constants.clearStorages(['access_token', 'displayedModal'])
				if (!window.location.pathname.includes('/login')) routes.push({ name: 'login' });
			} else if (error.response?.data?.errors) {
				// do anything
			} else if (error.config.showToastError) {
				$toast.error(message || 'Error!');
			}
		}
		throw error;
	}
);

export default HTTP;
