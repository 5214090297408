import constants from "@/constants";

// mutations.js
export default {
  user(state, user) {
    state.user = user;
  },

  loading(state, status) {
    state.loading = status;
  },

  childLoading(state, status) {
    state.childLoading = status;
  },

  users(state, users) {
    state.users = users;
  },

  updatedData(state, data) {
    state.updatedData = data;
  },

  products(state, products) {
    state.products = products;
  },

  withdrawHistories(state, withdrawHistories) {
    state.withdrawHistories = withdrawHistories;
  },

  depositHistories(state, depositHistories) {
    state.depositHistories = depositHistories;
  },

  messages(state, messages) {
    if (messages?.length) {
      state.messages = messages.sort((a, b) => Number(b.id) - Number(a.id));
    } else {
      state.messages = [];
    }
  },

  addMessage(state, message) {
    if (state.user?.role !== "ADMIN" && (state.user?.id == message?.sender?.id || state.user?.id == message?.receiver?.id)) {
      state.messages.unshift(message);
    }
  },

  conversations(state, conversations) {
    state.conversations = conversations;
  },

  addMessageToConversations(state, message) {
    if (state.user?.role == "ADMIN" && (state.user?.id == message?.sender?.id || state.user?.id == message?.receiver?.id)) {
      const conversationIndex = state.conversations.findIndex(conversation => conversation.id == message.chat_group?.id);
      if (conversationIndex >= 0) {
        state.conversations[conversationIndex].messages.unshift(message);
      } else {
        const createConversation = {
          ...message.chat_group,
          messages: [
            message
          ]
        }
        state.conversations.unshift(createConversation);
      }
    }
  },

  levels(state, levels) {
    state.levels = levels;
  },

  orderData(state, orderData) {
    state.orderData = orderData;
  },

  orders(state, orders) {
    state.orders = orders;
  },

  bankAccount(state, bankAccount) {
    state.bankAccount = bankAccount;
  },

  errors(state, errors) {
    state.errors = errors;
  },

  bankList(state, bankList) {
    state.bankList = bankList;
  },

  specialOrders(state, specialOrders) {
    if (!specialOrders?.length) {
      specialOrders = [
        {
          ...constants.SPECIAL_ORDER_ORIGIN
        }
      ]
    }
    state.specialOrders = specialOrders;
  },
  addSpecialOrder(state) {
    state.specialOrders.push({
      ...constants.SPECIAL_ORDER_ORIGIN
    });
  },
  removeSpecialOrder(state, index) {
    state.specialOrders.splice(index, 1);
  },

  reports(state, reports) {
    state.reports = reports;
  },

  setBanners(state, banners) {
    state.banners = banners;
  },
}
