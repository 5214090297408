<template>
  <div class="d-flex flex-column">
    <div class="d-flex justify-content-center fs-4 position-relative py-2">
      <button @click="$router.go(-1)" class="btn text-white btn-back">
        <IconChevronLeft />
      </button>
      <div>{{ $t("menu.account") }}</div>
    </div>
  </div>
  <div class="d-flex flex-column mt-4 px-3 pb-5" v-if="bank">
    <div class="card detail">
      <div class="card-body">
        <div class="fw-bold fs-5">{{ bank?.account_name }} | {{ bank?.bank_name }}</div>
        <div class="mb-2 fs-6">{{ bank?.bank_branch }}</div>
        <div class="fw-bold fs-5">{{ bank?.account_number }}</div>
      </div>
    </div>
  </div>
</template>
<script>
import { IconChevronLeft } from "@tabler/icons-vue";
import { mapGetters } from "vuex";

export default {
  components: { IconChevronLeft },
  computed: {
    ...mapGetters(["getUserData"]),
    bank() {
      return this.getUserData?.bank_accounts?.[0];
    },
  },
};
</script>
<style lang="scss" scoped>
.detail {
  background: url("@/assets/images/account/my_account.png");
  background-size: cover;
  border-radius: 24px;
  border: 0;
}
</style>
