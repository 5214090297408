<template>
  <div class="page-container">
    <div class="page" :class="{ 'pb-0': isDisableHeader }">
      <HeaderComponent v-if="!isDisableHeader" />
      <router-view />
    </div>
    <FooterComponent v-if="!isDisableHeader" />
    <ModalComponent
      v-if="!displayedModal"
      :modalId="'Home'"
      :show-on-default="true"
      :custom-class="{ 'modal-black': true }"
    >
      <template v-slot:modal-header>
        <div class="modal-header">
          <h5 class="text-center w-100">{{ $t("notification") }}</h5>
        </div>
      </template>
      <template v-slot:modal-body>
        <h5 class="text-center">
          {{ $t("welcome_modal_content") }}
        </h5>
      </template>
      <template v-slot:modal-footer>
        <div class="modal-footer justify-content-center">
          <button type="button" class="btn btn-system w-75" data-bs-dismiss="modal">
            {{ $t("close") }}
          </button>
        </div>
      </template>
    </ModalComponent>
  </div>
</template>

<script>
import FooterComponent from "@/components/FooterComponent";
import HeaderComponent from "@/components/HeaderComponent";
import ModalComponent from "@/components/ModalComponent.vue";

export default {
  name: "ClientPage",
  setup() {
    const disableHeaderRouters = [
      "chat",
      "withdraw",
      "withdraw.history",
      "deposit.history",
      "transfer",
      "profile",
      "updatePassword",
      "updateWithdrawPassword",
      "bankAccount",
      "reports",
      "share",
      "deposit",
      "myBankAccount",
    ];

    const displayedModal = localStorage.getItem("displayedModal");
    if (!displayedModal) {
      localStorage.setItem("displayedModal", "1");
    }

    return {
      disableHeaderRouters,
      displayedModal,
    };
  },
  computed: {
    isDisableHeader() {
      return this.disableHeaderRouters.includes(this.$route.name);
    },
  },
  components: { FooterComponent, HeaderComponent, ModalComponent },
};
</script>

<style lang="scss" scoped>
.page {
  padding-bottom: 100px;
  background-image: url("@/assets/images/bg.png");
  background-repeat: no-repeat;
  background-size: 100% 90vh;
}

.page-container {
  overflow-y: auto;
  overflow-x: hidden;
}
</style>
