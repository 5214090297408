const PAYMENT_STATUS = {
    PENDING: 1,
    SUCCESS: 2,
    REJECTED: 3,
  }
const ORDER_STATUS = {
    PENDING: 1,
    SUCCESS: 2,
    FREEZE: 3,
  }

  const LEVELS = {
    1: {
      name: 'SLIVER',
      image: require('@/assets/images/levels/sliver.png'),
      bg: require('@/assets/images/levels/sliver_bg.png'),
      bg_color: "#8a97a5",
    },
    2: {
      name: 'GOLD',
      image: require('@/assets/images/levels/gold.png'),
      bg: require('@/assets/images/levels/gold_bg.png'),
      bg_color: "#c9a771",
    },
    3: {
      name: "DAIMON",
      image: require('@/assets/images/levels/daimon.png'),
      bg: require('@/assets/images/levels/daimon_bg.png'),
      bg_color: "#6fa4ba",
    },
    4: {
      name: "CROWN",
      image: require('@/assets/images/levels/crown.png'),
      bg: require('@/assets/images/levels/crown_bg.png'),
      bg_color: "#9284e6",
    },
    noLevel: {
      name: "NO_RANKING",
      image: require('@/assets/images/levels/no_level.png'),
      bg: require('@/assets/images/levels/no_level_bg.png'),
      bg_color: "#777f87",
    },
    getLevelInfo: function (level) {
      if (!this[level] || !level) {
        return this.noLevel;
      }
      return this[level];
    }
  }

  const randomIntFromInterval = (min = 500, max = 1000) => {
    return Math.floor(Math.random() * (max - min + 1) + min);
  }

  const makeId = (length = 2) => {
    let result = "";
    const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < length) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
      counter += 1;
    }
    return result;
  }

  const clearStorages = (storageItems) => {
    storageItems.forEach(item => {
      localStorage.removeItem(item)
    })
  }

  const SPECIAL_ORDER_ORIGIN = {
    "id": null,
    "product_id": '',
    "price": '',
    "profit_percent": '',
    "profit_amount": '',
    "available_time": ''
  }

export default {
  PAYMENT_STATUS,
  ORDER_STATUS,
  LEVELS,
  SPECIAL_ORDER_ORIGIN,
  randomIntFromInterval,
  makeId,
  clearStorages
};

const PROFIT_RATE = 1000;

export function getLevelProfitPercent(level) {
  const levelId = level?.id;
  if (!levelId) {
    return 0;
  }
  return (levelId == 1 ? levelId : levelId + 1) / PROFIT_RATE;
}

export const BANK_INFO = {
  "name": "VUONG KHANH LINH",
  "account_number": "19039059209013",
  "bank_name": "TECKCOMBANK",
}
