<template>
  <div class="px-3">
    <div class="homepage-top">
      <BannerComponent></BannerComponent>
      <div class="my-4">
        <NotificationComponent></NotificationComponent>
      </div>
      <div class="mb-4">
        <BalanceComponent />
      </div>
      <div>
        <SubmenuComponent></SubmenuComponent>
      </div>
    </div>
    <div class="mb-5">
      <SummaryComponent />
    </div>
    <div>
      <LevelContainerComponent />
    </div>
    <div>
      <h4>{{ $t("daily_income") }}</h4>
      <DailyIncomeComponent />
    </div>
    <div class="introduce">
      <h4 class="mb-3">{{ $t("platform_introduction.title") }}</h4>
      <div class="introduce-card-container">
        <div class="introduce-card about-us" @click="popupType = 'about_us'">
          <img src="@/assets/images/introduce/about_us.png" class="introduce-img" />
          <p class="mt-3 text-center mb-0 text-black">
            {{ $t("platform_introduction.about_us") }}
          </p>
        </div>
        <div class="introduce-card company" @click="popupType = 'company'">
          <img src="@/assets/images/introduce/company.png" class="introduce-img" />
          <p class="mt-3 text-center mb-0 text-black">
            {{ $t("platform_introduction.company") }}
          </p>
        </div>
        <div class="introduce-card rule" @click="popupType = 'rule'">
          <img src="@/assets/images/introduce/rule.png" class="introduce-img" />
          <p class="mt-3 text-center mb-0 text-black">
            {{ $t("platform_introduction.rule") }}
          </p>
        </div>
        <div class="introduce-card hoptac" @click="popupType = 'poxy'">
          <img src="@/assets/images/introduce/hoptac.png" class="introduce-img" />
          <p class="mt-3 text-center mb-0 text-black">
            {{ $t("platform_introduction.proxy") }}
          </p>
        </div>
        <div class="introduce-card policy" @click="popupType = 'policy'">
          <img src="@/assets/images/introduce/policy.png" class="introduce-img" />
          <p class="mt-3 text-center mb-0 text-black">
            {{ $t("platform_introduction.policy") }}
          </p>
        </div>
      </div>
      <h4 class="mt-3">{{ $t("partner") }}</h4>
      <PartnerComponent />
    </div>
  </div>
  <IntroducePopup v-if="popupType" :popup-type="popupType" />
</template>
<script>
import BalanceComponent from "@/components/Home/BalanceComponent.vue";
import BannerComponent from "@/components/Home/BannerComponent.vue";
import NotificationComponent from "@/components/Home/NotificationComponent.vue";
import SubmenuComponent from "@/components/Home/SubmenuComponent.vue";
import SummaryComponent from "@/components/Home/SummaryComponent.vue";
import LevelContainerComponent from "@/components/Home/LevelContainerComponent.vue";
import { mapGetters } from "vuex";
import DailyIncomeComponent from "@/components/Home/DailyIncomeComponent.vue";
import PartnerComponent from "@/components/Home/PartnerComponent.vue";
import IntroducePopup from "@/components/Home/IntroducePopup.vue";
import emitter from "@/mitt";

export default {
  components: {
    BannerComponent,
    NotificationComponent,
    BalanceComponent,
    SubmenuComponent,
    SummaryComponent,
    LevelContainerComponent,
    DailyIncomeComponent,
    PartnerComponent,
    IntroducePopup,
  },
  mounted() {
    emitter.on("close-popup", () => {
      this.popupType = "";
    });
  },
  computed: {
    ...mapGetters(["getUserData"]),
    user() {
      return this.getUserData;
    },
  },
  data() {
    return {
      popupType: "",
    };
  },
  methods: {},
};
</script>
<style lang="scss" scoped>
.homepage-top {
  padding-bottom: 40px;
}

.introduce {
  margin-top: 32px;
  background-color: #303c48;
  padding: 16px;
  border-radius: 32px;
}

.introduce-card {
  background-size: 100% 100%;
  padding: 16px;
  border-radius: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}

.about-us {
  background-image: url("@/assets/images/introduce/about_us_bg.png");
}

.company {
  background-image: url("@/assets/images/introduce/company_bg.png");
}

.rule {
  background-image: url("@/assets/images/introduce/rule_bg.png");
}

.hoptac {
  background-image: url("@/assets/images/introduce/hoptac_bg.png");
}

.policy {
  background-image: url("@/assets/images/introduce/policy_bg.png");
}

.introduce-img {
  max-width: 100px;
}

.right,
.left {
  .introduce-img {
    max-width: 100px;
  }
}

.introduce-card-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  row-gap: 16px;
  column-gap: 16px;
}
</style>
