<template>
  <div class="p-3">
    <h2 class="mb-3">{{ $t("users") }}</h2>
    <div class="mb-3 row justify-content-between align-items-center">
      <div class="col d-flex">
        <label for="search" class="col-sm-2 col-form-label">{{ $t("search") }}:</label>
        <input type="text" class="form-control" id="search" v-model="searchValue" />
      </div>
      <div class="col">
        <button class="btn btn-info float-end" @click="showCreateModal">
          {{ $t("new") }}
        </button>
      </div>
    </div>
    <div>
      <EasyDataTable
        :headers="headers"
        :items="users"
        :search-field="searchFields"
        :header-text-direction="'center'"
        :search-value="searchValue"
        buttons-pagination
      >
        <template #item-operation="item">
          <div class="py-1 d-flex justify-content-center">
            <button class="btn btn-primary p-1 me-1" @click="showRow(item, 'user')">
              <IconAddressBook />
            </button>
            <button class="btn btn-primary p-1 me-1" @click="showRow(item, 'bank')">
              <IconBuildingBank />
            </button>
            <button class="btn btn-primary p-1 me-1" @click="showRow(item, 'products')">
              <IconShoppingCartPlus />
            </button>
            <button class="btn btn-primary p-1" @click="showRow(item, 'event')">
              <IconCalendarEvent />
            </button>
          </div>
        </template>
        <template #item-delete="item">
          <div class="py-1 d-flex justify-content-center">
            <button class="btn btn-danger p-1" @click="showDeleteModal(item)">
              <IconTrash />
            </button>
          </div>
        </template>
      </EasyDataTable>
    </div>
    <ModalComponent
      :submit-action="modalAction"
      :title="$t('user') + ': ' + selectedUser?.name"
      :modal-id="modalId"
      :custom-class-dialog="[modalType == 'event' ? 'modal-xl' : 'modal-lg']"
    >
      <template v-slot:modal-body>
        <div v-if="selectedUser">
          <div v-if="modalType == 'user'">
            <div class="mb-3">
              <label for="name" class="form-label">{{ $t("name") }}</label>
              <input
                type="text"
                class="form-control"
                id="name"
                v-model="selectedUser.name"
              />
            </div>
            <div class="mb-3">
              <label for="email" class="form-label">Email</label>
              <input
                type="email"
                class="form-control"
                id="email"
                placeholder="name@example.com"
                v-model="selectedUser.email"
              />
            </div>
            <div class="mb-3" v-if="selectedUser.role === 1">
              <label for="invite_id" class="form-label">{{ $t("placeholder.invite_code") }}</label>
              <div class="d-flex">
                <input
                  type="number"
                  class="form-control"
                  id="invite_id"
                  v-model="selectedUser.invite_id"
                  maxlength = "6"
                />
                <button class="btn btn-info text-light ms-3" @click="generateInvitedCode">
                  <IconRefreshDot />
                </button>
              </div>
            </div>
            <div class="mb-3">
              <label for="Password" class="form-label">{{ $t("password") }}</label>
              <input
                type="password"
                class="form-control"
                id="Password"
                v-model="selectedUser.password"
              />
            </div>
            <div class="mb-3">
              <label for="phone" class="form-label">{{ $t("auth.phone_number") }}</label>
              <input
                type="text"
                class="form-control"
                id="phone"
                v-model="selectedUser.phone"
              />
            </div>
            <div class="mb-3">
              <label for="balance" class="form-label">
                {{ $t("available_balance") }}
              </label>
              <input
                type="text"
                class="form-control"
                id="balance"
                v-model="selectedUser.balance"
              />
            </div>
            <div class="mb-3">
              <label for="address" class="form-label">{{ $t("address") }}</label>
              <input
                type="text"
                class="form-control"
                id="address"
                v-model="selectedUser.address"
              />
            </div>
          </div>
          <div v-else-if="modalType == 'bank'">
            <h5>{{ $t("menu.bank_account") }}</h5>
            <div class="mb-3">
              <label for="account_name" class="form-label">
                {{ $t("placeholder.account_name") }}
              </label>
              <input
                type="text"
                class="form-control"
                id="account_name"
                v-model="bankAccount.account_name"
              />
            </div>
            <div class="mb-3">
              <label for="bank_name" class="form-label">
                {{ $t("placeholder.bank_name") }}
              </label>
              <VSelect
                :options="bankList"
                v-model="bankAccount.bank_name"
                label="shortName"
                :placeholder="$t('placeholder.bank_name')"
                :reduce="(b) => b.shortName"
              >
                <template #option="{ shortName, name, logo }">
                  <div>
                    <img :src="logo" alt="logo" class="bank-logo" />
                    {{ shortName }}|{{ name }}
                  </div>
                </template>
              </VSelect>
            </div>
            <div class="mb-3">
              <label for="account_number" class="form-label">
                {{ $t("placeholder.account_number") }}
              </label>
              <input
                type="text"
                class="form-control"
                id="account_number"
                v-model="bankAccount.account_number"
              />
            </div>
            <div class="mb-3">
              <label for="account_branch" class="form-label">
                {{ $t("placeholder.branch") }}
              </label>
              <input
                type="text"
                class="form-control"
                id="account_branch"
                v-model="bankAccount.bank_branch"
              />
            </div>
          </div>
          <div v-else-if="modalType == 'event'">
            <div class="d-flex">
              <p class="me-3">
                {{ $t("order.current_orders") }}: {{ selectedUser.orders?.length || 0 }}
              </p>
            </div>
            <div class="d-flex">
              <p class="me-3">{{ $t("status.title") }}:</p>
              <label class="switch">
                <input
                  :checked="selectedUser.special_order_status"
                  @change="handleUpdateUserOrderStatus"
                  type="checkbox"
                />
                <span class="slider"></span>
              </label>
            </div>
            <div class="spinner-border text-secondary" role="status" v-if="childLoading">
              <span class="visually-hidden">Loading...</span>
            </div>
            <table class="table table-bordered" v-else>
              <thead>
                <tr>
                  <th>{{ $t("products") }}</th>
                  <th>{{ $t("product.price") }}</th>
                  <th>% {{ $t("commission") }}</th>
                  <th>{{ $t("order.get_commissions") }}</th>
                  <th>{{ $t("order.available_at") }}</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <template v-for="(setting, index) in specialOrders" :key="index">
                  <tr>
                    <td class="w-25">
                      <VSelect
                        class="flex-grow-1"
                        :options="products"
                        label="name"
                        :reduce="(p) => p.id"
                        @option:selected="(val) => selectedProduct(val, setting)"
                        v-model="setting.product_id"
                        :placeholder="$t('products')"
                      />
                    </td>
                    <td>
                      <input
                        v-model="setting.price"
                        type="number"
                        class="form-control"
                        @keyup="updateProfitAmount(setting)"
                        @change="updateProfitAmount(setting)"
                        :placeholder="$t('product.price')"
                      />
                    </td>
                    <td>
                      <input
                        v-model="setting.profit_percent"
                        type="number"
                        class="form-control"
                        @keyup="updateProfitAmount(setting)"
                        @change="updateProfitAmount(setting)"
                        :placeholder="$t('commission')"
                      />
                    </td>
                    <td>
                      <input
                        v-model="setting.profit_amount"
                        type="number"
                        class="form-control"
                        :placeholder="$t('order.get_commissions')"
                      />
                    </td>
                    <td>
                      <input
                        v-model="setting.available_time"
                        type="number"
                        class="form-control"
                        :placeholder="$t('order.available_at')"
                      />
                    </td>
                    <td class="align-middle">
                      <div class="d-flex">
                        <span
                          class="text-danger me-2"
                          role="button"
                          v-if="specialOrders?.length > 1"
                          @click="removeSetting(index)"
                        >
                          <IconSquareRoundedMinus />
                        </span>
                        <span class="text-success" role="button" @click="addSetting">
                          <IconSquareRoundedPlusFilled />
                        </span>
                      </div>
                    </td>
                  </tr>
                </template>
              </tbody>
            </table>
          </div>
          <div v-else>
            <div class="mb-3">
              <label for="name" class="form-label">{{ $t("name") }}</label>
              <input
                type="text"
                class="form-control"
                id="name"
                v-model="selectedUser.name"
                disabled
              />
            </div>
            <div class="mb-3">
              <label for="address" class="form-label">{{ $t("products") }}</label>
              <VSelect
                :options="products"
                label="name"
                :reduce="(p) => p.id"
                multiple
                v-model="productIds"
              >
                <template #option="{ name, price }">
                  <p>{{ name }} - ${{ price }}</p>
                </template>
              </VSelect>
              <div>{{ $t("products") }}: {{ productIds?.length || 0 }}</div>
            </div>
          </div>
        </div>
      </template>
    </ModalComponent>
    <ModalComponent
      :submit-action="deleteUserAction"
      :title="$t('user')"
      :modal-id="modalDeleteId"
    >
      <template v-slot:modal-body>
        <div v-if="selectedUser">
          <p class="text-center">{{ $t("delete_message") }}</p>
          <p class="text-center fw-bold text-danger">{{ selectedUser?.name }}</p>
        </div>
      </template>
    </ModalComponent>
  </div>
</template>
<script>
import ModalComponent from "@/components/ModalComponent.vue";
import constants from "@/constants";
import emitter from "@/mitt";
import { IconTrash } from "@tabler/icons-vue";
import {
  IconSquareRoundedMinus,
  IconSquareRoundedPlusFilled,
  IconShoppingCartPlus,
  IconAddressBook,
  IconBuildingBank,
  IconCalendarEvent,
  IconRefreshDot,
} from "@tabler/icons-vue";
import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    ModalComponent,
    IconShoppingCartPlus,
    IconAddressBook,
    IconBuildingBank,
    IconCalendarEvent,
    IconSquareRoundedPlusFilled,
    IconSquareRoundedMinus,
    IconTrash,
    IconRefreshDot
},
  setup() {
    const searchFields = ["email", "name", "phone", "balance", "id"];

    const modalId = "User";
    const modalDeleteId = "UserDelete";

    return {
      searchFields,
      modalId,
      modalDeleteId,
    };
  },
  computed: {
    ...mapGetters([
      "getDataUsers",
      "getUpdatedData",
      "getDataProducts",
      "getDataBankList",
      "getDataSpecialOrders",
      "getChildLoading",
    ]),
    users() {
      return this.getDataUsers;
    },
    childLoading() {
      return this.getChildLoading;
    },
    specialOrders() {
      return this.getDataSpecialOrders;
    },
    updatedData() {
      return this.getUpdatedData;
    },
    products() {
      return this.getDataProducts;
    },
    headers() {
      return [
        { text: "ID", value: "id" },
        { text: this.$t("name"), value: "name" },
        { text: "Email", value: "email" },
        { text: this.$t("auth.phone_number"), value: "phone" },
        { text: this.$t("available_balance"), value: "balance" },
        { text: this.$t("setting"), value: "operation" },
        { text: "", value: "delete" },
      ];
    },
    bankList() {
      return this.getDataBankList;
    },
  },
  mounted() {
    this.getUsers();
    this.getProducts();
    this.getBankList();
  },
  data() {
    return {
      searchValue: "",
      selectedUser: null,
      modalType: null,
      productIds: [],
      bankAccount: null,
    };
  },
  methods: {
    ...mapActions([
      "getUsers",
      "createOrUpdateUser",
      "getProducts",
      "addProductToUser",
      "adminAddBank",
      "getBankList",
      "updateBankAccount",
      "deleteUser",
      "getSpecialOrder",
      "updateUserOrderStatus",
      "addSpecialOrder",
      "removeSpecialOrder",
      "addSpecialOrders",
    ]),
    showRow(item, modalType = "user") {
      this.modalType = modalType;
      this.selectedUser = item;
      this.bankAccount = {
        user_id: item.id,
        account_name: "",
        bank_name: "",
        account_number: "",
        ...item.bank_accounts[0],
      };
      this.productIds = item.products.map((p) => p.id);

      if (modalType == "event") {
        this.getSpecialOrder(item.id);
      }
      emitter.emit(`show-modal${this.modalId}`);
    },
    handleAddProductsToUser() {
      this.addProductToUser({
        user_id: this.selectedUser.id,
        products: this.productIds,
      });
    },
    showCreateModal() {
      this.selectedUser = {};
      emitter.emit(`show-modal${this.modalId}`);
    },
    updateOrCreateUser() {
      this.createOrUpdateUser(this.selectedUser);
    },
    addOrUpdateBankAccount() {
      if (this.bankAccount.id) {
        this.updateBankAccount(this.bankAccount);
      } else {
        this.adminAddBank(this.bankAccount);
      }
    },
    modalAction() {
      if (this.modalType == "user") {
        this.updateOrCreateUser();
      } else if (this.modalType == "bank") {
        this.addOrUpdateBankAccount(this.bankAccount);
      } else if (this.modalType == "event") {
        this.addSpecialOrders({
          user_id: this.selectedUser.id,
          products: this.specialOrders,
        });
      } else {
        this.handleAddProductsToUser();
      }
    },
    addSetting() {
      this.addSpecialOrder();
    },
    removeSetting(settingIndex) {
      this.removeSpecialOrder(settingIndex);
    },
    deleteUserAction() {
      this.deleteUser({
        type: "users",
        id: this.selectedUser.id,
      });
    },
    showDeleteModal(item) {
      this.selectedUser = item;
      emitter.emit(`show-modal${this.modalDeleteId}`);
    },
    handleUpdateUserOrderStatus(event) {
      this.updateUserOrderStatus({
        user_id: this.selectedUser.id,
        status: event.target.checked,
      });
      this.selectedUser.special_order_status = event.target.checked;
    },
    updateProfitAmount(item) {
      item.profit_amount = Number(item.price * item.profit_percent).toFixed(2);
    },

    selectedProduct(product, setting) {
      setting.price = Number(product.price).toFixed(2);
    },

    generateInvitedCode() {
      let newCode = constants.randomIntFromInterval(100000, 999999);
      this.selectedUser.invite_id = newCode;
    }
  },
  watch: {
    updatedData(newVal) {
      if (newVal) {
        this.getUsers();
        emitter.emit(`close-modal${this.modalId}`);
        emitter.emit(`close-modal${this.modalDeleteId}`);
        this.selectedUser = {};
        this.productIds = [];
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.switch {
  --button-width: 3em;
  --button-height: 1.5em;
  --toggle-diameter: 1em;
  --button-toggle-offset: calc((var(--button-height) - var(--toggle-diameter)) / 2);
  --toggle-shadow-offset: 10px;
  --toggle-wider: 2.5em;
  --color-grey: #cccccc;
  --color-green: #4296f4;
}

.slider {
  display: inline-block;
  width: var(--button-width);
  height: var(--button-height);
  background-color: var(--color-grey);
  border-radius: calc(var(--button-height) / 2);
  position: relative;
  transition: 0.3s all ease-in-out;
}

.slider::after {
  content: "";
  display: inline-block;
  width: var(--toggle-diameter);
  height: var(--toggle-diameter);
  background-color: #fff;
  border-radius: calc(var(--toggle-diameter) / 2);
  position: absolute;
  top: var(--button-toggle-offset);
  transform: translateX(var(--button-toggle-offset));
  box-shadow: var(--toggle-shadow-offset) 0 calc(var(--toggle-shadow-offset) * 4)
    rgba(0, 0, 0, 0.1);
  transition: 0.3s all ease-in-out;
}

.switch input[type="checkbox"]:checked + .slider {
  background-color: var(--color-green);
}

.switch input[type="checkbox"]:checked + .slider::after {
  transform: translateX(
    calc(var(--button-width) - var(--toggle-diameter) - var(--button-toggle-offset))
  );
  box-shadow: calc(var(--toggle-shadow-offset) * -1) 0
    calc(var(--toggle-shadow-offset) * 4) rgba(0, 0, 0, 0.1);
}

.switch input[type="checkbox"] {
  display: none;
}

.switch input[type="checkbox"]:active + .slider::after {
  width: var(--toggle-wider);
}

.switch input[type="checkbox"]:checked:active + .slider::after {
  transform: translateX(
    calc(var(--button-width) - var(--toggle-wider) - var(--button-toggle-offset))
  );
}
</style>
