<template>
  <div class="px-3">
    <div class="d-flex justify-content-around mb-4">
      <button
        @click="currentStatus = constants.ORDER_STATUS.PENDING"
        type="button"
        class="btn"
        :class="[
          currentStatus == constants.ORDER_STATUS.PENDING
            ? 'btn-primary'
            : 'btn-secondary',
        ]"
      >
        {{ $t("status.pending") }}
      </button>
      <button
        @click="currentStatus = constants.ORDER_STATUS.SUCCESS"
        type="button"
        class="btn"
        :class="[
          currentStatus == constants.ORDER_STATUS.SUCCESS
            ? 'btn-primary'
            : 'btn-secondary',
        ]"
      >
        {{ $t("status.success") }}
      </button>
      <button
        @click="currentStatus = constants.ORDER_STATUS.FREEZE"
        type="button"
        class="btn"
        :class="[
          currentStatus == constants.ORDER_STATUS.FREEZE
            ? 'btn-primary'
            : 'btn-secondary',
        ]"
      >
        {{ $t("status.freeze") }}
      </button>
    </div>
    <div class="mb-4">
      <p>{{ $t("balance") }}:</p>
      <h3 class="fw-bold">$ {{ user?.balance }}</h3>
    </div>
    <div>
      <template v-for="(order, index) in orders" :key="index">
        <div class="card mb-4">
          <div class="card-body">
            <div class="d-flex justify-content-between align-items-center">
              <div>{{ moment(order.created_at).format("Y/MM/DD hh:mm:ss") }}</div>
              <div>
                <BadgeComponent :status="order.status" :reject-label="'Đóng băng'" />
              </div>
            </div>
            <div class="d-flex mt-4">
              <div class="card-image me-3">
                <img :src="assetUrl + order.product_original?.img" />
              </div>
              <div class="product-description">
                <span class="fw-bold">{{ order.product_original?.description }}</span>
                <br />
                {{ order.product_original?.description }}
              </div>
            </div>
            <div class="mt-4">
              <div class="d-flex justify-content-between">
                <p>{{ $t("order.amount") }}</p>
                <p class="fw-bold">${{ order?.product_original?.price }}</p>
              </div>
              <div class="d-flex justify-content-between">
                <p>{{ $t("commission") }}</p>
                <p class="fw-bold">${{ order.profit_amount }}</p>
              </div>
              <div class="d-flex justify-content-between">
                <p>{{ $t("order.return_amount") }}</p>
                <p class="fw-bold">${{ order.total_value }}</p>
              </div>
              <div
                class="d-flex justify-content-center"
                v-if="order.status != constants.ORDER_STATUS.SUCCESS"
              >
                <button class="btn w-100 btn-system" @click="handleSubmitOrder(order.id)">
                  {{ $t("order.submit") }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </template>
    </div>
    <ModalComponent :modalId="modalId" :custom-class="{ 'modal-black': true }">
      <template v-slot:modal-header>
        <div class="modal-header">
          <h5 class="text-center w-100">{{ $t("notification") }}</h5>
        </div>
      </template>
      <template v-slot:modal-body>
        <h5 class="text-center lh-sm" v-html="$t('freeze_alert', {
          balance: user?.freeze_orders?.amount,
          profit: user?.freeze_orders?.profit
          }
        )">
        </h5>
      </template>
      <template v-slot:modal-footer>
        <div class="modal-footer justify-content-center">
          <button
            type="button"
            class="btn btn-secondary rounded-pill"
            data-bs-dismiss="modal"
          >
            {{ $t("cancel") }}
          </button>
        </div>
      </template>
    </ModalComponent>
  </div>
</template>
<script>
import BadgeComponent from "@/components/Home/BadgeComponent.vue";
import constants from "@/constants";
import emitter from "@/mitt";
import moment from "moment";
import { mapActions, mapGetters } from "vuex";
import ModalComponent from "@/components/ModalComponent.vue";

export default {
  setup() {
    const modalId = "Record"
    return {
      moment,
      constants,
      modalId
    };
  },
  computed: {
    ...mapGetters(["getUserData", "getDataOrders", "getUpdatedData"]),
    user() {
      return this.getUserData;
    },
    orders() {
      return this.getDataOrders;
    },
    updatedData() {
      return this.getUpdatedData;
    },
  },
  beforeMount() {
    this.setNullOrderPrize();
    this.getAuthOrders(this.currentStatus);
  },
  mounted() {
    if (Number(this.user?.freeze_balance)) {
      emitter.emit(`show-modal${this.modalId}`);
    }
  },
  data() {
    return {
      currentStatus: this.$route.query.status || constants.ORDER_STATUS.PENDING,
    };
  },
  methods: {
    ...mapActions(["getAuthOrders", "submitOrder", "getUserLogin", "setNullOrderPrize"]),
    handleSubmitOrder(orderId) {
      this.submitOrder({
        id: orderId,
        status: constants.ORDER_STATUS.SUCCESS,
      });
    },
  },
  components: { BadgeComponent, ModalComponent },
  watch: {
    currentStatus(newVal) {
      this.getAuthOrders(newVal);
    },
    updatedData(newVal) {
      if (newVal) {
        this.getAuthOrders(this.currentStatus);
        this.getUserLogin();
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.card {
  border: 0;
  overflow: hidden;
  background: #414d59;
  border-radius: 16px;
}

.card-image {
  width: 60px;
  height: 60px;
  background: white;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;

  img {
    object-fit: cover;
    height: 100%;
  }
}

.product-description {
  max-width: 70%;
}

.btn-submit {
  min-width: 50%;
}

.btn {
  border-radius: 24px;
  padding: 8px 16px;
  color: #ffffff;
}
</style>
