<template>
  <div class="d-flex flex-column chat-component">
    <div class="d-flex justify-content-center fs-4 position-relative py-2">
      <button @click="$router.go(-1)" class="btn text-white btn-back">
        <IconChevronLeft />
      </button>
      <div>{{ $t("menu.support") }}</div>
    </div>
    <div class="bg-primary px-3 py-2 d-flex align-items-center">
      <img src="@/assets/logo.png" class="avatar-small rounded-circle me-2" alt="" />
      <div>Admin</div>
    </div>
    <div class="chat-container py-4 px-2">
      <template v-for="(message, index) in messages" :key="index">
        <div class="message" :class="[message.sender.id == user.id ? 'you' : 'admin']">
          <div v-if="message.image" class="message-image">
            <img
              :src="assetUrl + message.image"
              @click="showImage(assetUrl + message.image)"
            />
          </div>
          <div class="message-content" v-if="message.content">{{ message.content }}</div>
          <small class="text-secondary">
            {{ moment(message.created_at).format("Y/MM/DD HH:mm:ss") }}
          </small>
        </div>
      </template>
    </div>
    <div>
      <div v-if="image">
        <div class="position-relative preview-img">
          <img :src="image" alt="" />
          <div class="remove" @click="removeImage">
            <IconX :size="16" />
          </div>
        </div>
      </div>
      <div class="message-send">
        <input
          type="file"
          ref="file"
          class="d-none"
          id="file"
          accept="image/*"
          @change="readUrl($event)"
        />
        <label for="file" class="select-image">
          <IconPhoto />
        </label>
        <input
          type="text"
          class="msg-input form-control"
          placeholder="Aa"
          v-model="message"
          @keyup.enter="handleSendMessage"
        />
        <div class="emoji-picker">
          <div class="btn-group dropup">
            <button
              type="button"
              class="btn emoji-picker-btn dropdown-toggle"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <IconMoodHappyFilled />
            </button>
            <div class="dropdown-menu p-0">
              <EmojiPicker
                :native="true"
                @select="onSelectEmoji"
                :disable-skin-tones="true"
              />
            </div>
          </div>
        </div>
        <div class="send" @click="handleSendMessage">
          <IconSend />
        </div>
      </div>
    </div>
  </div>
  <VueEasyLightbox :visible="visible" :imgs="imgs" @hide="onHide" />
</template>
<script>
import { socket } from "@/socket";
import { IconSend, IconX } from "@tabler/icons-vue";
import { IconPhoto } from "@tabler/icons-vue";
import { IconChevronLeft, IconMoodHappyFilled } from "@tabler/icons-vue";
import { mapActions, mapGetters } from "vuex";
import VueEasyLightbox from "vue-easy-lightbox/external-css";
import EmojiPicker from "vue3-emoji-picker";
import moment from "moment";

export default {
  components: {
    IconChevronLeft,
    IconSend,
    IconX,
    IconPhoto,
    VueEasyLightbox,
    EmojiPicker,
    IconMoodHappyFilled,
  },
  setup() {
    return {
      moment,
    };
  },
  data() {
    return {
      message: "",
      image: null,
      file: null,
      visible: false,
      imgs: "",
    };
  },
  computed: {
    ...mapGetters(["getDataMessages", "getUserData"]),
    messages() {
      return this.getDataMessages;
    },
    user() {
      return this.getUserData;
    },
  },
  beforeMount() {
    this.getMessages();
  },
  mounted() {
    socket.on("message", (message) => {
      this.addMessage(message);
    });
  },
  beforeUnmount() {
    socket.off("message");
  },
  methods: {
    ...mapActions(["getMessages", "sendMessages", "addMessage"]),
    handleSendMessage() {
      const validateMessage = this.message.replace(/\s{2,}/g, " ");
      if ((validateMessage && validateMessage != " ") || this.file) {
        const formData = new FormData();
        formData.append("receiver_id", 1);
        formData.append("content", validateMessage);
        if (this.file) {
          formData.append("image", this.file);
        }
        this.sendMessages(formData);
      }
      this.removeImage();
      this.message = "";
    },
    readUrl(event) {
      const input = event.target;
      if (input.files?.[0]) {
        const file = input.files[0];
        let reader = new FileReader();
        reader.onloadend = () => {
          this.image = reader.result;
        };
        reader.readAsDataURL(file);
        this.file = file;
      }
    },
    removeImage() {
      this.image = null;
      this.file = null;
      if (this.$refs.file) {
        this.$refs.file.value = null;
      }
    },
    showImage(image) {
      this.imgs = image;
      this.visible = true;
    },
    onHide() {
      this.visible = false;
      this.imgs = "";
    },
    onSelectEmoji(emoji) {
      this.message += emoji.i;
    },
  },
};
</script>
<style lang="scss" scoped></style>
