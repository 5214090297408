<template>
  <div class="d-flex justify-content-center">
    <router-link :to="{ name: 'deposit' }" class="submenu-item">
      <div class="submenu-icon">
        <IconCoin />
      </div>
      {{ $t("deposit") }}
    </router-link>
    <router-link :to="{ name: 'withdraw' }" class="submenu-item">
      <div class="submenu-icon">
        <IconCreditCard />
      </div>
      {{ $t("withdraw") }}
    </router-link>
    <div class="submenu-item clickable" @click="showModal">
      <div class="submenu-icon">
        <IconGift />
      </div>
      {{ $t("products") }}
    </div>
    <div role="button" @click="showModalShare" class="submenu-item">
      <div class="submenu-icon">
        <IconShare />
      </div>
      {{ $t("invite") }}
    </div>
  </div>
  <ModalComponent :modalId="'HomeProduct'" :custom-class="{ 'modal-black': true }">
    <template v-slot:modal-header>
      <div class="modal-header">
        <h5 class="text-center w-100">{{ $t("notification") }}</h5>
      </div>
    </template>
    <template v-slot:modal-body>
      <h5 class="text-center">{{ $t("stay_tuned") }}</h5>
    </template>
    <template v-slot:modal-footer>
      <div class="modal-footer justify-content-center">
        <button type="button" class="btn btn-system w-75" data-bs-dismiss="modal">
          {{ $t("close") }}
        </button>
      </div>
    </template>
  </ModalComponent>
  <ModalComponent :modalId="'Share'" :custom-class="{ 'modal-black': true }">
    <template v-slot:modal-header>
      <div class="modal-header">
        <h5 class="text-center w-100">{{ $t("notification") }}</h5>
      </div>
    </template>
    <template v-slot:modal-body>
      <h5 class="text-center">{{ $t("share_message") }}</h5>
    </template>
    <template v-slot:modal-footer>
      <div class="modal-footer justify-content-center">
        <button type="button" class="btn btn-system w-75" data-bs-dismiss="modal">
          {{ $t("close") }}
        </button>
      </div>
    </template>
  </ModalComponent>
</template>
<script>
import emitter from "@/mitt";
import { IconGift } from "@tabler/icons-vue";
import { IconShare } from "@tabler/icons-vue";
import { IconCreditCard } from "@tabler/icons-vue";
import { IconCoin } from "@tabler/icons-vue";
import ModalComponent from "../ModalComponent.vue";

export default {
  components: { IconCoin, IconCreditCard, IconGift, IconShare, ModalComponent },
  methods: {
    showModal() {
      emitter.emit(`show-modalHomeProduct`);
    },
    showModalShare() {
      emitter.emit(`show-modalShare`);
    },
  },
};
</script>
<style lang="scss" scoped>
.submenu-item {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 0 16px;
  font-size: 12px;
  text-decoration: none;
  color: white;
}

.submenu-icon {
  width: 50px;
  height: 50px;
  font-size: 24px;
  background-color: #53616d;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 8px;
}
</style>
