<template>
  <div>
    <div>
      <AdminHeaderComponent />
      <div class="container-fluid">
        <router-view />
      </div>
    </div>
  </div>
</template>

<script>
import AdminHeaderComponent from "@/components/admin/AdminHeaderComponent.vue";
export default {
  name: "AdminPage",
  components: { AdminHeaderComponent },
  methods: {

  },
};
</script>

<style lang="scss" scoped>
.container-fluid {
  background-color: white;
  height: calc(100vh - 100px);
  overflow: auto;
}
</style>
