<template>
  <div class="share-page d-flex flex-column justify-content-between">
    <div class="d-flex flex-column">
      <div class="d-flex justify-content-center fs-4 position-relative py-2">
        <button @click="$router.go(-1)" class="btn text-white btn-back">
          <IconChevronLeft />
        </button>
        <div>{{ $t("share") }}</div>
      </div>
    </div>
    <div class="pb-5 px-3">
      <!-- <div class="text-center">
        {{ $t("share_description") }}
      </div> -->
      <!-- <h4 class="fw-bold text-center mt-5 mb-4">
        {{ $t("placeholder.invite_code") }}: {{ user.invite_id }}
      </h4> -->
      <h4 class="fw-bold text-center mt-5 mb-4">
        {{ $t("share_message") }}
      </h4>
      <button class="btn btn-system w-100" @click="$router.push({name: 'support' })">
        {{ $t("menu.support") }}
      </button>
      <!-- <button class="btn btn-system w-100" @click="copyShareLink">
        <IconLink />
        {{ $t("copy") }}
      </button> -->
    </div>
  </div>
</template>
<script>
import { IconChevronLeft } from "@tabler/icons-vue";
import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters(["getDataMessages", "getUserData"]),
    user() {
      return this.getUserData;
    },
  },
  data() {
    return {};
  },
  methods: {
    copyShareLink() {
      navigator.clipboard.writeText(
        window.location.origin + "/#/register?invite_code=" + this.user.invite_id
      );
      this.$toast.success("Sao chép liên kết thành công", {
        position: "top-right",
      });
    },
  },
  components: { IconChevronLeft },
};
</script>
<style lang="scss" scoped>
.share-page {
  background-image: url("@/assets/images/share_bg.png");
  height: 100vh;
  background-size: cover;
}
</style>
