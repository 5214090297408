<template>
  <div class="confetti-land">
    <template v-for="(item, index) in 75" :key="index">
      <div class="confetti">
        <div class="rotate">
          <div class="askew"></div>
        </div>
      </div>
    </template>
    <div class="pyro">
      <div class="before"></div>
      <div class="after"></div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  mounted() {
    this.makeItConfetti();
  },
  methods: {
    makeItConfetti() {
      var confetti = document.querySelectorAll(".confetti");

      if (!confetti[0].animate) {
        return false;
      }

      for (let i = 0, len = confetti.length; i < len; ++i) {
        const candyCorn = confetti[i];
        const scale = Math.random() * 1 + 0.3;
        candyCorn.animate(
          [
            {
              transform: `translate3d(${
                (i / len) * 580
              }px,-5vh,0) scale(${scale}) rotate(0turn)`,
              opacity: scale,
            },
            {
              transform: `translate3d(${
                (i / len) * 580
              }px,80vh,0) scale(${scale}) rotate(${Math.random() > 0.5 ? "" : "-"}2turn)`,
              opacity: 1,
            },
          ],
          {
            duration: Math.random() * 2000 + 5000,
            iterations: Infinity,
            delay: -(Math.random() * 7000),
          }
        );
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.confetti {
  width: 1rem;
  height: 2rem;
  display: inline-block;
  position: absolute;
  top: -1rem;
  left: 0;
  z-index: 1051;
  background-image: url("@/assets/images/dollar.png");
  background-size: 100% 100%;

  .rotate {
    animation: driftyRotate 1s infinite both ease-in-out;
    perspective: 1rem;
  }

  .askew {
    background-image: url("@/assets/images/dollar.png");
    background-size: 100% 100%;
    transform: skewY(10deg);
    width: 1rem;
    height: 2.5rem;
    animation: drifty 1s infinite alternate both ease-in-out;
    perspective: 1rem;
  }
}

.confetti:nth-of-type(7n) .askew {
  animation-delay: -0.6s;
  animation-duration: 2.25s;
}
.confetti:nth-of-type(7n + 1) .askew {
  animation-delay: -0.879s;
  animation-duration: 3.5s;
}
.confetti:nth-of-type(7n + 2) .askew {
  animation-delay: -0.11s;
  animation-duration: 1.95s;
}
.confetti:nth-of-type(7n + 3) .askew {
  animation-delay: -0.246s;
  animation-duration: 0.85s;
}
.confetti:nth-of-type(7n + 4) .askew {
  animation-delay: -0.43s;
  animation-duration: 2.5s;
}
.confetti:nth-of-type(7n + 5) .askew {
  animation-delay: -0.56s;
  animation-duration: 1.75s;
}
.confetti:nth-of-type(7n + 6) .askew {
  animation-delay: -0.76s;
  animation-duration: 1.5s;
}

.confetti:nth-of-type(9n) .rotate {
  animation-duration: 2s;
}
.confetti:nth-of-type(9n + 1) .rotate {
  animation-duration: 2.3s;
}
.confetti:nth-of-type(9n + 2) .rotate {
  animation-duration: 1.1s;
}
.confetti:nth-of-type(9n + 3) .rotate {
  animation-duration: 0.75s;
}
.confetti:nth-of-type(9n + 4) .rotate {
  animation-duration: 4.3s;
}
.confetti:nth-of-type(9n + 5) .rotate {
  animation-duration: 3.05s;
}
.confetti:nth-of-type(9n + 6) .rotate {
  animation-duration: 2.76s;
}
.confetti:nth-of-type(9n + 7) .rotate {
  animation-duration: 7.6s;
}
.confetti:nth-of-type(9n + 8) .rotate {
  animation-duration: 1.78s;
}

@keyframes drifty {
  0% {
    transform: skewY(10deg) translate3d(-250%, 0, 0);
  }
  100% {
    transform: skewY(-12deg) translate3d(250%, 0, 0);
  }
}
@keyframes driftyRotate {
  0% {
    transform: rotateX(0);
  }
  100% {
    transform: rotateX(359deg);
  }
}
.confetti-land {
  max-height: 80vh;
  max-width: 280px;
  overflow: hidden;
}
$particles: 50;
$width: 500;
$height: 500;

$box-shadow: ();
$box-shadow2: ();
@for $i from 0 through $particles {
  $box-shadow: $box-shadow,
    random($width)-$width / 2 + px
    random($height)-$height / 1.2 + px
    hsl(random(360), 100, 50);
  $box-shadow2: $box-shadow2, 0 0 #fff
}
@mixin keyframes ($animationName) {
    @-webkit-keyframes #{$animationName} {
        @content;
    }

    @-moz-keyframes #{$animationName} {
        @content;
    }

    @-o-keyframes #{$animationName} {
        @content;
    }

    @-ms-keyframes #{$animationName} {
        @content;
    }

    @keyframes #{$animationName} {
        @content;
    }
}

@mixin animation-delay ($settings) {
    -moz-animation-delay: $settings;
    -webkit-animation-delay: $settings;
    -o-animation-delay: $settings;
    -ms-animation-delay: $settings;
    animation-delay: $settings;
}

@mixin animation-duration ($settings) {
    -moz-animation-duration: $settings;
    -webkit-animation-duration: $settings;
    -o-animation-duration: $settings;
    -ms-animation-duration: $settings;
    animation-duration: $settings;
}

@mixin animation ($settings) {
    -moz-animation: $settings;
    -webkit-animation: $settings;
    -o-animation: $settings;
    -ms-animation: $settings;
    animation: $settings;
}

@mixin transform ($settings) {
    transform: $settings;
    -moz-transform: $settings;
    -webkit-transform: $settings;
    -o-transform: $settings;
    -ms-transform: $settings;
}


.pyro > .before,
.pyro > .after {
  position: absolute;
  top: 0;
  left: 0;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  box-shadow: $box-shadow2;
  z-index: 1055;
  @include animation((1s bang ease-out infinite backwards, 1s gravity ease-in infinite backwards, 5s position linear infinite backwards));
}

.pyro > .after {
  @include animation-delay((1.25s, 1.25s, 1.25s));
  @include animation-duration((1.25s, 1.25s, 6.25s));
}

@include keyframes(bang) {
  to {
    box-shadow:$box-shadow;
  }
}

@include keyframes(gravity)  {
  to {
    @include transform(translateY(200px));
    opacity: 0;
  }
}

@include keyframes(position) {
  0%, 19.9% {
    margin-top: 10%;
    margin-left: 40%;
  }
  20%, 39.9% {
    margin-top: 40%;
    margin-left: 30%;
  }
  40%, 59.9% {
    margin-top: 20%;
    margin-left: 70%
  }
  60%, 79.9% {
    margin-top: 30%;
    margin-left: 20%;
  }
  80%, 99.9% {
    margin-top: 30%;
    margin-left: 80%;
  }
}
</style>
