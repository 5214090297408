<template>
  <div class="card">
    <div class="card-body">
      <div class="pe-3">
        <IconSpeakerphone></IconSpeakerphone>
      </div>
      <marquee behavior="" direction="" scrolldelay="100">{{
        $t("welcome_content")
      }}</marquee>
    </div>
  </div>
</template>
<script>
import { IconSpeakerphone } from "@tabler/icons-vue";

export default {
  components: { IconSpeakerphone },
};
</script>
<style lang="scss" scoped>
.card {
  border: 0;
  overflow: hidden;
  background: rgba($color: #21262a, $alpha: 0.5);
}

.card-body {
  display: flex;
  border-radius: 8px;
}
</style>
