<template>
  <div class="px-3">
    <div class="card account-card">
      <div class="d-flex justify-content-between">
        <div class="d-flex">
          <div class="d-flex justify-content-center align-items-center">
            <div class="avatar">
              <img src="@/assets/logo.png" alt="" />
            </div>
          </div>
          <div class="ms-3">
            <div>{{ user?.name }}</div>
            <div>Uid: {{ user?.uuid }}</div>
            <!-- <div>{{ $t("placeholder.invite_code") }}: {{ user?.invite_id }}</div> -->
          </div>
        </div>
        <div class="ranking">
          <img :src="user?.levelDetails?.image" alt="" />
        </div>
      </div>
      <div class="mt-4 d-flex justify-content-between flex-wrap">
        <div>
          <p class="mb-0">{{ $t("balance") }}:</p>
          <div class="fs-4 fw-bold text-nowrap">$ {{ user?.balance }}</div>
        </div>
        <div class="d-flex flex-column justify-content-center">
          <p class="mb-1 text-nowrap">
            {{ $t("available_balance") }}:
            <span class="fw-bold text-primary">$ {{ user?.balance }}</span>
          </p>
          <p class="mb-0 text-nowrap">
            {{ $t("freeze_balance") }}:
            <span class="fw-bold text-danger">$ {{ user?.freeze_balance }}</span>
          </p>
        </div>
      </div>
    </div>

    <div class="account-menu mt-3">
      <router-link :to="{ name: 'withdraw' }" class="menu-item">
        <div><IconWallet class="me-1" />{{ $t("withdraw") }}</div>
        <div>
          <IconChevronRight />
        </div>
      </router-link>
      <router-link :to="{ name: 'reports' }" class="menu-item">
        <div><IconFileAnalytics class="me-1" /> {{ $t("team_report") }}</div>
        <div>
          <IconChevronRight />
        </div>
      </router-link>
      <router-link :to="{ name: 'myBankAccount' }" class="menu-item">
        <div><IconUser class="me-1" />{{ $t("menu.account") }}</div>
        <div>
          <IconChevronRight />
        </div>
      </router-link>
      <router-link :to="{ name: 'deposit.history' }" class="menu-item">
        <div><IconHistoryToggle class="me-1" /> {{ $t("history.recharge") }}</div>
        <div>
          <IconChevronRight />
        </div>
      </router-link>
      <router-link :to="{ name: 'withdraw.history' }" class="menu-item">
        <div><IconHistory class="me-1" /> {{ $t("history.withdraw") }}</div>
        <div>
          <IconChevronRight />
        </div>
      </router-link>
      <router-link
        :to="{ name: 'record', query: { status: constants.ORDER_STATUS.SUCCESS } }"
        class="menu-item"
      >
        <div><IconReceipt2 class="me-1" /> {{ $t("order.order_details") }}</div>
        <div>
          <IconChevronRight />
        </div>
      </router-link>
      <router-link :to="{ name: 'bankAccount' }" class="menu-item">
        <div><IconCreditCard class="me-1" /> {{ $t("bank_account") }}</div>
        <div>
          <IconChevronRight />
        </div>
      </router-link>
      <router-link class="menu-item" :to="{ name: 'profile' }">
        <div><IconInfoCircle class="me-1" /> {{ $t("update_info") }}</div>
        <div>
          <IconChevronRight />
        </div>
      </router-link>
      <router-link :to="{ name: 'updateWithdrawPassword' }" class="menu-item">
        <div>
          <IconBrandSamsungpass class="me-1" /> {{ $t("password_withdraw_change") }}
        </div>
        <div>
          <IconChevronRight />
        </div>
      </router-link>
      <router-link :to="{ name: 'updatePassword' }" class="menu-item">
        <div><IconKey class="me-1" /> {{ $t("password_change") }}</div>
        <div>
          <IconChevronRight />
        </div>
      </router-link>
      <div class="menu-item flex-wrap">
        <div><IconLanguage class="me-1" /> {{ $t("language") }}</div>
        <div class="d-flex justify-content-start">
          <div class="d-flex me-5" @click="setLocale('en-US')">
            <div class="overflow-hidden">
              <img class="flag-icon" src="@/assets/images/flags/us.svg" />
            </div>
            <div class="d-blox">English</div>
          </div>
          <div class="d-flex" @click="setLocale('vi-VN')">
            <div class="overflow-hidden">
              <img class="flag-icon" src="@/assets/images/flags/vn.svg" />
            </div>
            <div class="d-blox">Tiếng Việt</div>
          </div>
        </div>
      </div>

      <div class="menu-item" @click="showLogoutModal">
        <div><IconLogout class="me-1" /> {{ $t("logout") }}</div>
        <div>
          <IconChevronRight />
        </div>
      </div>
    </div>
  </div>
  <ModalComponent
    :modalId="modalId"
    :custom-class="{ 'modal-black': true }"
    :custom-class-dialog="['modal-dialog-centered']"
  >
    <template v-slot:modal-header>
      <div class="modal-header">
        <h5 class="text-center w-100">{{ $t("notification") }}</h5>
      </div>
    </template>
    <template v-slot:modal-body>
      <h5 class="text-center">{{ $t("logout_message") }}</h5>
    </template>
    <template v-slot:modal-footer>
      <div class="modal-footer justify-content-center">
        <button type="button" class="btn btn-system bg-secondary" data-bs-dismiss="modal">
          {{ $t("close") }}
        </button>
        <button
          type="button"
          @click="handleLogout"
          class="btn btn-system px-5"
          data-bs-dismiss="modal"
        >
          {{ $t("yes") }}
        </button>
      </div>
    </template>
  </ModalComponent>
</template>
<script>
import ModalComponent from "@/components/ModalComponent.vue";
import constants from "@/constants";
import emitter from "@/mitt";
import { IconFileAnalytics } from "@tabler/icons-vue";
import { IconHistoryToggle } from "@tabler/icons-vue";
import { IconReceipt2 } from "@tabler/icons-vue";
import { IconInfoCircle } from "@tabler/icons-vue";
import { IconKey } from "@tabler/icons-vue";
import { IconLogout } from "@tabler/icons-vue";
import { IconLanguage } from "@tabler/icons-vue";
import { IconBrandSamsungpass } from "@tabler/icons-vue";
import { IconCreditCard } from "@tabler/icons-vue";
import { IconHistory } from "@tabler/icons-vue";
import { IconUser } from "@tabler/icons-vue";
import { IconChevronRight } from "@tabler/icons-vue";
import { IconWallet } from "@tabler/icons-vue";
import { mapActions, mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters(["getUserData"]),
    user() {
      const user = this.getUserData;
      if (user) {
        user.levelDetails = constants.LEVELS.getLevelInfo(user?.level?.id || 0);
      }
      return user;
    },
  },
  setup() {
    const defaultLang = localStorage.getItem("country");
    const modalId = "Logout";
    return {
      defaultLang,
      constants,
      modalId,
    };
  },
  data() {
    return {
      lang: this.defaultLang,
    };
  },
  methods: {
    ...mapActions(["logout"]),
    handleLogout() {
      this.logout();
    },
    setLocale(locale) {
      localStorage.setItem("country", locale);
      this.$i18n.locale = locale;
    },
    showLogoutModal() {
      emitter.emit(`show-modal${this.modalId}`);
    },
  },
  components: {
    IconWallet,
    IconChevronRight,
    IconFileAnalytics,
    IconUser,
    IconHistoryToggle,
    IconHistory,
    IconReceipt2,
    IconCreditCard,
    IconInfoCircle,
    IconBrandSamsungpass,
    IconKey,
    IconLanguage,
    IconLogout,
    ModalComponent,
  },
};
</script>
<style lang="scss" scoped>
.avatar {
  width: 50px;
  height: 50px;
  overflow: hidden;
  border-radius: 50%;
  border: 2px solid white;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
}

.ranking {
  width: 55px;

  img {
    max-width: 100%;
  }
}

.account-menu {
  background-color: #36424f;
  border-radius: 24px;
}

.menu-item {
  display: flex;
  justify-content: space-between;
  padding: 8px 16px;
  border-bottom: 1px solid #cecece61;
  font-size: 18px;
  cursor: pointer;
  text-decoration: none;
  color: white;

  &:last-child {
    border: 0;
  }

  svg {
    width: 32px;
    height: 32px;
    color: #46b4e9;
  }
}
</style>
