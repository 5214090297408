<template>
  <div class="d-flex flex-column">
    <div class="d-flex justify-content-center fs-4 position-relative py-2">
      <button @click="$router.go(-1)" class="btn text-white btn-back">
        <IconChevronLeft />
      </button>
      <div>{{ $t("menu.bank_account") }}</div>
    </div>
  </div>
  <div class="px-5">
    <div class="my-3 input-with-icon">
      <label for="name" class="form-label"><IconUserEdit :size="32" /></label>
      <input
        id="name"
        type="text"
        class="form-control"
        v-model="accountName"
        :placeholder="$t('placeholder.account_name')"
      />
    </div>
    <div class="my-3 input-with-icon">
      <label for="accountNumber" class="form-label"><IconCreditCard :size="32" /></label>
      <input
        id="accountNumber"
        type="text"
        class="form-control"
        v-model="accountNumber"
        :placeholder="$t('placeholder.account_number')"
      />
    </div>
    <!-- <div class="my-3 input-with-icon">
      <label for="bankName" class="form-label"><IconBuildingBank :size="32" /></label>
      <input
        id="bankName"
        type="text"
        class="form-control"
        v-model="bankName"
        placeholder="Tên ngân hàng"
      />
    </div> -->
    <div class="my-3 input-with-icon">
      <label for="bankName" class="form-label"><IconBuildingBank :size="32" /></label>
      <VSelect
        :options="bankList"
        v-model="bankName"
        label="shortName"
        :placeholder="$t('placeholder.bank_name')"
        :reduce="(b) => b.shortName"
      >
        <template #option="{ shortName, name, logo }">
          <div>
            <img :src="logo" alt="logo" class="bank-logo" />
            {{ shortName }}|{{ name }}
          </div>
        </template>
      </VSelect>
    </div>
    <div class="my-3 input-with-icon">
      <label for="addtionalInfo" class="form-label">
        <IconMapPin :size="32" />
      </label>
      <input
        id="bankBranch"
        type="text"
        class="form-control"
        v-model="bankBranch"
        :placeholder="$t('placeholder.branch')"
      />
    </div>
    <div class="mt-5 pb-5">
      <button class="btn btn-system w-100" @click="handleCreateBankAccount">
        {{ $t("submit") }}
      </button>
    </div>
    <div class="pb-5">
      <p class="mb-1 fw-bold">{{ $t("advice") }}:</p>
      <small class="text-secondary">
        {{ $t("advice_content") }}
      </small>
    </div>
  </div>
</template>
<script>
import { IconCreditCard } from "@tabler/icons-vue";
import { IconMapPin } from "@tabler/icons-vue";
import { IconBuildingBank } from "@tabler/icons-vue";
import { IconUserEdit } from "@tabler/icons-vue";
import { IconChevronLeft } from "@tabler/icons-vue";
import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    IconChevronLeft,
    IconUserEdit,
    IconCreditCard,
    IconBuildingBank,
    IconMapPin,
  },
  computed: {
    ...mapGetters([
      "getUserData",
      "getUpdatedData",
      "getDataDankAccount",
      "getDataBankList",
    ]),
    user() {
      return this.getUserData;
    },
    updatedData() {
      return this.getUpdatedData;
    },
    bank() {
      return this.getDataDankAccount;
    },
    bankList() {
      return this.getDataBankList;
    },
  },
  data() {
    return {
      accountName: "",
      accountNumber: "",
      bankName: "",
      bankBranch: "",
    };
  },
  beforeMount() {
    this.getBankList();
    this.getBankAccount();
  },
  methods: {
    ...mapActions([
      "getBankAccount",
      "createBankAccount",
      "updateBankAccount",
      "getBankList",
    ]),
    handleCreateBankAccount() {
      if (!this.bank?.id) {
        this.createBankAccount({
          account_name: this.accountName,
          account_number: this.accountNumber,
          bank_name: this.bankName,
          bank_branch: this.bankBranch,
        });
      } else {
        this.updateBankAccount({
          id: this.bank.id,
          account_name: this.accountName,
          account_number: this.accountNumber,
          bank_name: this.bankName,
          bank_branch: this.bankBranch,
        });
      }
    },
  },
  watch: {
    updatedData() {
      this.$router.push({ name: "account" });
    },
    bank(newVal) {
      if (newVal) {
        this.accountName = newVal.account_name;
        this.accountNumber = newVal.account_number;
        this.bankName = newVal.bank_name;
        this.bankBranch = newVal.bank_branch;
      }
    },
  },
};
</script>
<style lang="scss" scoped></style>
