<template>
  <div class="d-flex flex-column">
    <div class="d-flex justify-content-center fs-4 position-relative py-2">
      <button @click="$router.go(-1)" class="btn text-white btn-back">
        <IconChevronLeft />
      </button>
      <div>{{ $t("team_report") }}</div>
    </div>
  </div>
  <div class="px-3 pb-5">
    <div>
      <div class="mb-3">
        <div class="custom-radio mb-3">
          <input
            type="radio"
            class="custom-input"
            v-model="level"
            name="level"
            id="levelAll"
            :value="0"
          />
          <label class="custom-babel" for="levelAll">{{ $t("report.all_levels") }}</label>
        </div>
        <div class="d-flex justify-content-around ps-5 flex-wrap">
          <div class="custom-radio">
            <input
              type="radio"
              class="custom-input"
              v-model="level"
              name="level"
              id="level1"
              :value="1"
            />
            <label class="custom-babel" for="level1">{{ $t("report.level") }} 1</label>
          </div>
          <div class="custom-radio">
            <input
              type="radio"
              class="custom-input"
              v-model="level"
              name="level"
              id="level2"
              :value="2"
            />
            <label class="custom-babel" for="level2">{{ $t("report.level") }} 2</label>
          </div>
          <div class="custom-radio">
            <input
              type="radio"
              class="custom-input"
              v-model="level"
              name="level"
              id="level3"
              :value="3"
            />
            <label class="custom-babel" for="level3">{{ $t("report.level") }} 3</label>
          </div>
          <div class="custom-radio">
            <input
              type="radio"
              class="custom-input"
              v-model="level"
              name="level"
              id="level4"
              :value="4"
            />
            <label class="custom-babel" for="level4">{{ $t("report.level") }} 4</label>
          </div>
        </div>
      </div>

      <div class="mb-3">
        <div class="custom-radio mb-3">
          <input
            type="radio"
            v-model="date"
            :value="0"
            class="custom-input"
            name="date"
            id="dateAll"
          />
          <label class="custom-babel" for="dateAll">{{ $t("report.all_levels") }}</label>
        </div>
        <div class="d-flex justify-content-around ps-5 flex-wrap">
          <div class="custom-radio">
            <input
              type="radio"
              v-model="date"
              :value="1"
              class="custom-input"
              name="date"
              id="date1"
            />
            <label class="custom-babel" for="date1">{{ $t("report.yesterday") }}</label>
          </div>
          <div class="custom-radio">
            <input
              type="radio"
              v-model="date"
              :value="2"
              class="custom-input"
              name="date"
              id="date2"
            />
            <label class="custom-babel" for="date2">{{ $t("report.today") }}</label>
          </div>
          <div class="custom-radio">
            <input
              type="radio"
              v-model="date"
              :value="3"
              class="custom-input"
              name="date"
              id="date3"
            />
            <label class="custom-babel" for="date3">{{ $t("report.this_week") }}</label>
          </div>
        </div>
      </div>
    </div>
    <div class="card bg-secondary mb-3" v-if="reports?.users?.length">
      <div class="card-body">
        <div class="d-flex justify-content-between">
          <span>{{ $t("total_revenue") }}:</span>
          <span class="fw-bold">$ {{ Number(user?.total_profit || 0).toFixed(2) }}</span>
        </div>
        <div class="d-flex justify-content-between">
          <span>{{ $t("team_size") }}:</span>
          <span class="fw-bold">{{ reports?.users?.length || 0 }}</span>
        </div>
        <div class="d-flex justify-content-between">
          <span>{{ $t("total_commission") }}:</span>
          <span class="fw-bold">
            $ {{ Number(reports?.total_profit || 0).toFixed(2) }}
          </span>
        </div>
      </div>
    </div>

    <template v-if="reports?.users?.length">
      <template v-for="(user, index) in reports?.users" :key="'user' + index">
        <div class="card bg-secondary my-1">
          <div class="card-body py-1 px-3">
            <div class="card-item d-flex align-items-center">
              <div class="avatar-small me-3">
                <img class="rounded-circle" src="@/assets/logo.png" alt="" />
              </div>
              <div>
                <div>
                  <small>{{ $t("placeholder.account_name") }}: {{ user.name }}</small>
                </div>
                <div>
                  <small class="text-custom"
                    >{{ $t("auth.phone_number") }}:
                    {{ hideMiddleContent(user.phone) }}</small
                  >
                </div>
                <div>
                  <small class="text-custom">
                    {{ $t("date") }}:
                    {{ moment(user.created_at).format("yyyy/MM/DD HH:mm:ss") }}</small
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
    </template>
    <template v-else>
      <div class="card bg-secondary">
        <div class="card-body p-3">
          <div class="card-item d-flex align-items-center justify-content-center">
            <h5 class="mb-0">{{ $t("no_data") }}</h5>
          </div>
        </div>
      </div>
    </template>
  </div>
  <ModalComponent :modalId="modalId" :custom-class="{ 'modal-black': true }">
    <template v-slot:modal-header>
      <div class="modal-header">
        <h5 class="text-center w-100">{{ $t("order.title") }}</h5>
      </div>
    </template>
    <template v-slot:modal-body>
      <h1 class="text-center">{{ $t("coming_soon") }}</h1>
    </template>
    <template v-slot:modal-footer>
      <div class="modal-footer justify-content-center">
        <button
          @click="$router.go(-1)"
          class="btn btn-secondary rounded-pill me-3"
          data-bs-dismiss="modal"
        >
          {{ $t("cancel") }}
        </button>
      </div>
    </template>
  </ModalComponent>
</template>
<script>
import ModalComponent from "@/components/ModalComponent.vue";
import emitter from "@/mitt";
import { IconChevronLeft } from "@tabler/icons-vue";
import moment from "moment";
import { mapActions, mapGetters } from "vuex";

export default {
  components: { IconChevronLeft, ModalComponent },
  setup() {
    const modalId = "report";
    return {
      moment,
      modalId,
    };
  },
  computed: {
    ...mapGetters(["getDataReports", "getUserData"]),
    reports() {
      return this.getDataReports;
    },
    user() {
      return this.getUserData;
    },
  },
  beforeMount() {
    // this.getReports();
  },
  data() {
    return {
      level: 0,
      date: 0,
    };
  },
  mounted() {
    emitter.emit(`show-modal${this.modalId}`);
  },
  methods: {
    ...mapActions(["getReports"]),
    hideMiddleContent(str, startChars = 3, endChars = 2) {
      if (!str || str.length <= startChars + endChars) {
        return str;
      }

      const hiddenLength = str.length - (startChars + endChars);
      const hiddenContent = "*".repeat(hiddenLength);

      const startContent = str.substring(0, startChars);
      const endContent = str.substring(str.length - endChars);

      return startContent + hiddenContent + endContent;
    },
    getDateStartEnd(dateType) {
      const data = {};
      if (dateType == 1) {
        data.start_date = moment().format("YYYY-MM-DD");
        data.end_date = moment().format("YYYY-MM-DD");
      } else if (dateType == 2) {
        data.start_date = moment().subtract(1, "day").format("YYYY-MM-DD");
        data.end_date = moment().subtract(1, "day").format("YYYY-MM-DD");
      } else if (dateType == 3) {
        data.start_date = moment().startOf("week").format("YYYY-MM-DD");
        data.end_date = moment().endOf("week").format("YYYY-MM-DD");
      }
      return data;
    },
  },
  watch: {
    level(newLevel) {
      const data = {
        ...this.getDateStartEnd(this.date),
      };
      if (newLevel) {
        data.level_id = newLevel;
      }
      this.getReports(data);
    },
    date(newDate) {
      const data = {
        ...this.getDateStartEnd(newDate),
      };

      if (this.level) {
        data.level_id = this.level;
      }
      this.getReports(data);
    },
  },
};
</script>
<style lang="scss" scoped>
.text-custom {
  color: #323232;
}
</style>
