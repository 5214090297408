<template>
  <div class="d-flex justify-content-center">
    <div class="card me-2">
      <div class="card-body">
        <p class="text-primary text-center">{{ $t("commission_today") }}</p>
        <h3 class="mb-0 text-center fw-bold">$ {{ user?.today_total_profit }}</h3>
      </div>
    </div>
    <div class="card">
      <div class="card-body">
        <p class="text-primary text-center">{{ $t("total_revenue") }}</p>
        <h3 class="mb-0 text-center fw-bold">$ {{ user?.total_profit }}</h3>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters(["getUserData"]),
    user() {
      return this.getUserData;
    },
  },
};
</script>
<style lang="scss" scoped>
.card {
  background: #3d4a56;
  flex-grow: 1;
}
</style>
