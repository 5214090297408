<template>
  <div>
    <h1>DASHBOARD</h1>
  </div>
</template>
<script>
export default {
  computed: {},

  data() {
    return {};
  },
  methods: {},
};
</script>
<style lang="scss" scoped></style>
