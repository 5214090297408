<template>
  <div class="d-flex flex-column">
    <div class="d-flex justify-content-center fs-4 position-relative py-2">
      <button @click="$router.go(-1)" class="btn text-white btn-back">
        <IconChevronLeft />
      </button>
      <div>{{ $t("instruct") }}</div>
    </div>
  </div>
  <div class="card bg-transparent">
    <div class="card-body text-black">
      <div class="alert alert-success text-center" role="alert">
        <span class="fw-bold">{{ $t("advice") }}! </span>
        {{ $t("recharge_page.note") }}
        <br />
        <span class="fw-bold">{{ user.uuid }}</span>
        <br />
        {{ $t("recharge_page.note1") }}
      </div>
      <div class="bg-light p-3 rounded">
        <div>
          {{ $t("placeholder.bank_name") }}:
          <span class="fw-bold">{{ BANK_INFO.bank_name }}</span>
        </div>
        <div>
          {{ $t("placeholder.account_number") }}:
          <span class="fw-bold">{{ BANK_INFO.account_number }}</span>
        </div>
        <div>
          {{ $t("placeholder.receiver") }}:
          <span class="fw-bold">{{ BANK_INFO.name }}</span>
        </div>
      </div>
      <div class="text-danger fw-bold my-3">
        {{ $t("recharge_page.note2") }}
      </div>
      <div class="bg-light p-2 rounded text-center py-4">
        <span class="fs-4 fw-bold text-success border-4 border-dashed border-success p-2">
          {{ user.uuid }}
        </span>
      </div>
      <div class="text-primary fw-bold text-center mt-4">
        {{ $t("recharge_page.note3") }}
      </div>
      <div class="d-flex justify-content-center mt-4">
        <img src="@/assets/images/qr_image.jpg" alt="qr" />
      </div>
      <div class="my-5">
        <router-link :to="{ name: 'deposit.history' }" class="btn btn-system w-100">
          {{ $t("submit") }}
        </router-link>
      </div>
    </div>
  </div>
</template>
<script>
import { BANK_INFO } from "@/constants";
import { IconChevronLeft } from "@tabler/icons-vue";
import { mapGetters } from "vuex";

export default {
  components: { IconChevronLeft },
  setup() {
    return {
      BANK_INFO,
    };
  },
  computed: {
    ...mapGetters(["getUserData"]),
    user() {
      return this.getUserData;
    },
  },
};
</script>
<style lang="scss" scoped>
.border-dashed {
  border: 4px dashed;
}
</style>
