<template>
  <div class="px-3">
    <div class="card" :style="{ backgroundColor: user.levelDetails.bg_color }">
      <div class="card-body">
        <div class="d-flex justify-content-between mb-4">
          <div class="ranking-img">
            <img :src="user.levelDetails.image" alt="" />
          </div>
          <div>
            <p class="mb-1">{{ $t("menu.account") }}:</p>
            <h3 class="fw-bold mb-1">$ {{ user?.balance }}</h3>
            <p class="mb-0">{{ $t(`levels.${user?.level?.id || 0}`) }}</p>
            <p class="mb-0">
              {{ $t("commission") }} {{ levelProfit }}% |
              {{ user?.level?.max_order || user?.max_free_order }} {{ $t("push") }}
            </p>
          </div>
          <div>
            <div
              class="fw-bold d-flex fs-3 justify-content-center align-items-center text-nowrap"
            >
              <span
                class="current-number me-2"
                :style="{ color: user.levelDetails.bg_color }"
              >
                {{ user.orders_count || 0 }}
              </span>
              <span> / {{ user?.level?.max_order || user?.max_free_order }}</span>
            </div>
          </div>
        </div>
        <div class="d-flex justify-content-center">
          <Swiper
            :effect="'cards'"
            :grabCursor="true"
            :modules="modules"
            :loop="true"
            @slide-change="handleSlideChange"
            :autoplay="{
              delay: 2400,
              disableOnInteraction: false,
            }"
          >
            <template v-for="(product, index) in products" :key="index">
              <SwiperSlide class="card-swiper">
                <img :src="assetUrl + product.img" alt="product image" />
                <!-- <div class="fs-4 mb-3 fw-bold mt-2">{{ product.price }}</div> -->
                <div class="product-description mt-3">
                  <span class="fw-bold">
                    {{ product.name }}
                  </span>
                  {{ product.description }}
                </div>
              </SwiperSlide>
            </template>
          </Swiper>
        </div>
      </div>
    </div>
    <div class="order-detail-container">
      <div class="d-flex justify-content-between order-detail">
        <div>{{ $t("order.get_commissions") }}</div>
        <div class="fw-bold fs-5">$ {{ user.total_profit }}</div>
      </div>
      <div class="d-flex justify-content-between order-detail">
        <div>{{ $t("order.holding") }}</div>
        <div class="fw-bold fs-5 text-danger">
          $ {{ Number(user.freeze_balance).toFixed(2) }}
        </div>
      </div>
      <div class="d-flex justify-content-between order-detail">
        <div>{{ $t("order.quantity") }}</div>
        <div class="fw-bold fs-5">{{ user.orders_count || 0 }}</div>
      </div>
      <div class="d-flex justify-content-between order-detail">
        <div>{{ $t("order.available") }}</div>
        <div class="fw-bold fs-5">$ {{ user.balance }}</div>
      </div>
    </div>
    <div class="d-flex justify-content-center my-4" v-if="products?.length">
      <button
        class="btn btn-system w-75 text-uppercase"
        @click="Number(user?.freeze_balance) ? showAlertMessage() : takeOrder()"
      >
        {{ $t("order.start") }}
      </button>
    </div>
  </div>
  <ModalComponent :modalId="modalId" :custom-class="{ 'modal-black': true }">
    <template v-slot:modal-header>
      <div class="modal-header">
        <h5 class="text-center w-100">{{ $t("order.title") }}</h5>
      </div>
    </template>
    <template v-slot:modal-body v-if="createdNewOrder">
      <table class="mb-3">
        <tr>
          <td class="pe-3">{{ $t("date") }}:</td>
          <td>{{ createdNewOrder.created_at }}</td>
        </tr>
        <tr>
          <td class="pe-3">{{ $t("order.number") }}:</td>
          <td>{{ createdNewOrder?.code }}</td>
        </tr>
      </table>
      <div class="d-flex align-items-center">
        <div class="product-image me-3">
          <img :src="assetUrl + createdNewOrder?.product_original?.img" alt="" />
        </div>
        <div>
          <span class="fw-bold">
            {{ createdNewOrder?.product_original?.name }}
          </span>
          <br />
          {{ createdNewOrder?.product_original?.description }}
        </div>
      </div>
      <div class="d-flex justify-content-between w-100 mt-3">
        <div>{{ $t("product.price") }}:</div>
        <div>$ {{ createdNewOrder?.product_original?.price }}</div>
      </div>
      <div class="d-flex justify-content-between w-100">
        <div>{{ $t("order.get_commissions") }}:</div>
        <div>$ {{ createdNewOrder.profit_amount }}</div>
      </div>
    </template>
    <template v-slot:modal-footer>
      <div class="modal-footer justify-content-center">
        <button
          @click="handleUpdateOrder(true)"
          type="button"
          class="btn btn-secondary rounded-pill me-3"
        >
          {{ $t("cancel") }}
        </button>
        <button type="button" class="btn btn-system" @click="handleUpdateOrder()">
          {{ $t("order.submit") }}
        </button>
      </div>
    </template>
  </ModalComponent>
  <Loading
    :active="loading"
    :z-index="9999"
    :can-cancel="false"
    :is-full-page="true"
    :background-color="'#646464'"
    :color="'#FF4B12'"
  >
    <template v-slot:default>
      <div class="d-flex flex-column align-items-center justify-content-center">
        <div>
          <div
            class="spinner-grow me-3"
            style="width: 1rem; height: 1rem; background-color: #ff4b12"
            role="status"
          ></div>
          <div
            class="spinner-grow me-3"
            style="width: 1rem; height: 1rem; background-color: #ff4b12"
            role="status"
          ></div>
          <div
            class="spinner-grow"
            style="width: 1rem; height: 1rem; background-color: #ff4b12"
            role="status"
          ></div>
        </div>
        <div class="mt-2 fs-5" v-if="orderProcess == 0">
          {{ $t("order.processing") }}...
        </div>
        <div class="mt-2 fs-5 text-warning" v-else-if="orderProcess == 1">
          {{ $t("order.connecting") }}...
        </div>
        <div class="mt-2 fs-5 text-danger" v-else-if="orderProcess == 3">
          {{ $t("order.order_failed") }}...
        </div>
        <div
          class="mt-2 fw-bold fs-5 text-success bg-light px-3 py-1 rounded-pill"
          v-else
        >
          {{ $t("order.order_success") }}
        </div>
      </div>
    </template>
  </Loading>
  <ConfettiComponent v-if="showConfetti" />
</template>
<script>
import { Swiper, SwiperSlide } from "swiper/vue";
import { EffectCards, Autoplay } from "swiper";
import "swiper/css/effect-cards";
import { mapActions, mapGetters } from "vuex";
import constants, { getLevelProfitPercent } from "@/constants";
import ModalComponent from "@/components/ModalComponent.vue";
import emitter from "@/mitt";
import moment from "moment";
import Loading from "vue3-loading-overlay";
import { useToast, POSITION } from "vue-toastification";
import ToastContainerComponent from "@/components/Toast/ToastContainerComponent";
import ConfettiComponent from "@/components/ConfettiComponent.vue";

export default {
  components: {
    Swiper,
    SwiperSlide,
    ModalComponent,
    Loading,
    ConfettiComponent,
  },
  setup() {
    const modalId = "modalOrder";
    const toast = useToast();
    const audio = new Audio(require("@/assets/sounds/success.mp3"));

    return {
      modules: [EffectCards, Autoplay],
      modalId,
      moment,
      toast,
      audio,
    };
  },
  mounted() {
    this.getAddedProducts();
    const modalOrder = document.getElementById("modalmodalOrder");
    modalOrder.addEventListener("hidden.bs.modal", () => {
      this.setNullOrderPrize();
      this.showConfetti = false;
    });
  },
  computed: {
    ...mapGetters([
      "getUserData",
      "getDataProducts",
      "getCreatedOrderData",
      "getUpdatedData",
    ]),
    user() {
      const user = this.getUserData;
      user.levelDetails = constants.LEVELS.getLevelInfo(user.level?.id || 0);
      return user;
    },
    products() {
      return this.getDataProducts;
    },
    createdNewOrder() {
      return this.getCreatedOrderData;
    },
    updatedData() {
      return this.getUpdatedData;
    },
    errors() {
      return this.$store.state.errors;
    },
    levelProfit() {
      return getLevelProfitPercent(this.user?.level);
    }
  },

  data() {
    return {
      takeProduct: null,
      bookedTime: null,
      orderId: null,
      currentIndex: 0,
      orderProcess: 0,
      loading: false,
      showConfetti: false,
    };
  },
  methods: {
    ...mapActions([
      "getAddedProducts",
      "createOrder",
      "getUserLogin",
      "submitOrder",
      "getOrderPrize",
      "setNullOrderPrize",
    ]),
    timeout(ms) {
      return new Promise((resolve) => setTimeout(resolve, ms));
    },
    async takeOrder() {
      if (
        (this.user?.orders_count || 0) >=
        (this.user?.level?.max_order || this.user?.max_free_order)
      ) {
        this.$toast.error(this.$t("order.limit_reached"), {
          position: "bottom",
        });
        return;
      }
      if (this.products?.length) {
        this.orderProcess = 0;
        this.loading = true;
        await this.timeout(1400);
        this.orderProcess = 1;
        await this.timeout(4000);
        await this.draftOrder();
        if (this.errors) {
          this.orderProcess = 3;
        } else {
          this.orderProcess = 2;
        }
        await this.timeout(1400);
        if (this.createdNewOrder?.created_by_admin) {
          this.showConfetti = true;
          this.toast.success(
            {
              component: ToastContainerComponent,
              props: {
                message: this.$t("prize"),
              },
            },
            {
              timeout: 7000,
              position: POSITION.TOP_CENTER,
              icon: false,
              toastClassName: "custom-toast-class",
            }
          );
        }
        if (!this.errors) {
          emitter.emit(`show-modal${this.modalId}`);
          this.audio.play();
        }
        this.loading = false;
      }
    },

    async draftOrder() {
      const productIndex = constants.randomIntFromInterval(0, this.products?.length - 1);
      this.takeProduct = this.products?.[productIndex];
      this.bookedTime = moment().format("Y/MM/DD HH:mm:ss");
      this.orderId = "OD" + constants.makeId(15).toLocaleUpperCase();
      await this.handleCreateOrder();
    },

    async handleCreateOrder() {
      const orderDetail = {
        product_id: this.takeProduct.id,
        code: this.orderId,
      };
      await this.createOrder(orderDetail);
    },

    async handleUpdateOrder(isCancelled = false) {
      if (this.createdNewOrder) {
        if (!isCancelled) {
          await this.submitOrder({
            id: this.createdNewOrder.id,
            status: constants.ORDER_STATUS.SUCCESS,
          });
          this.setNullOrderPrize();
        }
        emitter.emit(`close-modal${this.modalId}`);
      }
    },

    handleSlideChange(swiper) {
      const currentIndex = swiper.realIndex;
      this.currentIndex = currentIndex;
    },
    showAlertMessage() {
      this.$toast.error(
        this.$t("order.blocked", {
          freeze_amount: this.user?.freeze_balance,
        }),
        {
          position: "bottom",
        }
      );
    },
  },
  watch: {
    updatedData(newVal) {
      if (newVal) {
        this.getUserLogin();
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.bg-light {
  --bs-bg-opacity: 0.8;
  background-color: rgba(var(--bs-light-rgb), var(--bs-bg-opacity)) !important;
}
.ranking-img {
  img {
    max-width: 70px;
  }
}

.current-number {
  width: 1.5em;
  height: 1.5em;
  border-radius: 50%;
  background-color: white;
  display: block;
  display: flex;
  justify-content: center;
  align-items: center;
}

.card-swiper {
  background-color: white;
  color: #808080;
  max-width: 240px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 16px;
  border-radius: 16px;

  img {
    object-fit: contain;
    height: 100%;
    max-height: 170px;
    border-radius: 16px;
    min-height: 170px;
  }

  .product-description {
    max-width: 100%;
    background-color: #d4d4d4;
    padding: 2px 8px;
    white-space: nowrap;
    overflow: hidden;
    color: black;
    text-overflow: ellipsis;
    border-radius: 16px;
    font-size: 12px;
  }
}

.swiper {
  width: 240px;
}

.order-detail-container {
  padding: 0 16px;
  .order-detail {
    margin: auto;
    width: 90%;
    padding: 4px 16px;
    background-color: #3c4956;
    border-bottom: 1px solid #ddd;

    &:last-child {
      border: 0;
      border-bottom-left-radius: 16px;
      border-bottom-right-radius: 16px;
    }
  }
}

.product-image {
  max-width: 30%;

  img {
    border-radius: 16px;
  }
}
</style>
