<template>
  <div class="partner-container">
    <div class="partner" v-for="(partner, index) in partners" :key="index">
      <a :href="partner.url" target="_blank">
        <img :src="partner.image" :alt="partner.url">
      </a>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      partners: [
        {
          image: require('../../assets/images/partners/amazon.png'),
          url: 'https://www.amazon.com/'
        },
        {
          image: require('../../assets/images/partners/shopee.png'),
          url: 'https://shopee.vn/'
        },
        {
          image: require('../../assets/images/partners/lazada.png'),
          url: 'https://www.lazada.vn/'
        },
        {
          image: require('../../assets/images/partners/tiki.png'),
          url: 'https://tiki.vn/'
        },
        {
          image: require('../../assets/images/partners/11.png'),
          url: 'https://global.11st.co.kr/html/en/main_en.html'
        },
        {
          image: require('../../assets/images/partners/ozonru.png'),
          url: 'https://www.ozon.ru/'
        },
        {
          image: require('../../assets/images/partners/walmart.png'),
          url: 'https://www.walmart.com/'
        },
        {
          image: require('../../assets/images/partners/ebay.png'),
          url: 'https://www.ebay.com/'
        },
        {
          image: require('../../assets/images/partners/kakaku.png'),
          url: 'https://kakaku.com/'
        },
      ],
    }
  },
}
</script>
<style lang="scss" scoped>
.partner-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}

.partner {
  padding: 16px;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: auto;
    max-width: 100%;
    max-height: 40px;
  }
}
</style>
