<template>
  <div :class="`card`" :style="{ 'background-image': `url(${info.bg})` }">
    <div class="card-body">
      <div class="d-flex justify-content-between">
        <img :src="info.image" alt="level image" class="level-image mb-2" />
        <div v-if="userLevel !== level.id && userLevel < level.id">
          <router-link :to="{ name: 'support' }" class="btn join-btn">
            {{ $t("join") }}
          </router-link>
        </div>
        <div v-else-if="userLevel === level.id">
          <button class="btn py-0 btn-secondary" disabled>{{ $t("you_here") }}</button>
        </div>
      </div>
      <h5 class="fw-bold mb-3">{{ $t(`levels.${level.id}`) }}</h5>
      <p class="mb-0">{{ $t("minium_balance") }}: {{ level.min_balance }}$</p>
      <p class="mb-0">
        {{ $t("commission") }}: {{ levelProfit }}%|{{ level.max_order }}
        {{ $t("push") }}
      </p>
    </div>
  </div>
</template>
<script>
import { getLevelProfitPercent } from "@/constants";
import { mapGetters } from "vuex";

export default {
  props: {
    level: {
      type: Object,
      required: true,
    },
    info: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters(["getUserData"]),
    user() {
      return this.getUserData;
    },
    userLevel() {
      return this.user?.level?.id || 1;
    },
    levelProfit() {
      return getLevelProfitPercent(this.level);
    },
  },
};
</script>
<style lang="scss" scoped>
.card {
  border-radius: 16px;
  background-size: 100% 100%;
  border: 0;
  margin: 16px 0;
}
.level-image {
  width: 50px;
}

.join-btn {
  color: white;
  background-color: #f95f62;
  line-height: 1;
}

.sliver {
  background-image: url("@/assets/images/levels/sliver_bg.png");
}

.gold {
  background-image: url("@/assets/images/levels/gold_bg.png");
}

.daimon {
  background-image: url("@/assets/images/levels/daimon_bg.png");
}

.crown {
  background-image: url("@/assets/images/levels/crown_bg.png");
}
</style>
