<template>
  <div class="p-3">
    <h2 class="mb-3">{{ $t("order.title") }}</h2>
    <div class="mb-3 row justify-content-between align-items-center">
      <div class="col d-flex">
        <label for="search" class="col-sm-2 col-form-label">{{ $t("search") }}:</label>
        <input type="text" class="form-control" id="search" v-model="searchValue" />
      </div>
      <div class="col">
        <button class="btn btn-info float-end" @click="showCreateModal">
          {{ $t("new") }}
        </button>
      </div>
    </div>
    <div>
      <EasyDataTable
        :headers="headers"
        :items="orders"
        :search-field="searchFields"
        :search-value="searchValue"
        buttons-pagination
      >
        <template #item-status="{ status }">
          <BadgeComponent :status="status"></BadgeComponent>
        </template>
        <template #item-user_email="{ user }">
          {{ user?.email }}
        </template>
        <template #item-user_name="{ user }">
          {{ user?.name }}
        </template>
        <template #item-total_value="{ total_value, profit_amount }">
          ${{ total_value - profit_amount }}
        </template>
        <template #item-profit_amount="{ profit_amount }">
          ${{ profit_amount }}
        </template>
        <template #item-created_at="{ created_at }">
          {{ moment(created_at).format("Y/MM/DD HH:mm:ss") }}
        </template>
      </EasyDataTable>
    </div>
    <ModalComponent
      :submit-action="handleCreateOrder"
      :title="'Tạo đơn hàng'"
      :modal-id="modalId"
    >
      <template v-slot:modal-body>
        <div v-if="selectedOrder">
          <div class="mb-3">
            <label for="code" class="form-label">{{ $t("order.number") }}</label>
            <input type="text" class="form-control" id="code" v-model="code" disabled />
          </div>
          <div class="mb-3">
            <label for="address" class="form-label">{{ $t("products") }}</label>
            <VSelect
              :options="products"
              label="name"
              :reduce="(p) => p.id"
              v-model="productId"
              @option:selected="selectedProduct"
            />
          </div>
          <div class="mb-3">
            <label for="address" class="form-label">{{ $t("users") }}</label>
            <VSelect
              :options="users"
              label="name"
              :reduce="(u) => u.id"
              v-model="userId"
            />
          </div>
          <div class="mb-3">
            <label for="code" class="form-label">{{ $t("product.price") }}</label>
            <input type="number" class="form-control" id="code" v-model="price" />
          </div>
          <div class="mb-3">
            <label for="code" class="form-label">{{ $t("commission") }}</label>
            <input type="number" class="form-control" id="code" v-model="profitPercent" />
          </div>
          <div class="mb-3">
            <label for="code" class="form-label">{{ $t("order.get_commissions") }}</label>
            <input type="number" class="form-control" id="code" v-model="profitAmount" />
          </div>
        </div>
      </template>
    </ModalComponent>
  </div>
</template>
<script>
import BadgeComponent from "@/components/Home/BadgeComponent.vue";
import ModalComponent from "@/components/ModalComponent.vue";
import constants from "@/constants";
import emitter from "@/mitt";
import moment from "moment";
import { mapActions, mapGetters } from "vuex";

export default {
  setup() {
    const searchFields = ["code", "user.name", "user.email", "created_at"];
    const modalId = "Orders";
    return {
      searchFields,
      modalId,
      constants,
      moment,
    };
  },
  computed: {
    ...mapGetters(["getDataOrders", "getUpdatedData", "getDataUsers", "getDataProducts"]),
    orders() {
      return this.getDataOrders;
    },
    updatedData() {
      return this.getUpdatedData;
    },
    users() {
      return this.getDataUsers;
    },
    products() {
      return this.getDataProducts;
    },

    headers() {
      return [
        { text: this.$t("order.number"), value: "code" },
        { text: this.$t("user"), value: "user_name" },
        { text: "Email", value: "user_email" },
        { text: this.$t("order.amount"), value: "total_value" },
        { text: "%", value: "profit_percent_original" },
        { text: this.$t("commission"), value: "profit_amount" },
        { text: this.$t("status.title"), value: "status" },
        { text: this.$t("date"), value: "created_at" },
      ];
    },
  },
  beforeMount() {
    this.getAdminOrders();
    this.getUsers();
    this.getProducts();
  },
  mounted() {},
  data() {
    return {
      searchValue: "",
      selectedOrder: null,
      userId: null,
      productId: null,
      product: null,

      price: null,
      profitPercent: null,
      profitAmount: null,
      code: null,
    };
  },
  methods: {
    ...mapActions(["getAdminOrders", "getUsers", "getProducts", "createAdminOrder"]),
    showRow(item) {
      this.selectedOrder = { ...item };
      emitter.emit(`show-modal${this.modalId}`);
    },
    showCreateModal() {
      this.code = "OD" + constants.makeId(15).toLocaleUpperCase();
      this.selectedOrder = {};
      emitter.emit(`show-modal${this.modalId}`);
    },
    handleCreateOrder() {
      this.createAdminOrder({
        code: this.code,
        user_id: this.userId,
        product_id: this.productId,
        price: Number(this.price),
        profit_percent: Number(this.profitPercent),
        profit_amount: Number(this.profitAmount),
      });
    },
    selectedProduct(item) {
      this.price = item.price;
    },
  },
  components: { ModalComponent, BadgeComponent },
  watch: {
    updatedData(newVal) {
      if (newVal) {
        this.getAdminOrders();
        emitter.emit(`close-modal${this.modalId}`);
        this.selectedOrder = null;
      }
    },
    profitPercent(newVal) {
      this.profitAmount = newVal * (this.price || 0);
    },
  },
};
</script>
<style lang="scss" scoped></style>
