<template>
  <div class="page-container position-relative d-flex flex-column container">
    <div>
      <div class="dropdown btn-select-lang">
        <button
          class="btn text-light"
          type="button"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          <IconWorld :size="32" class="me-1" />
        </button>
        <ul class="dropdown-menu">
          <li class="text-light">
            <button @click="setLocale('en-US')" class="dropdown-item" href="#">
              <img class="flag-icon" src="@/assets/images/flags/us.svg" alt="" />
              English
            </button>
          </li>
          <li class="text-light">
            <button @click="setLocale('vi-VN')" class="dropdown-item" href="#">
              <img class="flag-icon" src="@/assets/images/flags/vn.svg" alt="" />
              Tiếng Việt
            </button>
          </li>
        </ul>
      </div>
    </div>
    <div class="mt-5 pt-3">
      <ul class="nav nav-tabs">
        <li class="nav-item">
          <router-link :to="{ name: 'termsOfUse' }" class="nav-link">
            {{ $t("rules_title") }}
          </router-link>
        </li>
        <li class="nav-item">
          <router-link :to="{ name: 'privacy' }" class="nav-link active">
            {{ $t("privacy_title") }}
          </router-link>
        </li>
      </ul>
    </div>
    <div class="policy-container flex-grow-1 overflow-auto">
      <!-- Điều khoản -->
      <p class="pre-line mt-3">{{ $t("privacy_policy") }}</p>
      <!-- Chính sách -->
    </div>
  </div>
</template>
<script>
import { IconWorld } from "@tabler/icons-vue";
import "@/assets/css/vue-tel-input.css";

export default {
  data() {
    return {
      activeTab: "rules",
    };
  },
  methods: {
    setActiveTab(tab) {
      this.activeTab = tab;
    },

    setLocale(locale) {
      localStorage.setItem("country", locale);
      this.$i18n.locale = locale;
    },
  },
  components: { IconWorld },
};
</script>
<style lang="scss" scoped>
.page-container {
  background: url("@/assets/images/background_login.png") no-repeat;
  background-size: cover;
  background-position: 50%;
}

.tab-btn {
  padding: 16px 0;
  cursor: pointer;

  &:hover {
    background-color: #2184da31;
  }

  &:first-child {
    width: 60%;
  }

  &:last-child {
    flex-grow: 1;
  }

  &.active {
    border-bottom: 2px solid #2185da;
  }
}

.btn-select-lang {
  position: absolute;
  top: 16px;
  right: 8px;
}
</style>
