<template>
  <router-view />
  <LoadingComponent :loading="loadingState"></LoadingComponent>
</template>

<script>
import LoadingComponent from "@/components/LoadingComponent.vue";
import { mapGetters } from "vuex";

export default {
  components: { LoadingComponent },
  computed: {
    ...mapGetters(["getLoading"]),
    loadingState() {
      return this.getLoading;
    },
  },
  watch: {},
  methods: {},
};
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:wght@400;500;700&display=swap');
body {
  --bs-body-bg: #394249;
  font-family: 'Noto Sans', sans-serif;
}

.custom-toast-class {
  width: clamp(375px, 100vw, 500px);
}
</style>
