<template>
  <div class="d-flex flex-column">
    <div class="d-flex justify-content-center fs-4 position-relative py-2">
      <button @click="$router.go(-1)" class="btn text-white btn-back">
        <IconChevronLeft />
      </button>
      <div>{{ $t("password_withdraw_change") }}</div>
    </div>
  </div>
  <div class="px-5">
    <div class="my-3 input-with-icon">
      <label for="oldPassword" class="form-label"><IconLockSearch :size="32" /></label>
      <input
        id="oldPassword"
        type="password"
        class="form-control"
        v-model="oldPassword"
        :placeholder="$t('placeholder.old_password')"
      />
    </div>
    <div class="my-3 input-with-icon">
      <label for="password" class="form-label"><IconLockCog :size="32" /></label>
      <input
        id="password"
        type="password"
        class="form-control"
        v-model="password"
        :placeholder="$t('placeholder.new_password')"
      />
    </div>
    <div class="my-3 input-with-icon">
      <label for="confirmPassword" class="form-label">
        <IconLockExclamation :size="32" />
      </label>
      <input
        id="confirmPassword"
        type="password"
        class="form-control"
        v-model="confirmPassword"
        :placeholder="$t('placeholder.password_confirm')"
      />
    </div>
  </div>
  <div class="px-5 mt-5 pb-5">
    <button class="btn btn-system w-100" @click="handleUpdatePassword">
      {{ $t("update") }}
    </button>
  </div>
</template>
<script>
import { IconLockCog } from "@tabler/icons-vue";
import { IconLockExclamation } from "@tabler/icons-vue";
import { IconLockSearch } from "@tabler/icons-vue";
import { IconChevronLeft } from "@tabler/icons-vue";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "ChangePassword",
  components: { IconChevronLeft, IconLockSearch, IconLockCog, IconLockExclamation },
  data() {
    return {
      oldPassword: "",
      password: "",
      confirmPassword: "",
    };
  },
  computed: {
    ...mapGetters(["getUpdatedData"]),
    updatedData() {
      return this.getUpdatedData;
    },
  },
  methods: {
    ...mapActions(["updateWithdrawPassword"]),
    handleUpdatePassword() {
      this.updateWithdrawPassword({
        oldPassword: this.oldPassword,
        password: this.password,
        confirm_password: this.confirmPassword,
      });
    },
  },
  watch: {
    updatedData(newVal) {
      if (newVal) {
        this.$router.push({ name: "account" });
      }
    },
  },
};
</script>
<style lang="scss" scoped></style>
