// getters.js
export default {
  getUserData(state) {
    return state.user;
  },
  getLoading(state) {
    return state.loading;
  },

  getChildLoading(state) {
    return state.childLoading;
  },

  getDataUsers(state) {
    return state.users;
  },

  getDataProducts(state) {
    return state.products;
  },

  getDataWithdrawHistories(state) {
    return state.withdrawHistories;
  },

  getDataDepositHistories(state) {
    return state.depositHistories;
  },

  getUpdatedData(state) {
    return state.updatedData;
  },

  getDataMessages(state) {
    return state.messages;
  },

  getDataConversations(state) {
    return state.conversations;
  },

  getDataLevels(state) {
    return state.levels;
  },

  getCreatedOrderData(state) {
    return state.orderData;
  },

  getDataOrders(state) {
    return state.orders;
  },

  getDataDankAccount(state) {
    return state.bankAccount;
  },

  getDataBankList(state) {
    return state.bankList;
  },

  getDataReports(state) {
    return state.reports;
  },

  getDataSpecialOrders(state) {
    return state.specialOrders;
  },

  getBanners(state) {
    return state.banners;
  },
}
