<template>
  <div class="d-flex flex-column">
    <div class="d-flex justify-content-center fs-4 position-relative py-2">
      <router-link :to="{ name: 'account' }" class="btn text-white btn-back">
        <IconChevronLeft />
      </router-link>
      <div>{{ $t("withdraw_page.title") }}</div>
    </div>
  </div>
  <div class="px-3 flex-grow-1">
    <div class="mb-3">{{ $t("withdraw_page.title") }}</div>
    <div class="card history-card" v-for="(history, index) in histories" :key="index">
      <div class="d-flex justify-content-between flex-wrap">
        <div>{{ $t("history.trading_code") }}: {{ history.code }}</div>
        <div>$ {{ history.amount }}</div>
      </div>
      <div class="d-flex justify-content-between mt-3">
        <div>
          {{ $t("date") }}: {{ moment(history.created_at).format("yyyy/MM/DD H:m:s") }}
        </div>
        <div>
          <BadgeComponent :status="history.status" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import constants from "@/constants";
import { IconChevronLeft } from "@tabler/icons-vue";
import { mapActions, mapGetters } from "vuex";
import moment from "moment";
import BadgeComponent from "@/components/Home/BadgeComponent.vue";

export default {
  components: { IconChevronLeft, BadgeComponent },
  setup() {
    return {
      constants,
      moment,
    };
  },
  computed: {
    ...mapGetters(["getDataWithdrawHistories"]),
    histories() {
      return this.getDataWithdrawHistories;
    },
  },
  data() {
    return {};
  },
  beforeMount() {
    this.getPaymentHistories("withdraw");
  },
  methods: {
    ...mapActions(["getPaymentHistories"]),
  },
};
</script>
<style lang="scss" scoped></style>
