<template>
  <div class="introduce-popup">
    <div class="d-flex justify-content-center fs-4 position-relative">
      <button class="btn text-white btn-back" @click="closePopup">
        <IconChevronLeft />
      </button>
      <div>{{ dataE[popupType].title }}</div>
    </div>
    <div class="introduce-popup-content">
      {{ dataE[popupType].description }}
    </div>
  </div>
</template>
<script>
import emitter from "@/mitt";
import { IconChevronLeft } from "@tabler/icons-vue";

export default {
  components: { IconChevronLeft },
  props: {
    popupType: String,
  },
  computed: {
    dataE() {
      return {
        about_us: {
          title: this.$t("data_popup.about_us.title"),
          description: this.$t("data_popup.about_us.description"),
        },
        company: {
          title: this.$t("data_popup.company.title"),
          description: this.$t("data_popup.company.description"),
        },
        rule: {
          title: this.$t("data_popup.rule.title"),
          description: this.$t("data_popup.rule.description"),
        },
        poxy: {
          title: this.$t("data_popup.poxy.title"),
          description: this.$t("data_popup.poxy.description"),
        },
        policy: {
          title: this.$t("data_popup.policy.title"),
          description: this.$t("data_popup.policy.description"),
        },
      };
    },
  },
  methods: {
    closePopup() {
      emitter.emit("close-popup");
    },
  },
};
</script>
<style lang="scss" scoped>
.introduce-popup {
  width: 100%;
  height: 100vh;
  position: fixed;
  z-index: 9999;
  background-color: #2a353e;
  top: 0;
  padding: 16px;
  overflow-y: auto;
}

.introduce-popup-content {
  padding: 16px;
  background-color: #414d59;
  margin-top: 16px;
  border-radius: 16px;
  white-space: pre-line;
}
</style>
