import constants from '@/constants';
import HTTP from '@/http_common';
// import statusCode from '@/statusCode';
import routes from '@/routes';

// actions.js
export default {
	async login(context, user) {
		context.commit('loading', true);
		try {
			const res = await HTTP.post('/auth/login', { ...user });
			if (res?.accessToken) {
				localStorage.setItem('access_token', res?.accessToken);
				context.commit('loading', false);
				routes.push({ name: 'home' });
			}
		} catch (error) {
			context.commit('loading', false);
		}
	},

	async getUserLogin(context) {
		try {
			context.commit('loading', true);
			const res = await HTTP.get('/auth');
			localStorage.setItem('role', res?.isLoggedInUser?.role || '');
			context.commit('user', res?.isLoggedInUser);
			context.commit('loading', false);
		} catch (error) {
			routes.push({ name: 'login' });
			context.commit('loading', false);
		}
	},

	async logout(context) {
		try {
			context.commit('loading', true);
			await HTTP.post('/auth/logout');
			context.commit('loading', false);
      constants.clearStorages(['access_token', 'displayedModal'])
			context.commit('user', null);
			routes.push({ name: 'login' });
		} catch (error) {
			context.commit('loading', false);
		}
	},

	async signup(context, user) {
		try {
			context.commit('loading', true);
			const res = await HTTP.post(
				'/auth/signup',
				{ ...user },
				{
					showToast: true,
				}
			);
			if (res.newUser) {
				routes.push({ name: 'login' });
				context.commit('loading', false);
			}
		} catch (err) {
			context.commit('loading', false);
		}
	},

	//ADMIN
	async getUsers(context) {
		try {
			const res = await HTTP.get('/admin/user-manager');
			context.commit('users', res?.items);
		} catch (err) {
			//
		}
	},
	async getProducts(context) {
		try {
			const res = await HTTP.get('/products');
			context.commit('products', res?.items);
		} catch (err) {
			//
		}
	},
	async getFreeProducts(context) {
		try {
			context.commit('products', []);
			const res = await HTTP.get('/free-products');
			context.commit('products', res?.items);
		} catch (err) {
			//
		}
	},

	async createOrUpdateUser(context, user) {
		try {
			context.commit('loading', true);

			if (!user.password) {
				delete user.password;
			}
			if (user.id) {
				await HTTP.put(
					`/admin/user-manager/${user.id}/edit`,
					{ ...user },
					{
						showToast: true,
					}
				);
			} else {
				await HTTP.post(
					'/admin/user-manager/create',
					{ ...user },
					{
						showToast: true,
					}
				);
			}
			context.commit('updatedData', new Date());
			context.commit('loading', false);
		} catch (error) {
			context.commit('loading', false);
		}
	},

	async createOrUpdateProduct(context, product) {
		try {
			context.commit('loading', true);

			if (product.id || product?.get('id')) {
				await HTTP.patch(`/products/${product.id || product?.get('id')}`, product, {
					showToast: true,
				});
			} else {
				await HTTP.post('/products', product, {
					showToast: true,
				});
			}
			context.commit('updatedData', new Date());
			context.commit('loading', false);
		} catch (error) {
			context.commit('loading', false);
		}
	},

	async createOrUpdateFreeProduct(context, product) {
		try {
			context.commit('loading', true);

			if (product.id || product?.get('id')) {
				await HTTP.patch(`/free-products/${product.id || product?.get('id')}`, product, {
					showToast: true,
				});
			} else {
				await HTTP.post('/free-products', product, {
					showToast: true,
				});
			}
			context.commit('updatedData', new Date());
			context.commit('loading', false);
		} catch (error) {
			context.commit('loading', false);
		}
	},

	async createPayment(context, payment) {
		try {
			const { amount, paymentType, password } = payment;
			const data = { amount: Number(amount) };
			if (password) {
				data.password = password;
			}
			context.commit('loading', true);
			await HTTP.post(`/payments/${paymentType}`, data);
			context.commit('updatedData', new Date());
			context.commit('loading', false);
		} catch (error) {
			context.commit('loading', false);
		}
	},

	async getPaymentHistories(context, paymentType) {
		try {
			context.commit('loading', true);
			const res = await HTTP.get(`/payments/my-${paymentType}`);
			context.commit(`${paymentType}Histories`, res?.items);
			context.commit('loading', false);
		} catch (error) {
			context.commit('loading', false);
		}
	},

	async getWithdrawHistory(context) {
		try {
			context.commit('loading', true);
			const res = await HTTP.get('/payments/withdraw');
			context.commit('withdrawHistories', res?.items);
			context.commit('loading', false);
		} catch (error) {
			context.commit('loading', false);
		}
	},

	async getDepositHistory(context) {
		try {
			context.commit('loading', true);
			const res = await HTTP.get('/payments/deposit');
			context.commit('depositHistories', res?.items);
			context.commit('loading', false);
		} catch (error) {
			context.commit('loading', false);
		}
	},

	async updatePaymentStatus(context, payment) {
		try {
			const { id, status, type } = payment;
			context.commit('loading', true);
			await HTTP.patch(`/payments/${type}/${id}`, { status });
			context.commit('updatedData', new Date());
			context.commit('loading', false);
		} catch (error) {
			context.commit('loading', false);
		}
	},

	async updateProfile(context, profile) {
		try {
			context.commit('loading', true);
			await HTTP.put(`/users/update-info`, profile, {
				showToast: true,
			});
			context.commit('updatedData', new Date());
			context.commit('loading', false);
		} catch (error) {
			context.commit('loading', false);
		}
	},

	async updatePassword(context, newPasswords) {
		try {
			context.commit('loading', true);
			await HTTP.put(`/users/update-password`, newPasswords, {
				showToast: true,
			});
			context.commit('updatedData', new Date());
			context.commit('loading', false);
		} catch (error) {
			context.commit('loading', false);
		}
	},

	async updateWithdrawPassword(context, newPasswords) {
		try {
			context.commit('loading', true);
			await HTTP.put(`/users/update-withdraw-password`, newPasswords, {
				showToast: true,
			});
			context.commit('updatedData', new Date());
			context.commit('loading', false);
		} catch (error) {
			context.commit('loading', false);
		}
	},

	async getMessages(context) {
		try {
			context.commit('loading', true);
			const res = await HTTP.get(`/chat/messages?receiver_id=1`);
			context.commit('messages', res?.data);
			context.commit('loading', false);
		} catch (error) {
			context.commit('loading', false);
		}
	},

	async setMessages(context, messages) {
		context.commit('messages', messages);
	},

	async getChatList(context) {
		try {
			context.commit('loading', true);
			const res = await HTTP.get(`/chat/groups`);
			const converSations = res.data.map((c) => {
				return {
					...c,
					messages: c.messages.sort((a, b) => Number(b.id) - Number(a.id)),
				};
			});
			context.commit('conversations', converSations);
			context.commit('loading', false);
		} catch (error) {
			context.commit('loading', false);
		}
	},

	async sendMessages(context, message) {
		try {
			await HTTP.post(`/chat/messages/create`, message);
		} catch (error) {
			console.log(error);
		}
	},

	async addProductToUser(context, products) {
		try {
			context.commit('loading', true);
			await HTTP.post(`/admin/product/add_to_user`, products, {
				showToast: true,
			});
			context.commit('updatedData', new Date());
			context.commit('loading', false);
		} catch (error) {
			context.commit('loading', false);
		}
	},

	async getAddedProducts(context) {
		try {
			const res = await HTTP.get(`/users/products`);
			context.commit('products', res?.items);
		} catch (error) {
			//
		}
	},

	async getLevels(context) {
		try {
			const res = await HTTP.get(`/levels`);
			context.commit('levels', res?.data);
		} catch (error) {
			//
		}
	},

	async createOrder(context, orderInfo) {
		try {
      context.commit('errors', null);
			const res = await HTTP.post(`/order/create`, orderInfo);
			context.commit('orderData', res?.newOrder);
		} catch (error) {
      context.commit('errors', error);
		}
	},

	async getAuthOrders(context, status) {
		try {
			context.commit('orders', []);
			context.commit('loading', true);
			const res = await HTTP.get(`/order/my_orders?status=${status}`);
			context.commit('orders', res?.items);
			context.commit('loading', false);
		} catch (error) {
			//
			context.commit('loading', false);
		}
	},

	async getAdminOrders(context) {
		try {
			context.commit('orders', []);
			context.commit('loading', true);
			const res = await HTTP.get(`/admin/orders`);
			context.commit('orders', res?.items);
			context.commit('loading', false);
		} catch (error) {
			//
			context.commit('loading', false);
		}
	},

	async createAdminOrder(context, orderInfo) {
		try {
			context.commit('loading', true);
			await HTTP.post(`/admin/order/add_to_user`, orderInfo);
			context.commit('updatedData', new Date());
			context.commit('loading', false);
		} catch (error) {
			context.commit('loading', false);
		}
	},

	async submitOrder(context, status) {
		try {
			context.commit('loading', true);
			const res = await HTTP.patch(
				`/order/${status.id}`,
				{ status: status.status },
				{
					showToastError: false,
					showToast: true,
          customToastLocation: 'bottom'
				}
			);
			context.commit('orderData', res.data);
			context.commit('updatedData', new Date());
			context.commit('loading', false);
		} catch (error) {
			context.commit('updatedData', new Date());
			context.commit('loading', false);
		}
	},

	async getBankAccount(context) {
		try {
			context.commit('loading', true);
			const res = await HTTP.get(`/bank-accounts`);
			context.commit('bankAccount', res.data[0]);
			context.commit('loading', false);
		} catch (error) {
			context.commit('loading', false);
		}
	},

	async createBankAccount(context, account) {
		try {
			context.commit('loading', true);
			const res = await HTTP.post(`/bank-accounts`, account, {
				showToast: true,
			});
			context.commit('bankAccount', res.data);
			context.commit('updatedData', new Date());
			context.commit('loading', false);
		} catch (error) {
			context.commit('loading', false);
		}
	},

  async adminAddBank(context, account) {
    try {
      context.commit('loading', true);
      await HTTP.post(`/admin/bank-account/create`, account, {
        showToast: true,
      });
      context.commit('updatedData', new Date());
      context.commit('loading', false);
    } catch (error) {
      context.commit('loading', false);
    }
  },

	async updateBankAccount(context, account) {
		try {
			context.commit('loading', true);
			const id = account.id;
			delete account.id;
			const res = await HTTP.patch(`/bank-accounts/${id}`, account, {
				showToast: true,
			});
			context.commit('bankAccount', res.data);
			context.commit('updatedData', new Date());
			context.commit('loading', false);
		} catch (error) {
			context.commit('loading', false);
		}
	},

	async getOrderPrize(context) {
		try {
			const res = await HTTP.get(`/order/prize`);
			if (res?.data) {
				context.commit('orderData', res?.data);
			}
		} catch (error) {
			context.commit('loading', false);
		}
	},

	async setNullOrderPrize(context) {
		context.commit('orderData', null);
	},

	async getBankList(context) {
		try {
			context.commit('loading', true);
			const res = await HTTP.get('https://api.vietqr.io/v2/banks', {
				baseURL: '',
			});
			context.commit('bankList', res?.data);
			context.commit('loading', false);
		} catch (error) {
			context.commit('loading', false);
		}
		context.commit('orderData', null);
	},

	async getReports(context, data) {
		try {
			context.commit('reports', null);
      const params = new URLSearchParams();
      data && Object.entries(data).forEach(([key, value]) => {
        params.append(key, value);
      });
			context.commit('loading', true);
			const res = await HTTP.get('/users?' + params.toString());
			context.commit('reports', res?.data);
			context.commit('loading', false);
		} catch (error) {
			context.commit('loading', false);
      console.log(error);
		}
	},

  async addMessage(context, messageContent) {
		try {
      const message = JSON.parse(messageContent);
			context.commit('addMessage', message);
			context.commit('addMessageToConversations', message);
		} catch (error) {
			console.error(error);
		}
	},

  async deleteItem(context, item) {
    try {
			context.commit('loading', true);
			await HTTP.delete(`/${item.type}/${item.id}`, {
				showToast: true,
			});
			context.commit('updatedData', new Date());
			context.commit('loading', false);
		} catch (error) {
			context.commit('loading', false);
		}
  },

  async deleteUser(context, item) {
    try {
			context.commit('loading', true);
			await HTTP.delete(`/admin/user-manager/${item.id}/delete`, {
				showToast: true,
			});
			context.commit('updatedData', new Date());
			context.commit('loading', false);
		} catch (error) {
			context.commit('loading', false);
		}
  },

  async updateUserOrderStatus(context, status) {
    try {
      await HTTP.patch(`/admin/user-manager/${status.user_id}/special-order-status/update`, {
        status: status.status
      }, {
        showToast: true,
      })
    } catch (error) {
      //
    }
  },

  async getSpecialOrder(context, userId) {
    try {
			context.commit('childLoading', true);
      const res = await HTTP.get(`/admin/user-manager/${userId}/special-orders`)
			context.commit('specialOrders', res.data);
			context.commit('childLoading', false);
    } catch (error) {
			context.commit('childLoading', false);
    }
  },
  addSpecialOrder(context, data) {
    context.commit('addSpecialOrder', data);
  },
  removeSpecialOrder(context, index) {
    context.commit('removeSpecialOrder', index);
  },

  async addSpecialOrders(context, data) {
    try {
      context.commit('loading', true);
      await HTTP.post(`/admin/user-manager/${data.user_id}/special-orders/add`, {
        products: data.products
      }, {
        showToast: true,
      })
			context.commit('updatedData', new Date());
      context.commit('loading', false);
    } catch (error) {
      context.commit('loading', false);
    }
  },

  async fetchBanners(context) {
    try {
      context.commit('loading', true);
      const res = await HTTP.get(`/banner-images`)
			context.commit('setBanners', res.items || []);
      context.commit('loading', false);
    } catch (error) {
      context.commit('loading', false);
    }
  },

  async createBannerImages(context, images) {
    try {
      context.commit('loading', true);
      await HTTP.post(`/banner-images`, images, {
        showToast: true,
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
      context.commit('updatedData', new Date());
      context.commit('loading', false);
    } catch (error) {
      context.commit('loading', false);
    }
  },

  async deleteBannerImages(context, imageIds) {
    try {
      context.commit('loading', true);
      await HTTP.post(`/banner-images/delete`, {imageIds}, {
        showToast: true,
      })
      context.commit('updatedData', new Date());
      context.commit('loading', false);
    } catch (error) {
      context.commit('loading', false);
    }
  }
};
