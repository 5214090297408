<template>
  <div class="p-3">
    <h2 class="mb-3">{{ $t("products") }}</h2>
    <div class="mb-3 row justify-content-between align-items-center">
      <div class="col d-flex">
        <label for="search" class="col-sm-2 col-form-label">{{ $t("search") }}:</label>
        <input type="text" class="form-control" id="search" v-model="searchValue" />
      </div>
      <div class="col">
        <button class="btn btn-info float-end" @click="showCreateModal">
          {{ $t("new") }}
        </button>
      </div>
    </div>
    <div>
      <EasyDataTable
        :headers="headers"
        :items="products"
        :search-field="searchFields"
        :search-value="searchValue"
        buttons-pagination
      >
        <template #item-img="{ img }">
          <div class="product-img border">
            <img :src="assetUrl + img" />
          </div>
        </template>
        <template #item-operation="item">
          <div class="py-1 d-flex justify-content-center">
            <button class="btn btn-primary p-1 me-1" @click="showRow(item)">
              <IconInfoSquare />
            </button>
            <button class="btn btn-danger p-1" @click="showDeleteModal(item)">
              <IconTrash />
            </button>
          </div>
        </template>
      </EasyDataTable>
    </div>
  </div>
  <ModalComponent
    :submit-action="updateOrCreateProduct"
    :title="$t('products')"
    :modal-id="modalId"
  >
    <template v-slot:modal-body>
      <div v-if="selectedProduct">
        <div>
          <UploadImage
            v-model:image="selectedProduct.productImage"
            :default-image="assetUrl + selectedProduct.img"
          />
        </div>
        <div class="mb-3">
          <label for="name" class="form-label">{{ $t("name") }}:</label>
          <input
            type="text"
            class="form-control"
            id="name"
            v-model="selectedProduct.name"
          />
        </div>
        <div class="mb-3">
          <label for="price" class="form-label">{{ $t("product.price") }}:</label>
          <input
            type="text"
            class="form-control"
            id="price"
            v-model="selectedProduct.price"
          />
        </div>
        <div class="mb-3">
          <label for="description" class="form-label">{{ $t("description") }}:</label>
          <textarea
            class="form-control"
            rows=""
            cols=""
            v-model="selectedProduct.description"
          >
          </textarea>
        </div>
      </div>
    </template>
  </ModalComponent>
  <ModalComponent
    :submit-action="deleteProduct"
    :title="$t('products')"
    :modal-id="modalDeleteId"
  >
    <template v-slot:modal-body>
      <div v-if="selectedProduct">
        <p class="text-center">{{ $t("delete_message") }}</p>
        <p class="text-center fw-bold text-danger">{{ selectedProduct?.name }}</p>
      </div>
    </template>
  </ModalComponent>
</template>
<script>
import ModalComponent from "@/components/ModalComponent.vue";
import UploadImage from "@/components/UploadImage.vue";
import emitter from "@/mitt";
import { IconTrash } from "@tabler/icons-vue";
import { IconInfoSquare } from "@tabler/icons-vue";
import { mapActions, mapGetters } from "vuex";

export default {
  setup() {
    const searchFields = ["id", "name", "description", "price"];
    const modalId = "Product";
    const modalDeleteId = "ProductDelete";
    return {
      searchFields,
      modalId,
      modalDeleteId,
    };
  },
  beforeCreate() {},
  computed: {
    ...mapGetters(["getDataProducts", "getUpdatedData"]),
    products() {
      return this.getDataProducts;
    },
    updatedData() {
      return this.getUpdatedData;
    },
    headers() {
      return [
        { text: "ID", value: "id" },
        { text: this.$t("image"), value: "img" },
        { text: this.$t("name"), value: "name" },
        { text: this.$t("description"), value: "description" },
        { text: this.$t("product.price"), value: "price" },
        { text: this.$t("setting"), value: "operation" },
      ];
    },
  },
  mounted() {
    this.getProducts();
    const modalOrder = document.getElementById("modal" + this.modalId);
    modalOrder.addEventListener("hidden.bs.modal", () => {
      this.selectedProduct = null;
    });
  },
  data() {
    return {
      selectedProduct: null,
      searchValue: "",
    };
  },
  methods: {
    ...mapActions(["getProducts", "createOrUpdateProduct", "deleteItem"]),
    showRow(item) {
      this.selectedProduct = { ...item };
      emitter.emit(`show-modal${this.modalId}`);
    },
    showDeleteModal(item) {
      this.selectedProduct = { ...item };
      emitter.emit(`show-modal${this.modalDeleteId}`);
    },
    deleteProduct() {
      this.deleteItem({
        type: "products",
        id: this.selectedProduct.id,
      });
    },
    updateOrCreateProduct() {
      const form = new FormData();
      for (const [key, value] of Object.entries(this.selectedProduct)) {
        if (["created_at", "updated_at", "deleted_at", "key"].includes(key)) continue;
        form.append(key, !value ? "" : value);
      }
      this.createOrUpdateProduct(form);
    },
    showCreateModal() {
      this.selectedProduct = {};
      emitter.emit(`show-modal${this.modalId}`);
    },
  },
  components: {
    ModalComponent,
    UploadImage,
    IconTrash,
    IconInfoSquare,
  },
  watch: {
    updatedData(newVal) {
      if (newVal) {
        this.getProducts();
        emitter.emit(`close-modal${this.modalId}`);
        emitter.emit(`close-modal${this.modalDeleteId}`);
        this.selectedProduct = {};
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.product-img {
  width: 60px;
  height: 60px;
  overflow: hidden;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;

  &.lg {
    width: 150px;
    height: 150px;
  }

  img {
    object-fit: cover;
  }
}
</style>
