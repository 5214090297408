<template>
  <div class="page-container position-relative d-flex flex-column container">
    <div>
      <div class="dropdown btn-select-lang">
        <button
          class="btn text-light"
          type="button"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          <IconWorld :size="32" class="me-1" />
        </button>
        <ul class="dropdown-menu">
          <li class="text-light">
            <button @click="setLocale('en-US')" class="dropdown-item" href="#">
              <img class="flag-icon" src="@/assets/images/flags/us.svg" alt="" />
              English
            </button>
          </li>
          <li class="text-light">
            <button @click="setLocale('vi-VN')" class="dropdown-item" href="#">
              <img class="flag-icon" src="@/assets/images/flags/vn.svg" alt="" />
              Tiếng Việt
            </button>
          </li>
        </ul>
      </div>
    </div>
    <div class="mt-5 pt-3"></div>
    <div class="policy-container flex-grow-1 overflow-auto">
      <h5>{{ $t("contact_info") }}:</h5>

      <ul>
        <li>
          {{ $t("address") }}: 250 Vesey Street Suite 106 - New York NY United States 10281
        </li>
        <li>{{ $t("auth.phone_number") }}: 917.344.1004</li>
        <li>Fax: 917.344.1004</li>
      </ul>
      <h5>Contact Us</h5>
      <form class="mt-3" action="#" @submit.prevent="handleSubmit">
        <div class="mb-3">
          <label for="Name" class="form-label">{{ $t("name") }}:</label>
          <input type="text" class="form-control" id="Name" required />
        </div>
        <div class="mb-3">
          <label for="email" class="form-label">Email:</label>
          <input type="email" class="form-control" id="email" required />
        </div>
        <div class="mb-3">
          <label for="Address" class="form-label">{{ $t("address") }}:</label>
          <input type="text" class="form-control" id="Address" />
        </div>
        <div class="mb-3">
          <label for="phone" class="form-label">{{ $t("auth.phone_number") }}:</label>
          <input type="text" class="form-control" id="phone" />
        </div>
        <div class="mb-3">
          <label for="Content" class="form-label">Content:</label>
          <textarea class="form-control" id="Content" required rows="3"></textarea>
        </div>
        <div class="mb-3 text-center">
          <input type="submit" value="Send" class="btn btn-primary px-5" />
        </div>
      </form>
      <!-- Chính sách -->
    </div>
    <LoadingComponent :loading="loading"></LoadingComponent>
  </div>
</template>
<script>
import { IconWorld } from "@tabler/icons-vue";
import "@/assets/css/vue-tel-input.css";
import LoadingComponent from "@/components/LoadingComponent.vue";

export default {
  data() {
    return {
      activeTab: "rules",
      loading: false,
    };
  },
  methods: {
    handleSubmit() {
      this.loading = true;
      setTimeout(() => {
        this.$router.push({ name: "login" });
        this.$toast.success("Inquiry sent !!", {
          position: "top-right",
        });
        this.loading = false;
      }, 2400);
    },
  },
  components: { IconWorld, LoadingComponent },
};
</script>
<style lang="scss" scoped>
.page-container {
  background: url("@/assets/images/background_login.png") no-repeat;
  background-size: cover;
  background-position: 50%;
}

.tab-btn {
  padding: 16px 0;
  cursor: pointer;

  &:hover {
    background-color: #2184da31;
  }

  &:first-child {
    width: 60%;
  }

  &:last-child {
    flex-grow: 1;
  }

  &.active {
    border-bottom: 2px solid #2185da;
  }
}

.btn-select-lang {
  position: absolute;
  top: 16px;
  right: 8px;
}
</style>
