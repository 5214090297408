import { createApp } from 'vue'
import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap"
import '@splidejs/vue-splide/css';
import 'vue-toast-notification/dist/theme-sugar.css';
import 'swiper/css';
import 'vue3-easy-data-table/dist/style.css';
import routes from '@/routes'
import App from './App.vue'
import store from './store'
import Vue3EasyDataTable from 'vue3-easy-data-table';
import "vue-select/dist/vue-select.css";
import vSelect from 'vue-select'
import ToastPlugin from 'vue-toast-notification';
import VueTelInput from 'vue-tel-input';
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";
import { createI18n } from 'vue-i18n'
import en from '@/lang/en';
import vi from '@/lang/vi';
import 'vue-easy-lightbox/external-css/vue-easy-lightbox.css'
import 'vue3-emoji-picker/css'

const app = createApp(App);

app.mixin({
  data() {
    return {
      assetUrl: process.env.VUE_APP_ASSET_URL
    };
  }
});
const messages = {
  'en-US': en,
  'vi-VN': vi,
}

const i18n = createI18n({
  messages,
  locale: localStorage.getItem("country"), // set locale
  fallbackLocale: 'vi-VN', // set fallback locale
})

app.use(i18n);
app.use(VueTelInput);
//using lib
app.use(routes)
app.use(store)
app.use(ToastPlugin);
app.use(Toast);
app.component('EasyDataTable', Vue3EasyDataTable);
app.component('VSelect', vSelect);
app.mount('#app')
