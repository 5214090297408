<template>
  <div
    class="page-container position-relative d-flex flex-column justify-content-center container"
  >
    <div>
      <div class="dropdown btn-select-lang">
        <button
          class="btn text-light"
          type="button"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          <IconWorld :size="32" class="me-1" />
        </button>
        <ul class="dropdown-menu">
          <li class="text-light">
            <button @click="setLocale('en-US')" class="dropdown-item" href="#">
              <img class="flag-icon" src="@/assets/images/flags/us.svg" alt="" />
              English
            </button>
          </li>
          <li class="text-light">
            <button @click="setLocale('vi-VN')" class="dropdown-item" href="#">
              <img class="flag-icon" src="@/assets/images/flags/vn.svg" alt="" />
              Tiếng Việt
            </button>
          </li>
        </ul>
      </div>
    </div>
    <div class="logo d-flex justify-content-center mb-3">
      <img src="@/assets/logo.png" alt="" />
    </div>
    <div class="mb-5">
      <div class="w-100 mx-auto d-flex justify-content-center">
        <div
          class="text-center tab-btn text-uppercase"
          :class="{ active: activeTab == 'phone' }"
          @click="setActiveTab('phone')"
        >
          {{ $t("auth.phone_number") }}
        </div>
        <div
          class="text-center tab-btn text-uppercase"
          :class="{ active: activeTab == 'email' }"
          @click="setActiveTab('email')"
        >
          {{ $t("auth.email") }}
        </div>
      </div>
    </div>
    <div class="mb-3 input-with-icon" v-if="activeTab == 'email'">
      <label for="email" class="form-label">
        <IconMail :size="32" />
      </label>
      <input
        type="email"
        class="form-control"
        id="email"
        :placeholder="$t('placeholder.email')"
        v-model="email"
      />
    </div>
    <div class="mb-3 input-with-icon phone-input" v-if="activeTab == 'phone'">
      <label for="email" class="form-label">
        <IconDeviceMobile :size="32" />
      </label>
      <vue-tel-input
        v-model="phone"
        :default-country="'vn'"
        :auto-format="false"
        :dropdownOptions="{
          showDialCodeInSelection: true,
          showFlags: true,
        }"
        :inputOptions="{
          placeholder: $t('placeholder.phone'),
        }"
        @country-changed="handleCountryChanged"
      ></vue-tel-input>
    </div>
    <div class="mb-3 input-with-icon">
      <label for="password" class="form-label">
        <IconLock :size="32" />
      </label>
      <input
        type="password"
        id="password"
        class="form-control"
        v-model="password"
        autocomplete="off"
        :placeholder="$t('placeholder.password')"
        @keyup.enter="handleLogin"
      />
    </div>
    <div class="d-flex justify-content-center flex-column align-items-center mt-3">
      <button
        type="button"
        class="btn btn-primary w-100 mb-3 btn-login py-2 fs-5"
        @click="handleLogin"
      >
        {{ $t("login") }}
      </button>
      <router-link :to="{ name: 'register' }" class="nav-link">
        {{ $t("register") }}
      </router-link>
    </div>
    <div class="d-flex justify-content-center align-items-center mt-3">
      <router-link :to="{ name: 'termsOfUse' }" class="nav-link">
        {{ $t("terms_of_use") }}
      </router-link>
      <!-- <router-link :to="{ name: 'inquiry' }" class="nav-link ms-3">
        {{ $t("menu.support") }}
      </router-link> -->
      <a target="_blank" href="https://www.michaelkors.global/en_SG/stores/search/united-states/25/" class="nav-link ms-3">
        {{ $t("menu.support") }}
      </a>
    </div>
  </div>
</template>
<script>
import { IconWorld, IconLock } from "@tabler/icons-vue";
import { IconMail } from "@tabler/icons-vue";
import { mapActions } from "vuex";
import "@/assets/css/vue-tel-input.css";
import { IconDeviceMobile } from "@tabler/icons-vue";

export default {
  data() {
    return {
      email: "",
      password: "",
      phone: "",
      activeTab: "phone",
      selectedCountryCode: "",
    };
  },
  methods: {
    ...mapActions(["login"]),
    async handleLogin() {
      const formLogin = {
        password: this.password,
        isRemember: 1,
      };
      if (this.activeTab == "phone") {
        formLogin.phone = `${this.selectedCountryCode}${this.phone}`;
      } else {
        formLogin.email = this.email;
      }
      await this.login(formLogin);
    },
    setActiveTab(tab) {
      this.activeTab = tab;
      if (tab == "email") {
        this.phone = "";
      } else {
        this.email = "";
      }
    },
    handleCountryChanged(countryData) {
      this.selectedCountryCode = countryData.dialCode;
    },

    setLocale(locale) {
      localStorage.setItem("country", locale);
      this.$i18n.locale = locale;
    },
  },
  components: { IconMail, IconLock, IconDeviceMobile, IconWorld },
};
</script>
<style lang="scss" scoped>
.logo {
  width: 100px;
  height: 100px;
  border-radius: 20px;
  overflow: hidden;
  margin: 0 auto;
}

.page-container {
  background: url("@/assets/images/background_login.png") no-repeat;
  background-size: cover;
  background-position: 50%;
}

.btn-login {
  padding: 8px;
  border-radius: 24px;
  background: #2185da;
}

.tab-btn {
  padding: 16px 0;
  cursor: pointer;
  &:hover {
    background-color: #2184da31;
  }

  &:first-child {
    width: 60%;
  }

  &:last-child {
    flex-grow: 1;
  }

  &.active {
    border-bottom: 2px solid #2185da;
  }
}

.btn-select-lang {
  position: absolute;
  top: 16px;
  right: 8px;
}
</style>
