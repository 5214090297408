<template>
  <div class="page-container d-flex flex-column justify-content-center container">
    <div class="logo d-flex justify-content-center mb-5">
      <img src="@/assets/logo.png" alt="" />
    </div>
    <div class="mb-5">
      <div class="w-100 mx-auto d-flex justify-content-center">
        <div
          class="text-center tab-btn text-uppercase"
          :class="{ active: activeTab == 'phone' }"
          @click="setActiveTab('phone')"
        >
          {{ $t("auth.phone_number") }}
        </div>
        <div
          class="text-center tab-btn text-uppercase"
          :class="{ active: activeTab == 'email' }"
          @click="setActiveTab('email')"
        >
          {{ $t("auth.email") }}
        </div>
      </div>
    </div>
    <div class="mb-3 input-with-icon">
      <label for="email" class="form-label"><IconUserEdit :size="32" /></label>
      <input
        type="text"
        class="form-control"
        v-model="name"
        :placeholder="$t('placeholder.account_name')"
      />
    </div>
    <div class="mb-3 input-with-icon" v-if="activeTab == 'email'">
      <label for="email" class="form-label">
        <IconMail :size="32" />
      </label>
      <input
        type="email"
        class="form-control"
        v-model="email"
        :placeholder="$t('placeholder.email')"
      />
    </div>
    <div class="mb-3 input-with-icon phone-input" v-if="activeTab == 'phone'">
      <label for="email" class="form-label">
        <IconDeviceMobile :size="32" />
      </label>
      <vue-tel-input
        v-model="phone"
        :default-country="'vn'"
        :auto-format="false"
        :dropdownOptions="{
          showDialCodeInSelection: true,
          showFlags: true,
        }"
        :inputOptions="{
          placeholder: $t('placeholder.phone'),
        }"
        @country-changed="handleCountryChanged"
      ></vue-tel-input>
    </div>
    <div class="mb-3 input-with-icon">
      <label for="password" class="form-label">
        <IconLock :size="32" />
      </label>
      <input
        type="password"
        v-model="password"
        class="form-control"
        :placeholder="$t('placeholder.password')"
      />
    </div>
    <div class="mb-3 input-with-icon">
      <label for="passwordC" class="form-label">
        <IconLock :size="32" />
      </label>
      <input
        type="password"
        id="passwordC"
        v-model="passwordConfirm"
        class="form-control"
        :placeholder="$t('placeholder.password_confirm')"
      />
    </div>
    <div class="mb-3 input-with-icon">
      <label for="phone" class="form-label">
        <IconMailHeart :size="32" />
      </label>
      <input
        type="number"
        class="form-control"
        :placeholder="$t('placeholder.invite_code_input')"
        v-model="inviteCode"
      />
    </div>
    <div class="d-flex justify-content-center flex-column align-items-center mt-3">
      <button
        class="btn btn-primary w-100 mb-3 btn-login py-2 fs-5"
        @click="handleRegister"
      >
        {{ $t("register") }}
      </button>
      <router-link :to="{ name: 'login' }" class="nav-link">{{
        $t("login")
      }}</router-link>
    </div>
    <div class="d-flex justify-content-center align-items-center mt-3">
      <router-link :to="{ name: 'termsOfUse' }" class="nav-link">
        {{ $t("terms_of_use") }}
      </router-link>
    </div>
  </div>
</template>
<script>
import { IconDeviceMobile, IconMail, IconMailHeart } from "@tabler/icons-vue";
import { IconLock } from "@tabler/icons-vue";
import { IconUserEdit } from "@tabler/icons-vue";
import { mapActions } from "vuex";
import "@/assets/css/vue-tel-input.css";

export default {
  data() {
    return {
      email: "",
      name: "",
      password: "",
      passwordConfirm: "",
      phone: "",
      inviteCode: "",
      selectedCountryCode: "",
      activeTab: "phone",
    };
  },
  methods: {
    ...mapActions(["signup"]),
    handleRegister() {
      if (this.password != this.passwordConfirm) {
        this.$toast.error("Mật khẩu không khớp", {
          position: "top",
        });
        return;
      }
      const formRegister = {
        password: this.password,
        confirm_password: this.passwordConfirm,
        name: this.name,
        invite_id: Number(this.inviteCode),
      };
      if (this.activeTab == "phone") {
        formRegister.phone = `${this.selectedCountryCode}${this.phone}`;
      } else {
        formRegister.email = this.email;
      }
      this.signup(formRegister);
    },
    setActiveTab(tab) {
      this.activeTab = tab;
      if (tab == "email") {
        this.phone = "";
      } else {
        this.email = "";
      }
    },
    handleCountryChanged(countryData) {
      this.selectedCountryCode = countryData.dialCode;
    },
  },
  components: { IconUserEdit, IconMail, IconLock, IconMailHeart, IconDeviceMobile },
};
</script>

<style lang="scss" scoped>
.logo {
  width: 100px;
  height: 100px;
  border-radius: 20px;
  overflow: hidden;
  margin: 0 auto;
}

.page-container {
  background: url("@/assets/images/background_login.png") no-repeat;
  background-size: cover;
  background-position: 50%;
  overflow: auto;
}

.btn-login {
  padding: 8px;
  border-radius: 24px;
  background: #2185da;
}

.tab-btn {
  padding: 16px 0;
  cursor: pointer;
  &:hover {
    background-color: #2184da31;
  }

  &:first-child {
    width: 60%;
  }

  &:last-child {
    flex-grow: 1;
  }

  &.active {
    border-bottom: 2px solid #2185da;
  }
}
</style>
