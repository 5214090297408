import { createRouter, createWebHashHistory } from 'vue-router';
import ClientPage from '@/views/client/ClientPage';
import AdminPage from '@/views/admin/AdminPage';
import HomePage from '@/views/client/home/HomePage';
import RegisterPage from '@/views/auth/RegisterPage';
import LoginPage from '@/views/auth/LoginPage';
import PolicyPage from '@/views/policy/PolicyPage';
import PrivacyPage from '@/views/policy/PrivacyPage';
import OrderPage from '@/views/client/order/OrderPage';
import SupportPage from '@/views/client/support/SupportPage';
import AccountPage from '@/views/client/account/AccountPage';
import ChangeProfile from '@/views/client/account/ChangeProfile';
import ChangePassword from '@/views/client/account/ChangePassword';
import RecordPage from '@/views/client/record/RecordPage';

import HomePageAdmin from '@/views/admin/home/HomePage';
import PaymentPage from '@/views/admin/payment/PaymentPage';
import PayoutPage from '@/views/admin/payout/PayoutPage';
import UserPage from '@/views/admin/user/UserPage';
import AdminChatPage from '@/views/admin/chat/ChatPage';
import OrderPageAdmin from '@/views/admin/order/OrderPage';
import FreeProduct from '@/views/admin/free_product/FreeProduct';

import store from '@/store';
import ProductPageVue from '@/views/admin/product/ProductPage.vue';
import ChatComponentVue from '@/views/client/support/ChatComponent.vue';
import WithdrawPageVue from '@/views/client/withdraw/WithdrawPage.vue';
import WithdrawHistoryPageVue from '@/views/client/withdraw/WithdrawHistoryPage.vue';
import DepositHistoryPageVue from '@/views/client/deposit/DepositHistoryPage.vue';
import DepositPage from '@/views/client/deposit/DepositPage';
import TransferPageVue from '@/views/client/deposit/TransferPage.vue';
import ChangeWithdrawPasswordVue from '@/views/client/account/ChangeWithdrawPassword.vue';
import BankAccount from '@/views/client/bank/BankAccountPage';
import ReportPage from '@/views/client/report/ReportPage';
import SharePage from '@/views/client/share/SharePage';
import BankAccountDetailPageVue from '@/views/client/bank/BankAccountDetailPage.vue';
import BannerPageVue from "@/views/admin/banner/BannerPage.vue";
import InquiryPageVue from '@/views/inquiry/InquiryPage.vue';

const routes = [
  {
    path: "/register",
    name: "register",
    component: RegisterPage,
  },
  {
    path: "/login",
    name: "login",
    component: LoginPage,
  },
  {
    path: "/terms-of-use",
    name: "termsOfUse",
    component: PolicyPage,
  },
  {
    path: "/privacy",
    name: "privacy",
    component: PrivacyPage,
  },
  {
    path: "/inquiry",
    name: "inquiry",
    component: InquiryPageVue,
  },
  {
    path: "/app",
    component: ClientPage,
    meta: { requiresAuth: true, title: "MKShop" },
    children: [
      {
        path: "",
        name: "home",
        component: HomePage,
        meta: { requiresAuth: true },
      },
      {
        path: "order",
        name: "order",
        component: OrderPage,
      },
      {
        path: "support",
        name: "support",
        component: SupportPage,
      },
      {
        path: "account",
        name: "account",
        component: AccountPage,
      },
      {
        path: "record",
        name: "record",
        component: RecordPage,
      },
      {
        path: "chat",
        name: "chat",
        component: ChatComponentVue,
      },
      {
        path: "withdraw",
        name: "withdraw",
        component: WithdrawPageVue,
      },
      {
        path: "deposit",
        name: "deposit",
        component: DepositPage,
      },
      {
        path: "transfer",
        name: "transfer",
        component: TransferPageVue,
      },
      {
        path: "withdraw-history",
        name: "withdraw.history",
        component: WithdrawHistoryPageVue,
      },
      {
        path: "deposit-history",
        name: "deposit.history",
        component: DepositHistoryPageVue,
      },
      {
        path: "profile",
        name: "profile",
        component: ChangeProfile,
      },
      {
        path: "update-password",
        name: "updatePassword",
        component: ChangePassword,
      },
      {
        path: "update-withdraw-password",
        name: "updateWithdrawPassword",
        component: ChangeWithdrawPasswordVue,
      },
      {
        path: "bank-account",
        name: "bankAccount",
        component: BankAccount,
      },
      {
        path: "my-bank-account",
        name: "myBankAccount",
        component: BankAccountDetailPageVue,
      },
      {
        path: "reports",
        name: "reports",
        component: ReportPage,
      },
      {
        path: "share",
        name: "share",
        component: SharePage,
      },
    ],
  },
  {
    path: "/admin",
    component: AdminPage,
    meta: { requiresAuth: true, isAdmin: true, title: "MKShop | Admin" },
    children: [
      {
        path: "",
        name: "admin.home",
        component: HomePageAdmin,
      },
      {
        path: "payments",
        name: "admin.payment",
        component: PaymentPage,
      },
      {
        path: "payouts",
        name: "admin.payout",
        component: PayoutPage,
      },
      {
        path: "users",
        name: "admin.user",
        component: UserPage,
      },
      {
        path: "products",
        name: "admin.products",
        component: ProductPageVue,
      },
      {
        path: "chat",
        name: "admin.chat",
        component: AdminChatPage,
      },
      {
        path: "orders",
        name: "admin.orders",
        component: OrderPageAdmin,
      },
      {
        path: "free-product",
        name: "admin.free_product",
        component: FreeProduct,
      },
      {
        path: "banner",
        name: "admin.banner",
        component: BannerPageVue,
      },
    ],
  },
  // Catch-all route for redirection
  {
    path: "/:pathMatch(.*)*",
    redirect: "/app/",
  },
];

const router = createRouter({
	history: createWebHashHistory(),
	routes,
});
// router.afterEach(() => {
//   document.title = `MKShop`;
// });

const publicPages = ['login', 'register', 'termsOfUse', 'privacy', 'inquiry'];

router.beforeEach(async (to, from, next) => {
  if (publicPages.includes(to.name)) {
    next();
  } else {
    const isLoggedIn = await userIsLoggedIn(to);
    const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);

    if (requiresAuth && !isLoggedIn) {
      next({ name: 'login' });
    } else {
      if (isLoggedIn && (to.name == 'login' || to.name == 'register')) {
        next({ name: 'home' });
      } else if (isLoggedIn && !to.meta?.isAdmin && isAdmin() && from.name == 'login') {
        next({ name: 'admin.home' });
      } else if (to.meta?.isAdmin) {
        if (isAdmin()) {
          next();
        } else {
          next({ name: 'home' });
        }
      } else {
        next();
      }
    }
  }
});

async function userIsLoggedIn(to) {
	if (!(to.name == 'login' || to.name == 'register')) {
		await store.dispatch('getUserLogin');
	}
	return localStorage.getItem('access_token') !== null;
}

function isAdmin() {
	return localStorage.getItem('role') === 'ADMIN';
}

export default router;
