<template>
  <Splide
    :options="{ rewind: true, arrows: false, autoplay: true, interval: 2400 }"
    aria-label="Banners Images"
    tag="section"
  >
    <template v-for="(image, index) in banners" :key="index">
      <SplideSlide class="px-2">
        <img class="img-slider" :src="assetUrl + image.path" alt="Sample 1" />
      </SplideSlide>
    </template>
  </Splide>
</template>
<script>
import { Splide, SplideSlide } from "@splidejs/vue-splide";
import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    Splide,
    SplideSlide,
  },
  // data() {
  //   return {
  //     images: [
  //       require("@/assets/images/banners/1.jpg"),
  //       require("@/assets/images/banners/2.jpg"),
  //       require("@/assets/images/banners/3.jpg"),
  //       require("@/assets/images/banners/4.jpg"),
  //       require("@/assets/images/banners/5.jpg"),
  //       require("@/assets/images/banners/6.jpg"),
  //       require("@/assets/images/banners/7.jpg"),
  //       require("@/assets/images/banners/8.jpg"),
  //       require("@/assets/images/banners/9.jpg"),
  //       require("@/assets/images/banners/10.jpg"),
  //     ],
  //   };
  // },
  computed: {
    ...mapGetters(["getBanners"]),
    banners() {
      return this.getBanners || [];
    },
  },
  created() {
    this.fetchBanners();
  },
  methods: {
    ...mapActions(["fetchBanners"]),
  },
};
</script>
<style lang="scss" scoped>
.img-slider {
  width: 100%;
  border-radius: 16px;
  max-height: 244px;
}
</style>
