<template>
  <header>
    <nav class="navbar navbar-expand-lg navbar-light bg-light">
      <div class="container-fluid">
        <router-link to="/admin/" class="navbar-brand logo">
          <img src="@/assets/logo.png" alt="" />
        </router-link>
        <button
          class="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNav"
          aria-controls="navbarNav"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-icon"></span>
        </button>
        <div
          class="collapse navbar-collapse justify-content-between w-100"
          id="navbarNav"
        >
          <ul class="navbar-nav">
            <li class="nav-item border-end">
              <router-link to="/admin/" class="nav-link text-dark">Dashboard</router-link>
            </li>
            <li class="nav-item border-end">
              <router-link to="/admin/users" class="nav-link text-dark">{{
                $t("users")
              }}</router-link>
            </li>
            <li class="nav-item border-end">
              <router-link to="/admin/products" class="nav-link text-dark">{{
                $t("products")
              }}</router-link>
            </li>
            <li class="nav-item border-end">
              <router-link to="/admin/payments" class="nav-link text-dark">
                {{ $t("recharge") }}
              </router-link>
            </li>
            <li class="nav-item border-end">
              <router-link :to="{ name: 'admin.payout' }" class="nav-link text-dark">
                {{ $t("withdraw") }}
              </router-link>
            </li>
            <li class="nav-item border-end">
              <router-link :to="{ name: 'admin.orders' }" class="nav-link text-dark">
                {{ $t("orders") }}
              </router-link>
            </li>
            <li class="nav-item border-end">
              <router-link
                :to="{ name: 'admin.free_product' }"
                class="nav-link text-dark"
              >
                {{ $t("free_products") }}
              </router-link>
            </li>
            <li class="nav-item">
              <router-link :to="{ name: 'admin.banner' }" class="nav-link text-dark">
                Banners
              </router-link>
            </li>
          </ul>
          <div class="d-flex align-items-center">
            <div class="me-3">
              <router-link :to="{ name: 'admin.chat' }">
                <IconMessageCircle2Filled />
              </router-link>
            </div>
            <button class="btn ps-0" @click="logout">
              <IconLogout class="me-1" />
              {{ $t("logout") }}
            </button>
          </div>
        </div>
      </div>
    </nav>
  </header>
</template>
<script>
import { IconLogout, IconMessageCircle2Filled } from "@tabler/icons-vue";
import { mapActions } from "vuex";

export default {
  name: "AdminHeaderComponent",
  computed: {
    currentRouteName() {
      return this.$route.name;
    },
  },
  methods: {
    ...mapActions(["logout"]),
  },
  components: { IconLogout, IconMessageCircle2Filled },
};
</script>
<style lang="scss" scoped>
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.logo {
  width: 55px;
  height: 55px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    object-fit: cover;
  }
}
</style>
