<template>
  <div class="slider-height">
    <Splide :options="options" aria-label="My Favorite Images">
      <template v-for="(income, index) in incomes" :key="index">
        <SplideSlide>
          <div class="d-flex justify-content-between income">
            <div class="d-flex">
              <div class="income-img me-2">
                <img src="@/assets/logo.png" alt="Sample 1" />
              </div>
              <span>{{ income.user_id }}</span>
            </div>
            <div>{{ income.amount }} {{ $t("success_order") }}</div>
          </div>
        </SplideSlide>
      </template>
    </Splide>
  </div>
</template>
<script>
import { Splide, SplideSlide } from "@splidejs/vue-splide";

export default {
  components: {
    Splide,
    SplideSlide,
  },
  setup() {
    const options = {
      rewind: true,
      drag: "free",
      perPage: 6,
      arrows: false,
      pagination: false,
      type: "loop",
      focus: "center",
      direction: "ttb",
      height: 200,
      autoplay: true,
      interval: 1400,
    };

    return {
      options,
    };
  },
  data() {
    return {
      incomes: [],
    };
  },
  beforeMount() {
    const incomes = new Array(100).fill({});
    this.incomes = incomes.map(() => ({
      image: "",
      user_id: this.makeId() + "****" + this.randomIntFromInterval(0, 99),
      amount: this.randomIntFromInterval(5, 120),
    }));
  },
  methods: {
    randomIntFromInterval(min = 500, max = 1000) {
      return Math.floor(Math.random() * (max - min + 1) + min);
    },
    makeId(length = 2) {
      let result = "";
      const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      const charactersLength = characters.length;
      let counter = 0;
      while (counter < length) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
        counter += 1;
      }
      return result;
    },
  },
};
</script>
<style lang="scss" scoped>
.income-img {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.income {
  background-color: #39434d;
  padding: 6px 16px;
  margin: 8px 0;
  border-radius: 14px;
}
</style>
