export default {
  auth: {
    phone_number: "Phone number",
    email: "Email",
  },
  share: "Share",
  share_message: "Please contact with admin to get invite code!",
  share_description:
    "Join my group for half an hour every day. Winning 10,000 per month is just the beginning! Each invitation is a seed of opportunity, a ladder of wealth and freedom. Let's start the journey to get rich!",
  copy: "Copy",
  placeholder: {
    email: "Input your email address",
    phone: "Input your phone",
    password: "Enter your password",
    account_name: "Account name",
    account_number: "Account number",
    receiver: "Receiver",
    bank_name: "Bank name",
    branch: "Branch",
    password_confirm: "Confirm password",
    invite_code: "Invite code",
    invite_code_input: "Enter partner invitation code",
    old_password: "Old password",
    new_password: "New password",
  },
  login: "Login",
  register: "Register",
  close: "Close",
  cancel: "Cancel",
  notification: "Notification",
  welcome_modal_content: `
    On the occasion of Golden Week, the system is implementing a major promotion program for members when registering for a new member account, receiving a $20 bonus.
    Receive a $28 bonus for sponsors when registering for a joint sponsorship account on the system, and enjoy many benefits along with the program. Thank you for accompanying Michael - Kors. !!!
`,
  welcome_content: "Welcome all members to MKShop stock promotion platform...",
  balance: "My balance",
  available_balance: "Available Balance",
  freeze_balance: "Freeze Amount",
  deposit: "Deposit",
  withdraw: "Withdraw",
  products: "Products",
  free_products: "Free Products",
  product: {
    price: "Price",
  },
  invite: "Invite",
  commission_today: "Commission today",
  total_revenue: "Total revenue",
  total_commission: "Total commission",
  team_size: "Team size",
  stay_tuned: "Stay tuned, the team is planning...",
  minium_balance: "Minium balance",
  orders: "Orders",
  success_order: "success orders",
  push: "promote.",
  order: {
    title: "Order",
    amount: "Order Amount",
    return_amount: "Returned Amount",
    submit: "Submit",
    get_commissions: "Get commissions",
    holding: "Holding",
    quantity: "Quantity of order",
    available: "Available",
    available_at: "Available at",
    start: "Start grabbing orders",
    number: "Order Number",
    processing: "Order Processing",
    connecting: "Connecting with partners",
    order_success: "Order Successfully",
    order_failed: "Order Failed",
    order_details: "Order Details",
    limit_reached: "Your are limited to send orders for MICHAEL-Kors today...",
    blocked:
      "Your have to pay ${freeze_amount} freeze amount to get new order!!",
    current_orders: "Current orders",
  },
  commission: "Commission",
  join: "Join",
  you_here: "You are here",
  amount: "Amount",
  daily_income: "Orders are sent to the MICHAEL-KORS",
  platform_introduction: {
    title: "Platform Introduction",
    about_us: "About Us",
    company: "Our Company",
    rule: "Rules",
    proxy: "Proxy",
    policy: "Policy",
  },
  partner: "Partner",
  menu: {
    home: "Home",
    record: "Record",
    support: "Support",
    account: "Account",
    bank_account: "Bank Account",
  },
  status: {
    pending: "Pending",
    success: "Success",
    freeze: "Freeze",
    title: "Status",
  },
  date: "Date",
  service: {
    customer: "Online Customer Service",
    working_time: "Working time",
  },
  recharge: "Recharge",
  history: {
    withdraw: "Withdraw History",
    recharge: "Recharge History",
    trading_code: "Trading code",
  },
  update_info: "Update Information",
  password_change: "Change password",
  password_withdraw_change: "Change withdraw password",
  language: "Language",
  logout: "Logout",
  bank_account: "Bank account",
  team_report: "Team Report",
  update: "Update",
  submit: "Submit",
  advice: "Advice",
  advice_content:
    "Members note : to protect your account please do not give out your password and bank account to anyone . We will not ask you to give your bank password OTP / PIN / 4-digit security code to anyone.",
  recharge_note: "* Once the wallet is approved, you need to login to withdraw",
  instruct: "Instruct",
  recharge_page: {
    note: "You need to enter your customer code",
    note1:
      "Enter the transfer content and please wait for a while for the system to add money to you.",
    note2: "* The content of the transfer is required to have a customer code",
    note3:
      "Or you can use the following QR code to transfer, scan the following code and enter the transfer content as the customer code below.",
  },
  withdraw_page: {
    title: "Withdraw history",
    note1: "Please pay",
    note2: "to be able to make a withdrawal!",
    note3: "* Once the wallet is approved, you need to login to withdraw",
    password: "Withdraw password",
    update_info: "Please update your bank information!",
  },
  report: {
    all_levels: "All Levels",
    level: "Level",
    yesterday: "Yesterday",
    today: "Today",
    this_week: "This Week",
  },
  select_chat: "Select a chat to show",
  search: "Search",
  new: "New",
  users: "Users",
  user: "User",
  name: "Name",
  description: "Description",
  image: "Image",
  password: "Password",
  address: "Address",
  data_popup: {
    about_us: {
      title: "About Us",
      description: `
        Welcome to Michaelkorsshopvn - The paradise of luxury fashion accessories.
        Michaelkorsshopvn takes pride in being the genuine provider of premium fashion accessories from the world-renowned brand Michael Kors. We are committed to delivering a classy shopping experience with impeccable quality products and professional customer service.
        At Michaelkorsshopvn, you can find:
          - Diverse collections: Handbags, leather wallets, watches, jewelry, and various other fashion accessories for both men and women, ranging from classic elegance to modern trends.
          - Genuine products: Michaelkorsshopvn guarantees to offer only 100% genuine Michael Kors products, directly imported from the manufacturer.
          - Perfect quality: Every Michael Kors product is meticulously crafted from the finest materials, ensuring durability, beauty, and luxury.
          - Professional service: Michaelkorsshopvn provides dedicated customer advisory services, flexible product exchange and return policies, and genuine warranty support.
        Michaelkorsshopvn - Where your fashion sophistication is affirmed:
          - Brand prestige: Michael Kors is a premium fashion brand loved by Hollywood stars and the elite worldwide.
          - Luxurious style: Michael Kors products are designed with elegant, exquisite, and modern styles, helping you affirm your fashion sophistication.
          - Perfect quality: Michaelkorsshopvn guarantees to offer only the highest quality genuine Michael Kors products.
        In addition, Michaelkorsshopvn also offers dropshipping services for those who want to do online business with Michael Kors fashion accessories without the need for large capital investment.
        With Michaelkorsshopvn's dropshipping service, you will enjoy many benefits:
          - No-capital selling: You don't need to invest capital to import goods, just upload products to your website or social media.
          - High profits: Michaelkorsshopvn offers attractive discount rates for dropshipping partners.
          - Comprehensive support: Michaelkorsshopvn supports you from uploading products, processing orders, to shipping and customer care.
        Register for Michaelkorsshopvn's dropshipping service today to start your online business with Michael Kors fashion accessories easily and effectively!
        Contact us:
          - Website: https://michael-kors-shopvn.com/
          - Email: support{'@'}michaelkorsshopvn.com
        Michaelkorsshopvn - Where fashion sophistication is honored!
      `
    },
    company: {
      title: "Company Introduction",
      description: `
        MichaelKorsshopvn is proud to be the official provider of authentic premium fashion accessories from the world-renowned brand Michael Kors in Vietnam. We are committed to offering you a classy shopping experience with impeccable quality products and professional customer service.
        At MichaelKorsshopvn, you can find:
          - Diverse collections: Handbags, leather wallets, watches, jewelry, and many other fashion accessories for both men and women, ranging from classic elegant styles to modern youthful designs.
          - Genuine products: MichaelKorsshopvn guarantees to provide only 100% genuine Michael Kors products, directly imported from the manufacturer.
          - Perfect quality: Every Michael Kors product is meticulously crafted from the finest materials, ensuring durability, beauty, and luxury.
          - Professional service: MichaelKorsshopvn provides attentive customer advisory services, flexible product exchange and return policies, and genuine warranty support.
        MichaelKorsshopvn - Where you affirm your fashion sophistication:
          - Brand prestige: Michael Kors is a premium fashion brand loved by Hollywood stars and the elite worldwide.
          - Luxurious style: Michael Kors products are designed with elegant, exquisite, and modern styles, helping you affirm your fashion sophistication.
          - Perfect quality: MichaelKorsshopvn guarantees to offer only the highest quality genuine Michael Kors products.
        In addition, MichaelKorsshopvn also offers dropshipping services for those who want to do online business with Michael Kors fashion accessories without the need for large capital investment.
        With MichaelKorsshopvn's dropshipping service, you will enjoy many benefits:
          - Selling without capital: You don't need to invest capital to import goods, just upload products to your website or social media.
          - High profits: MichaelKorsshopvn offers attractive discount rates for dropshipping partners.
          - Comprehensive support: MichaelKorsshopvn supports you from uploading products, processing orders, to shipping and customer care.
        Register for MichaelKorsshopvn's dropshipping service today to start your online business with Michael Kors fashion accessories easily and effectively!
        Contact us:
          - Website: https://michael-kors-shopvn.com/
          - Email: support{'@'}michaelkorsshopvn.com
      `,
    },
    rule: {
      title: "Introduction to Rules",
      description: `
      Welcome to MichaelKorsshopvn!
      To ensure the best shopping experience for customers, MichaelKorsshopvn applies the following general regulations:
      1. Product regulations:
        - MichaelKorsshopvn commits to providing only 100% genuine Michael Kors products, directly imported from the manufacturer.
        - All products are carefully inspected before delivery to customers.
        - MichaelKorsshopvn provides detailed information about the products, including images, descriptions, materials, sizes, etc.
      2. Price regulations:
        - The prices of products at MichaelKorsshopvn are continuously updated and ensure the most competitive in the market.
        - MichaelKorsshopvn regularly launches promotion programs, discounts, and offers for customers.
      3. Payment regulations:
        - MichaelKorsshopvn supports multiple convenient payment methods for customers, including online payment, cash on delivery (COD), payment by bank card, etc.
      4. Shipping regulations:
        - MichaelKorsshopvn delivers nationwide with fast delivery times.
        - Free shipping for all orders over [amount] dong.
      5. Return and exchange regulations:
        - MichaelKorsshopvn supports returns and exchanges within [number of days] days from the date of receipt.
        - Customers can return or exchange products if they are defective due to the manufacturer or not as described on the website.
      6. Information security regulations:
        - MichaelKorsshopvn commits to protecting customers' personal information in accordance with legal regulations.
      7. Complaint regulations:
        - MichaelKorsshopvn is always ready to receive and resolve any customer complaints quickly and efficiently.
      For more detailed information about Michael Kors shopvn's regulations, please visit the website https://michael-kors-shopvn.com.
      Wishing you a pleasant shopping experience at MichaelKorsshopvn!
      `,
    },
    poxy: {
      title: "Policies",
      description: `
        MichaelKorsshopvn's Authorization Policy
        MichaelKorsshopvn currently implements a sales authorization policy for partners interested in doing business with the premium fashion accessories of the Michael Kors brand.
        Below are some basic information about MichaelKorsshopvn's authorization policy:
        1. Authorized subjects:
          - Enterprises, individuals with sufficient legal capacity and business conditions as prescribed by law.
          - Have experience in business in the high-end fashion field.
          - Have suitable business locations according to the requirements of MichaelKorsshopvn.
          - Have a professional sales team knowledgeable about Michael Kors products.
        2. Benefits of authorized dealers:
          - Enjoy attractive discounts from MichaelKorsshopvn.
          - Receive support in marketing, promotion, and sales training.
          - Participate in promotional programs and offers for dealers.
        3. Responsibilities of authorized dealers:
          - Comply with MichaelKorsshopvn's regulations on prices, products, customer service, etc.
          - Sell products according to MichaelKorsshopvn's commitments regarding product quality and service.
          - Have the responsibility to protect the Michael Kors brand.
        4. Authorization procedures:
          - Interested partners in selling authorization for MichaelKorsshopvn please contact via the website https://michael-kors-shopvn.com/.
          - MichaelKorsshopvn will review the authorization documents and notify the results to partners within [number of days] working days.
        5. Authorization contract:
          - After being approved for authorization, both parties will sign an authorization contract, which specifies the rights and responsibilities of each party.
        6. Authorization period:
          - The authorization contract has a term of [number of years] years and may be extended by agreement of both parties.
        7. Dispute resolution:
          - Any disputes arising during the implementation of the authorization contract will be resolved according to the laws of Vietnam.
        MichaelKorsshopvn believes that the authorization policy will help us expand the market and provide customers with the best shopping experience.
        For more detailed information about MichaelKorsshopvn's authorization policy, please contact us via the website https://michael-kors-shopvn.com/.
        Wishing you success in doing business with MichaelKorsshopvn!
      `
    },
    policy: {
      title: "Privacy Policy",
      description:`
        Privacy Policy of MichaelKorsshopvn
        MichaelKorsshopvn is committed to protecting the privacy rights of customers and complying with Vietnamese laws on personal information protection.
        Here are some basic information about MichaelKorsshopvn's privacy policy:
        1. Types of information collected:
          - MichaelKorsshopvn collects customers' personal information when they register an account, place an order, or contact us.
          - The collected information may include: name, email address, phone number, shipping address, payment information, etc.
        2. Purposes of using customers' personal information:
          - Processing orders and delivering goods.
          - Providing customer service.
          - Sending information about products, services, and promotional programs.
          - Conducting marketing and promotion activities.
          - Analyzing data and improving service quality.
        3. Information sharing:
          - MichaelKorsshopvn commits not to share customers' personal information with any third party without the customer's consent.
          - However, MichaelKorsshopvn may share customers' personal information with third parties in the following cases:
            - When required by law.
            - When necessary to perform services for customers (e.g., sharing information with shipping companies for delivery).
            - When customers agree to share information.
        4. Information security:
          - MichaelKorsshopvn applies appropriate technical and organizational measures to protect customers' personal information from unauthorized access, misuse, or disclosure.
        5. Customers' rights:
          - Customers have the right to access, modify, and delete their personal information.
          - Customers have the right to request MichaelKorsshopvn to stop using their personal information for marketing purposes.
          - Customers have the right to object to MichaelKorsshopvn sharing their personal information with third parties.
        6. Contact:
          - If customers have any questions about MichaelKorsshopvn's privacy policy, please contact us via the website https://michael-kors-shopvn.com/ or hotline.
        MichaelKorsshopvn is committed to protecting customers' privacy rights and complying with Vietnamese laws on personal information protection.
        Wishing you a safe and reliable shopping experience at MichaelKorsshopvn!
        Wishing you success!
      `,
    },
  },
  select_picture: "Select a picture",
  levels: {
    1: "Sliver member",
    2: "Gold member",
    3: "Daimon member",
    4: "Crown member",
    0: "No ranking",
  },
  prize:
    "You are the lucky winner of the single with the biggest % commission this month!!",
  logout_message: "Are you sure you want to log out?",
  yes: "Yes",
  no_data: "NO DATA",
  coming_soon: "COMING SOON",
  chat_with_admin: "Click here to chat with Admin",
  freeze_alert:
    "Complete the order to get <br /> <span class='fw-bold'>${balance}</span><br /> and <br /> <span class='fw-bold text-success'>${profit}</span> profit",
  setting: "Setting",
  delete_message: "Are you sure you want to delete this item?",
  rules: `
    Terms of Use of the Website
    Welcome to Michael Kors Shopvn!
    Thank you for taking the time to visit the official website of MichaelKorsShopvn. Before you start exploring our diverse and stylish fashion world, please take a few minutes to read and understand the terms of use of the website. Accessing, browsing content, or using any images on this website implies that you (the user) agree to comply with these terms. If you do not agree with any terms, please do not use this website.

    1. GENERAL PROVISIONS
    The Terms of Use govern the terms binding you when using the Services. The terms "You" and/or "User" herein shall collectively refer to those who use the Services. Please study carefully and retain a copy of these Terms of Use.
    By visiting the website michaelkorsshopvn.com and using the Services, You agree to be bound by these Terms of Use, our Advertising Policy, and Privacy Policy.
    We enforce these Terms of Use, Advertising Policy, and Privacy Policy in accordance with current laws and no content in these materials impedes our compliance with government regulations or requests from governmental authorities, courts, enforcement agencies regarding your use of the Services or information we receive or obtain from your use of the Services.

    2. INTELLECTUAL PROPERTY RIGHTS
    All content on the Website, including text, images, videos, software, etc., are the intellectual property rights of the Company or licensed to the Company by third parties. You are not allowed to copy, modify, distribute, transmit, publicly display, or use any content on this Website for commercial purposes without the written consent of the Company.

    3. DISCLAIMER OF WARRANTIES
    The website michaelkorsshopvn.com and the Services are provided on a "as is" basis, based on available services and features, and We do not guarantee that the features on the website michaelkorsshopvn.com or the Services will meet your requirements or ensure that the operation of the software or the Services will not be interrupted or error-free.
    We are not responsible for any loss of data, loss of benefits, or other issues related to accessing the website michaelkorsshopvn.com and using the Services, including but not limited to all information, data, text, software, music, sound, images, graphics, videos, messages, or other materials ("Content") that You may store, upload, or transmit through the Services. You agree to use the Services at your own risk.

    4. WEBSITE USAGE
    Shopping: You can purchase items on the Website by selecting products, adding them to your cart, and making payment. The Company will process your order and deliver it to the address you provide.
    Dropshipping: You can sign up to use the Company's dropshipping service to sell the Company's products on your own website. The Company will process orders and deliver them to your customers.
    Restrictions: You are not allowed to use this Website for any unlawful or prohibited purpose. You are also not allowed to use this Website to:
    - Send or transmit any illegal, pornographic, or offensive content.
    - Use any software or program that may harm the Website or the computers of other users.
    - Collect or store personal information of other users without their consent.

    5. LINKS TO OTHER WEBSITES
    This website may contain links to other websites that are not owned or controlled by the Company. The Company is not responsible for the content or privacy of other websites.

    6. Privacy Rights
    The Company is committed to protecting your privacy. Please refer to our Privacy Policy for more information on how we collect, use, and protect your personal information.

    7. LIMITATION OF LIABILITY
    The Company is not liable for any damages arising from the use of this Website, including but not limited to damages caused by errors, viruses, or service interruptions.
    The Company is not responsible for any damages arising from the use of the dropshipping service, including but not limited to damages caused by product defects, delayed delivery, or lost items.
    The Company is not responsible for any damages caused by your violation of these Terms.

    8. CHANGES TO TERMS OF USE
    We reserve the right to change and/or modify any terms in these Terms of Use at any time without prior notice. Such changes and/or modifications will be effective immediately upon posting on the website michaelkorsshopvn.com. If You continue to access michaelkorsshopvn.com or use the Services after such changes and/or modifications are posted, You have accepted and agreed to those changes and/or modifications.

    9. REGISTRATION, ACCOUNT USAGE, AND SERVICE FEES PAYMENT TERMS
    We provide services to You entirely through the online system of the Website michaelkorsshopvn.com from the time You start registering for an account, choosing services, and making payment for the service fees that You use our Services.
    You confirm and agree that You have carefully studied and will fully comply with the registration and service fee calculation methods and payment of fees posted on our Website michaelkorsshopvn.com (“Registration and Service Usage Terms”). By completing the account registration process on the website michaelkorsshopvn.com, You have accepted to be bound by such Registration and Service Usage Terms when using the Services.
    We always change and/or modify the content of these Terms of Use as well as the Registration and Service Usage Terms to best improve the content of the provisions and maximize convenience and efficiency when You use the Services as well as ensure the maximum benefits of Our services.
    We reserve the right but have no obligation to notify You of any changes and/or modifications to the Registration and Service Usage Terms. Such changes and/or modifications will be effective immediately upon being posted on the Website michaelkorsvn.com. If You continue to use the Services after such changes and/or modifications are posted, You have accepted and agreed to those changes and/or modifications. We recommend that You regularly check the Website michaelkorsshopvn.com and contact our support staff for the latest updated Registration and Service Usage Terms.

    10. LIMITATION OF LIABILITY
    You acknowledge and agree that we are only willing to provide the Services if You agree to limit Our liability to You and third parties. You agree that You are solely responsible for and shall fully indemnify and keep us and our affiliates and each employee, director, employee, agent, representative, information provider, and licensor of us harmless from any claims, actions, costs, losses, damages, judgments, and expenses, including but not limited to attorneys' fees, damages, litigation costs, late payment interest related to or arising from any claims, disputes, legal proceedings in Court or Arbitration organizations, decisions of competent authorities … related to or arising from products and services. We reserve the right, at Our expense, to assume full responsibility for and control (but not the obligation) of any such issues depending on Your indemnification.

    11. OTHER TERMS
    Governing Law
    These Terms of Use are governed by and interpreted in accordance with Vietnamese law. If any term in these Terms of Use is illegal, invalid, or for any reason unenforceable, such term shall be deemed separate from these Terms of Use and shall not affect the validity and enforceability of any remaining terms of these Terms of Use.
    Termination
    These Terms of Use are effective until terminated by You or by us under the following circumstances:
    Termination by You: You may terminate these Terms of Use by not using the Services anymore.
    Termination by us: These Terms of Use terminate immediately without prior notice if (i) at our discretion, You fail to comply with any term of these Terms of Use or (ii) Law or a government decision, court order, enforcement agency requires us to terminate these Terms of Use.
    Contact
    If You have any questions about these Terms of Use, the operation of the Services, or your connection with us, please contact us via email: info{'@'}michaelkorsshopvn.com. All issues or disputes will be resolved promptly and reasonably.
  `,
  privacy_policy: `
    INTRODUCTION
    1.1. Welcome to the MKShop APPLICATION (including the website and mobile app) operated by MKShop Investment and Technology Joint Stock Company. MKShop is committed to upholding its responsibilities regarding information security in accordance with the personal data protection laws of Vietnam ("Privacy Law"). We respect the privacy and interests of all MKShop users in our services. We acknowledge the importance of the personal data you entrust to us and believe that we have a responsibility to manage, protect, and process your personal data appropriately. This Privacy Policy ("Privacy Policy" or "Policy") is designed to help you understand how we collect, use, disclose, and/or process the personal data you provide to us and/or that we store about you, whether currently or in the future. It is also intended to help you make informed decisions before providing any of your personal data.
    1.2. "Personal Data" means data, whether accurate or not, about an individual from which that individual's identity can be ascertained or from that data and other information to which an organization has or is likely to have access. Common examples of personal data may include names, identity card numbers, and contact information.

    1.3. By using the Services, registering an account with us, or accessing the Platform, you confirm and agree that you accept the methods, requirements, and/or policies described in this Privacy Policy, and hereby consent to us collecting, using, disclosing, and/or processing your personal data as described herein. IF YOU DO NOT AGREE TO ALLOW US TO PROCESS YOUR PERSONAL DATA AS DESCRIBED IN THIS POLICY, PLEASE DO NOT USE OUR SERVICES OR ACCESS OUR PLATFORM OR WEBSITE. If we change our Privacy Policy, we will post those changes or amended Privacy Policy on our Platform. We reserve the right to modify and supplement this Privacy Policy at any time.

    2. WHAT DATA WILL MKShop COLLECT?
    2.1. MKShop may collect, but is not limited to, the following:
    a. Full name;
    b. Email address;
    c. Billing address;
    d. Phone number;
    e. Information sent by or related to the device(s) used to access the Services or our Platform.
    f. Any other information about users when users log in to use the Services or Platform, and when users use the Services or Platform, as well as information about how users use our Services or Platform.
    2.2. You agree not to provide us with any inaccurate or misleading information and agree to inform us of any inaccuracies or changes in information. We reserve the right, at our discretion, to request any necessary documentation to verify the information you provide.

    2.3. If you do not wish us to collect the aforementioned personal information/data, you may opt out at any time by providing written notice to our Data Protection Officer. You can find more information in the "How can you withdraw consent, access or correct your information?" section below. However, please note that refusing or withdrawing consent for us to collect, use, or process your personal data may affect your use of the Services and Platform. For example, location-based services will not function if you do not allow the application to access your location.

    3. HOW DO WE USE THE INFORMATION YOU PROVIDE TO US?
    3.1. We may collect, use, disclose, and/or process your personal data for one or more of the following purposes:
    a. To review and/or process your registration/transactions with us or your transactions or correspondence with third parties through the Services;
    b. To manage, operate, provide, and/or manage your use and/or access to our Services and Platforms (including, but not limited to, your preferences), as well as your relationship and user account with us;
    c. To respond to, process, resolve, or complete a transaction and/or meet your requests for specific products and services, and to notify you of service issues and abnormal account activities;
    d. To enforce our Terms of Service or any applicable end-user license agreements;
    e. To protect personal safety and the rights, property, or safety of others;
    f. To identify and/or verify;
    g. To maintain and manage any software updates and other updates and support that may be required from time to ensure the smooth operation of our Services;
    h. To resolve or create customer service conditions, carry out your instructions, resolve or respond to any queries sent by (or to be sent by) you or on your behalf;
    i. To contact you or communicate with you by telephone, text message, fax, email, and/or mail, or by other means for the purpose of managing and/or managing your relationship with us or your use of our Services, including but not limited to conveying administrative information to you about our Services. You confirm and agree that our contact with you may be done by sending letters through the mail, documents or notifications to you, which may include certain personal data about you in order to provide such documents as well as on the packaging/envelope;
    j. To allow other users to interact with or contact you through the Platform, including notifying you when another user has sent you a private message or posted a review for you on the Platform;
    k. To conduct research, analysis, and development activities (including, but not limited to, data analysis, surveys, development, and/or product and service feature development) to analyze how you use our Services, to improve our Services or products, and/or to enhance your customer experience.

    4.HOW DOES MKShop PROTECT AND STORE CUSTOMER INFORMATION?
    4.1. We implement various security measures and always strive to ensure the security of your personal data on our systems. User personal data is stored behind security networks and can only be accessed by certain employees with special access rights to our systems. However, there is no absolute security guarantee.

    4.2. We will retain personal data in compliance with Privacy Law and/or other relevant current laws. This means that we will delete or erase identity information from your personal data upon receiving your appropriate request according to the provisions of current Privacy Law or when we have reasonable grounds to determine that (i) the storage of such personal data no longer serves the purpose of collecting that personal data; (ii) the retention is no longer necessary for any legitimate purpose or business purpose, and (iii) there are no other legitimate interests to continue to retain that personal data. If you cease using our Platform or your rights to use the Platform and/or Services are terminated or cancelled, we may continue to store, use, and/or disclose your personal data in accordance with this Privacy Policy and our legal obligations under the provisions of privacy laws. Depending on the provisions of the law, we may securely destroy personal data without prior notice to you.

    4.3. We reserve the right to refuse to delete or destroy data in cases where your request is not in compliance with the Privacy Law or, even if it is in compliance, it falls within the cases where we are not allowed to delete or destroy personal data according to the provisions of the current Privacy Law.

    5.DOES MKShop DISCLOSE INFORMATION COLLECTED FROM USERS OR NOT?
    5.1. During the course of conducting our business activities, we may need to disclose your personal data to third-party service providers, agents, and/or our affiliated or related companies, and/or other third parties, for one or more of the Purposes mentioned above. These third-party service providers, agents, and/or affiliated or related companies, and/or other third parties will process your personal data on our behalf or on their own, for one or more of the Purposes mentioned above. Such third parties include, but are not limited to:
    a. Our subsidiary companies, affiliates, and related companies;
    b. Contractors, agents, service providers, and other third parties that we hire to support or supplement our business operations. These may include, but are not limited to, companies providing administrative services or other services for us, such as postal companies, telecommunications companies, information technology companies, e-commerce organizations, and data centers;
    c. Buyers or other successors in the event of a merger, divestiture, restructuring, reorganization, dissolution, or sale or transfer of all or part of MKShop's assets, whether ongoing or in bankruptcy, liquidation, or similar proceedings, where personal data MKShop holds about our users is among the assets transferred, or to a business partner in a business asset transaction in which MKShop or any of its affiliates or related companies is involved;
    d. Third parties to whom we disclose information for one of the Purposes, and those third parties may, in turn, collect and process your personal data for one or more Purposes.
    5.2. In this case, it may be necessary, among other things, to share statistical and demographic information about Users as well as their use of User Services with advertising service providers and programmers. We will also share statistical and demographic information about our users and their use of the Services with advertising service providers and programmers. This will not include any information that could be used to identify your specific identity or disclose personal information about you.
    5.3. For the avoidance of doubt, in cases where legal regulations on the protection of personal data or other existing laws allow an organization such as ours to collect, use, or disclose your personal data without your consent, the legal consent will continue to apply.
    5.4. Third parties may intercept or unlawfully access personal data sent to or present on the website, technologies may not work accurately or as expected, or someone may access, misuse, or misuse information without our fault. However, we will implement reasonable security measures to protect your personal data in accordance with the legal regulations on the protection of personal data; however, absolute security cannot be guaranteed, such as, but not limited to, when unauthorized disclosure arises from malicious or complex hacking activities or harmful contents that are not our fault.

    INFORMATION COLLECTED BY THIRD PARTIES
    6.1. Our platform uses Google Analytics, a web analysis service provided by Google, Inc. ("Google"). Google Analytics uses cookies, which are text files on your device, to help our platform analyze how users use our platform. Information created by cookies about your use of the platform (including your IP address) will be sent to and stored by Google on servers in the United States. Google will use this information to evaluate your use of our platform, compile reports on website activity for website operators, and provide other services related to website activity and internet usage. Google may also send this information to third parties as required by law, or in cases where those third parties process information on behalf of Google. Google will not associate your IP address with any other data held by Google.
    6.2. We and third parties may, from time to time, provide software application downloads for your use by the platform or through the Services. These applications may access and allow a third party to view your identification information, such as your name, your user ID, your device's IP address, or other information such as the game you are playing in any specific access session, and any cookies that you may have installed or that have been installed for you by a third-party software application or website. In addition, these applications may require you to provide additional information directly to third parties. The products or services of third parties provided through these applications are not owned or controlled by MKShop. You should read the terms and other policies published by these third parties on their websites or elsewhere.

    DISCLAIMER OF RESPONSIBILITY FOR DATA SECURITY AND THIRD-PARTY WEBSITES
    7.1. WE DO NOT GUARANTEE THE SECURITY OF YOUR PERSONAL DATA AND/OR OTHER INFORMATION THAT YOU PROVIDE ON THIRD-PARTY WEBSITES. We employ various security measures to maintain the security of your personal data that we hold or control. Your personal data is stored behind secure networks and can only be accessed by a limited number of individuals with special access rights to our systems, and they are required to secure that personal data. When you place an order or access your personal data, we recommend using a secure server. All personal data or personal information you provide will be encrypted into our databases to be accessed as described above.
    7.2. To provide you with added value, we may select different third-party websites, applications, or services to link to and frame within the platform. We may also engage in co-marketing and other relationships to provide e-commerce services and other services and features to visitors. These linked websites have their own privacy policies and independent security measures. Even if that third party links to us, we do not control these linked websites/applications/services, and each of them has separate privacy protection and data collection methods independent of us. Data collected by our co-marketing partners or third-party websites/applications/services (even if provided on or through our platform) may not be accessible and/or retained by us.
    7.3. Therefore, we are not responsible or legally responsible for the content, security measures (or the lack of security measures), and activities of these linked websites/applications/services. These linked websites/applications/services are provided for your convenience, and you are solely responsible for accessing them. However, we try to protect the integrity of our platform and the links on each of those websites, and we welcome feedback on these linked websites (including if a specific website is not working).`,
  terms_of_use: "Terms of Use",
  rules_title: "Rules",
  privacy_title: "Privacy Policy",
  delete: "Delete",
  contact_info: "Contact Info"
};
