<template>
  <div class="p-3">
    <h2 class="mb-3">{{ $t("recharge") }}</h2>
    <div class="mb-3 row justify-content-between align-items-center">
      <div class="col d-flex">
        <label for="search" class="col-sm-2 col-form-label">{{ $t("search") }}:</label>
        <input type="text" class="form-control" id="search" v-model="searchValue" />
      </div>
    </div>
    <div>
      <EasyDataTable
        :headers="headers"
        :items="histories"
        @click-row="showRow"
        :search-field="searchFields"
        :search-value="searchValue"
        buttons-pagination
      >
        <template #item-status="{ status }">
          <BadgeComponent :status="status" />
        </template>
        <template #item-user_email="{ user }">
          {{ user?.email }}
        </template>
        <template #item-user_name="{ user }">
          {{ user?.name }}
        </template>
        <template #item-user_uuid="{ user }">
          {{ user?.uuid }}
        </template>
      </EasyDataTable>
    </div>
    <ModalComponent
      :submit-action="handleUpdatePaymentStatus"
      :title="$t('recharge')"
      :modal-id="modalId"
    >
      <template v-slot:modal-body>
        <div v-if="selectedHistory">
          <div class="mb-3">
            <label for="name" class="form-label">{{ $t("user") }}</label>
            <input
              type="text"
              class="form-control"
              id="name"
              readonly
              v-model="selectedHistory.user.name"
            />
          </div>
          <div class="mb-3">
            <label for="email" class="form-label">Email</label>
            <input
              type="text"
              class="form-control"
              id="email"
              readonly
              v-model="selectedHistory.user.email"
            />
          </div>
          <div class="mb-3">
            <label for="amount" class="form-label">{{ $t("amount") }}</label>
            <input
              type="text"
              class="form-control"
              id="amount"
              readonly
              v-model="selectedHistory.amount"
            />
          </div>
          <div class="mb-3">
            <div class="form-check">
              <input
                class="form-check-input"
                type="radio"
                name="status"
                id="pending"
                v-model="selectedHistory.status"
                :value="constants.PAYMENT_STATUS.PENDING"
              />
              <label class="badge bg-warning text-dark" for="pending">
                {{ $t("status.pending") }}
              </label>
            </div>
            <div class="form-check">
              <input
                class="form-check-input"
                type="radio"
                name="status"
                id="success"
                v-model="selectedHistory.status"
                :value="constants.PAYMENT_STATUS.SUCCESS"
              />
              <label class="badge bg-success" for="success">
                {{ $t("status.success") }}
              </label>
            </div>
            <div class="form-check">
              <input
                class="form-check-input"
                type="radio"
                name="status"
                id="failed"
                v-model="selectedHistory.status"
                :value="constants.PAYMENT_STATUS.REJECTED"
              />
              <label class="badge bg-danger" for="failed">
                {{ $t("status.freeze") }}
              </label>
            </div>
          </div>
        </div>
      </template>
    </ModalComponent>
  </div>
</template>
<script>
import BadgeComponent from "@/components/Home/BadgeComponent.vue";
import ModalComponent from "@/components/ModalComponent.vue";
import constants from "@/constants";
import emitter from "@/mitt";
import { mapActions, mapGetters } from "vuex";

export default {
  setup() {
    const searchFields = ["id", "user.name", "user.email", "amount"];

    const modalId = "Payment";
    return {
      searchFields,
      modalId,
      constants,
    };
  },
  computed: {
    ...mapGetters(["getDataDepositHistories", "getUpdatedData"]),
    histories() {
      return this.getDataDepositHistories;
    },
    updatedData() {
      return this.getUpdatedData;
    },
    headers() {
      return [
        { text: "ID", value: "id" },
        { text: "UUID", value: "user_uuid" },
        { text: this.$t("user"), value: "user_name" },
        { text: "Email", value: "user_email" },
        { text: "Code", value: "code" },
        { text: this.$t("amount"), value: "amount" },
        { text: this.$t("status.title"), value: "status" },
      ];
    },
  },
  beforeCreate() {},
  mounted() {
    this.getDepositHistory();
  },
  data() {
    return {
      searchValue: "",
      selectedHistory: null,
    };
  },
  methods: {
    ...mapActions(["getDepositHistory", "updatePaymentStatus"]),
    showRow(item) {
      this.selectedHistory = { ...item };
      emitter.emit(`show-modal${this.modalId}`);
    },
    //   showCreateModal() {
    //     this.selectedHistory = {};
    //     emitter.emit(`show-modal${this.modalId}`);
    //   },
    handleUpdatePaymentStatus() {
      this.updatePaymentStatus({
        id: this.selectedHistory.id,
        status: this.selectedHistory.status,
        type: "deposit",
      });
    },
  },
  components: { ModalComponent, BadgeComponent },
  watch: {
    updatedData(newVal) {
      if (newVal) {
        this.getDepositHistory();
        emitter.emit(`close-modal${this.modalId}`);
        this.selectedHistory = null;
      }
    },
  },
};
</script>
<style lang="scss" scoped></style>
