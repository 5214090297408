<template>
  <Loading :active="loading" :z-index="9999" :can-cancel="false" :is-full-page="true" :background-color="'#646464'" :color="'#FF4B12'" />
</template>
<script>
import Loading from "vue3-loading-overlay";
import "vue3-loading-overlay/dist/vue3-loading-overlay.css";

export default {
  components: {
    Loading
  },
  props: {
    loading: {
      type: Boolean,
      default: false
    }
  },
};
</script>
<style lang="scss" scoped></style>
