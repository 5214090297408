<template>
  <span
    v-if="status == constants.PAYMENT_STATUS.PENDING"
    class="badge rounded-pill bg-warning text-dark"
  >
    {{ $t("status.pending") }}
  </span>
  <span
    v-else-if="status == constants.PAYMENT_STATUS.SUCCESS"
    class="badge rounded-pill bg-success"
  >
    {{ $t("status.success") }}
  </span>
  <span v-else class="badge rounded-pill bg-danger"> {{ $t("status.freeze") }} </span>
</template>
<script>
import constants from "@/constants";

export default {
  props: {
    status: {
      type: Number,
      required: true,
    },
    rejectLabel: {
      type: String,
      default: "Từ chối",
    },
  },
  setup() {
    return {
      constants,
    };
  },
};
</script>
<style lang="scss" scoped></style>
