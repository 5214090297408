<template>
  <div class="p-3">
    <h2 class="mb-3">Banner</h2>
    <div class="mb-3 row justify-content-between align-items-center">
      <div class="col d-flex justify-content-end">
        <button
          class="btn btn-danger me-3"
          @click="showDeleteModal"
          v-if="itemsSelected.length > 0"
        >
          {{ $t("delete") }}
        </button>
        <button class="btn btn-info" @click="showCreateModal">
          {{ $t("new") }}
        </button>
      </div>
    </div>
    <div>
      <EasyDataTable
        :headers="headers"
        :items="banners"
        buttons-pagination
        v-model:items-selected="itemsSelected"
      >
        <template #item-path="{ path }">
          <div class="product-img border">
            <img :src="assetUrl + path" />
          </div>
        </template>
        <template #item-setting="item">
          <div class="py-1 d-flex justify-content-center">
            <button class="btn btn-primary p-1 me-1" @click="showRow(item)">
              <IconInfoSquare />
            </button>
            <button class="btn btn-danger p-1" @click="showDeleteModal([item])">
              <IconTrash />
            </button>
          </div>
        </template>
      </EasyDataTable>
    </div>
  </div>
  <ModalComponent
    :submit-action="createImages"
    title="Banner"
    :modal-id="modalId"
    :customClassDialog="['modal-lg']"
  >
    <template v-slot:modal-body>
      <div class="mb-3">
        <input
          class="form-control"
          type="file"
          id="formFile"
          multiple
          accept="image/*"
          @change="onFileChange"
        />
      </div>
      <div v-for="(image, key) in images" :key="key">
        <div class="mt-3 d-flex justify-content-center flex-column">
          <img class="preview" :ref="'image'" />
          <span class="mt-2 text-center">
            {{ image.name }}
          </span>
        </div>
      </div>
    </template>
  </ModalComponent>
  <ModalComponent :submit-action="deleteImages" title="Banner" :modal-id="modalDeleteId">
    <template v-slot:modal-body>
      <p class="text-center">{{ $t("delete_message") }}</p>
    </template>
  </ModalComponent>
  <loading-component :active="loading" />
  <VueEasyLightbox
    :visible="visible"
    :imgs="bannersPaths"
    @hide="onHide"
    :index="currentIndex"
  />
</template>
<script>
import ModalComponent from "@/components/ModalComponent.vue";
import emitter from "@/mitt";
import { IconTrash } from "@tabler/icons-vue";
import VueEasyLightbox from "vue-easy-lightbox";
import { IconInfoSquare } from "@tabler/icons-vue";
import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    ModalComponent,
    IconTrash,
    IconInfoSquare,
    VueEasyLightbox,
  },
  setup() {
    const modalId = "Banner";
    const modalDeleteId = "BannerDelete";
    return {
      modalId,
      modalDeleteId,
    };
  },
  computed: {
    ...mapGetters(["getBanners", "getLoading", "getUpdatedData"]),
    banners() {
      return this.getBanners || [];
    },
    bannersPaths() {
      return this.banners.map((banner) => this.assetUrl + banner.path);
    },
    loading() {
      return this.getLoading;
    },
    headers() {
      return [
        { text: "ID", value: "id" },
        { text: this.$t("image"), value: "path" },
        { text: this.$t("setting"), value: "setting" },
      ];
    },
    updatedData() {
      return this.getUpdatedData;
    },
  },
  mounted() {
    this.fetchBanners();
  },
  data() {
    return {
      selectedProduct: null,
      searchValue: "",
      images: [],
      itemsSelected: [],
      visible: false,
      currentIndex: 0,
    };
  },
  methods: {
    ...mapActions(["fetchBanners", "createBannerImages", "deleteBannerImages"]),
    onHide() {
      this.visible = false;
    },
    showRow(item) {
      this.currentIndex = this.bannersPaths.indexOf(this.assetUrl + item.path);
      this.visible = true;
    },
    showDeleteModal(images) {
      if (images.length > 0) {
        this.itemsSelected = images;
      }
      emitter.emit(`show-modal${this.modalDeleteId}`);
    },
    deleteImages() {
      this.deleteBannerImages(this.itemsSelected.map((item) => item.id));
    },
    createImages() {
      if (this.images.length == 0) {
        emitter.emit(`close-modal${this.modalId}`);
        return;
      }
      const form = new FormData();
      this.images.forEach((image) => {
        form.append("images", image);
      });
      this.createBannerImages(form);
    },

    showCreateModal() {
      emitter.emit(`show-modal${this.modalId}`);
    },
    onFileChange(e) {
      const selectedFiles = e.target.files;
      for (let i = 0; i < selectedFiles.length; i++) {
        this.images.push(selectedFiles[i]);
      }

      for (let i = 0; i < this.images.length; i++) {
        let reader = new FileReader();
        reader.onload = () => {
          this.$refs.image[i].src = reader.result;
        };

        reader.readAsDataURL(this.images[i]);
      }
    },
  },

  watch: {
    updatedData(newVal) {
      if (newVal) {
        this.fetchBanners();
        this.itemsSelected = [];
        emitter.emit(`close-modal${this.modalId}`);
        emitter.emit(`close-modal${this.modalDeleteId}`);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.product-img {
  width: 200px;
  height: 160px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;

  &.lg {
    width: 150px;
    height: 150px;
  }

  img {
    object-fit: cover;
  }
}
</style>
