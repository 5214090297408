<template>
  <header class="header">
    <div></div>
    <h2 class="py-2">{{ getRouterName(currentRouteName) }}</h2>
    <div></div>
  </header>
</template>
<script>
export default {
  name: "HeaderComponent",
  computed: {
    currentRouteName() {
      return this.$route.name;
    },
  },
  methods: {
    getRouterName(routerName) {
      const names = {
        account: this.$t("menu.account"),
        support: this.$t("menu.support"),
        order: this.$t("orders"),
        record: this.$t("menu.record"),
        home: "MKShop",
      };

      return names[routerName] || "MKShop";
    },
  },
};
</script>
<style lang="scss" scoped>
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
</style>
