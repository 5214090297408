<template>
  <div class="px-3">
    <div class="w-50 mx-auto py-5">
      <img src="@/assets/images/service5.png" alt="" class="w-100" />
    </div>
    <div>
      <p>{{ $t("service.customer") }}</p>
      <router-link :to="{ name: 'chat' }" class="card support-card nav-link">
        <div class="card-body">
          <p class="mb-0">{{ $t("service.customer") }}</p>
          <p class="mb-0">{{ $t("service.working_time") }}:</p>
          <p>(GMT+7): 00:00:00 ~ 23:59:00</p>
          <div class="support-card-image">
            <p class="fw-bold fs-4 text-danger lh-sm">
              <!-- {{ $t('chat_with_admin') }} -->
            </p>
          </div>
        </div>
      </router-link>
    </div>
  </div>
</template>
<script>
export default {
  computed: {},

  data() {
    return {};
  },
  methods: {},
};
</script>
<style lang="scss" scoped>
.support-card {
  background: #3c4854;
  max-width: 260px;
  border-radius: 16px;

  .support-card-image {
    margin: auto;
    background-image: url("@/assets/images/support.jpg");
    width: 220px;
    height: 180px;
    background-size: cover;
    background-repeat: no-repeat;
    border-radius: 16px;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    text-align: center;
    // color: #3c4854;
  }
}
</style>
