import { createStore } from 'vuex';
import state from './state';
import mutations from './mutations';
import actions from './actions';
import getters from './getters';
// Create a new store instance.
const store = createStore({
	state,
	// mutation using to set state
	mutations,

	// actions to be executed using to call api example
	actions,

	// getters to get data from the state
	getters,
});

export default store;
