<template>
  <div class="card">
    <div class="card-body">
      <div>
        {{ $t("balance") }}
        <br />
        <span class="balance">$ {{ user?.balance }}</span>
      </div>
      <router-link
        to="/app/support"
        class="d-flex justify-content-center align-items-center"
      >
        <div class="support-icon text-primary">
          <img src="@/assets/icons/chat.png" alt="chat" />
        </div>
      </router-link>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";

export default {
  components: {},
  computed: {
    ...mapGetters(["getUserData"]),
    user() {
      return this.getUserData;
    },
  },
};
</script>
<style lang="scss" scoped>
.card {
  border: 0;
  overflow: hidden;
  background: rgba($color: #21262a, $alpha: 0.5);
}

.card-body {
  display: flex;
  border-radius: 8px;
  justify-content: space-between;
}

.balance {
  font-size: 32px;
  font-weight: bold;
}

.support-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  border-radius: 50%;
  font-size: 24px;
  width: 58px;
  height: 58px;

  img {
    width: 48px;
  }
}
</style>
