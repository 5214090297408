<template>
  <div class="d-flex flex-column">
    <div class="d-flex justify-content-center fs-4 position-relative py-2">
      <button @click="$router.go(-1)" class="btn text-white btn-back">
        <IconChevronLeft />
      </button>
      <div>{{ $t("withdraw") }}</div>
    </div>
  </div>

  <div class="px-3 py-5" v-if="Number(user.freeze_balance) > 0">
    <h4 class="text-center py-5">
      {{ $t("withdraw_page.note1") }}
      <br />
      <span class="text-danger my-3">${{ user.freeze_balance }}</span>
      <br />
      {{ $t("withdraw_page.note2") }}
    </h4>
  </div>
  <div class="px-3" v-else-if="user.bank_accounts?.length">
    <div class="card account-card mb-3">
      <div class="ms-3">
        <div>{{ $t("balance") }}:</div>
      </div>
      <div class="fs-3 fw-bold text-nowrap text-center w-100">$ {{ user?.balance }}</div>
      <div>{{ $t("placeholder.account_name") }}: {{ user?.name }}</div>
      <div>{{ $t("auth.phone_number") }}: {{ user?.phone }}</div>
      <small class="text-black-50">
        {{ $t("withdraw_page.note3") }}
      </small>
    </div>
    <div>
      <div class="mb-3">
        <label for="amount" class="form-label">{{ $t("amount") }}</label>
        <input
          type="number"
          class="form-control"
          v-model="amount"
          id="amount"
          :placeholder="$t('amount')"
        />
      </div>
      <div class="mb-3">
        <label for="password" class="form-label">{{
          $t("withdraw_page.password")
        }}</label>
        <input
          type="password"
          v-model="password"
          class="form-control"
          id="password"
          :placeholder="$t('withdraw_page.password')"
        />
      </div>
      <div class="text-center pb-4">
        <button
          type="button"
          class="btn btn-system w-75 text-center"
          @click="withdraw(amount)"
        >
          {{ $t("submit") }}
        </button>
      </div>
    </div>
  </div>
  <div class="px-3 py-5" v-else>
    <h4 class="text-center py-5">{{ $t("withdraw_page.update_info") }}</h4>
  </div>
</template>
<script>
import { IconChevronLeft } from "@tabler/icons-vue";
import { mapActions, mapGetters } from "vuex";
import routes from "@/routes";

export default {
  components: { IconChevronLeft },
  computed: {
    ...mapGetters(["getUserData", "getUpdatedData"]),
    user() {
      return this.getUserData;
    },
    updatedData() {
      return this.getUpdatedData;
    },
  },
  data() {
    return {
      amount: null,
      password: null,
    };
  },
  methods: {
    ...mapActions(["createPayment"]),
    withdraw() {
      this.createPayment({
        amount: this.amount,
        password: this.password,
        paymentType: "withdraw",
      });
    },
  },
  watch: {
    updatedData(newVal) {
      if (newVal) {
        routes.push({ name: "withdraw.history" });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.account-card {
  border-radius: 12px;
  background-image: url("@/assets/images/account/account_bg.png");
  background-color: transparent;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  border: 0;
  padding: 16px;
}

.form-control {
  background: #424f5c;
  padding: 8px 16px;
  color: white;
  border-radius: 30px;
}
</style>
