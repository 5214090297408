<template>
  <div class="d-flex flex-column">
    <div class="d-flex justify-content-center fs-4 position-relative py-2">
      <button @click="$router.go(-1)" class="btn text-white btn-back">
        <IconChevronLeft />
      </button>
      <div>{{ $t("menu.account") }}</div>
    </div>
  </div>
  <div class="px-3 mt-5">
    <div class="card account-card">
      <div class="d-flex justify-content-center align-items-center">
        <div class="avatar">
          <img src="@/assets/logo.png" alt="" />
        </div>
      </div>
      <div class="mt-3">
        <div>Uid: {{ user?.id }}</div>
        <div>{{ $t("placeholder.account_name") }}: {{ user?.name }}</div>
        <div>{{ $t("auth.phone_number") }}: {{ user?.phone }}</div>
        <div>{{ $t("auth.email") }}: {{ user?.email }}</div>
        <div>{{ $t("placeholder.invite_code") }}: {{ user?.invite_id }}</div>
      </div>
    </div>
    <div class="card mt-5 card-inputs">
      <div class="card-body">
        <div class="my-3 input-with-icon">
          <label for="name" class="form-label"><IconUserEdit :size="32" /></label>
          <input
            id="name"
            type="text"
            class="form-control"
            v-model="name"
            :placeholder="$t('placeholder.account_name')"
          />
        </div>
        <div class="mb-3 input-with-icon">
          <label for="email" class="form-label"><IconMail :size="32" /></label>
          <input
            id="email"
            type="text"
            class="form-control"
            v-model="email"
            :placeholder="$t('placeholder.email')"
          />
        </div>
      </div>
    </div>
    <div class="px-5 mt-5">
      <button class="btn btn-system w-100" @click="handleUpdateProfile">
        {{ $t("update") }}
      </button>
    </div>
  </div>
</template>
<script>
import { IconUserEdit } from "@tabler/icons-vue";
import { IconMail } from "@tabler/icons-vue";
import { IconChevronLeft } from "@tabler/icons-vue";
import { mapGetters, mapActions } from "vuex";

export default {
  components: { IconChevronLeft, IconUserEdit, IconMail },
  data() {
    return {
      name: "",
      email: "",
    };
  },
  computed: {
    ...mapGetters(["getUserData", "getUpdatedData"]),
    user() {
      return this.getUserData;
    },
    updatedData() {
      return this.getUpdatedData;
    },
  },
  mounted() {
    this.name = this.user.name;
    this.email = this.user.email;
  },
  methods: {
    ...mapActions(["updateProfile"]),
    handleUpdateProfile() {
      this.updateProfile({ email: this.email, name: this.name });
    },
  },
  watch: {
    updatedData(newVal) {
      if (newVal) {
        this.$router.push({ name: "account" });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.avatar {
  width: 100px;
  height: 100px;
  overflow: hidden;
  border-radius: 50%;
  border: 2px solid white;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
}

.card-inputs {
  background-color: #414d59;
}
</style>
