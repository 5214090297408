<template>
  <div class="row h-100">
    <div class="chat-list col-3 p-3">
      <template
        v-for="(conversation, index) in conversations"
        :key="index + 'conversation'"
      >
        <div
          v-if="!isHidden(conversation.messages)"
          class="d-flex align-items-center chat-group"
          @click="showConversation(conversation.messages)"
        >
          <div class="me-3 avatar-small">
            <img src="@/assets/logo.png" alt="logo" class="rounded-circle" />
          </div>
          <div class="flex-grow-1">
            <div class="w-100">
              <p class="fw-bold mb-1">{{ getSender(conversation.messages)?.name }}</p>
              <p class="text-secondary mb-0 last-message">
                {{ conversation.messages[0]?.content }}
              </p>
            </div>
          </div>
        </div>
      </template>
    </div>
    <div class="chat-component d-flex flex-column h-100 col px-0">
      <div
        class="d-flex flex-column justify-content-center align-items-start h-100"
        v-if="!messages.length"
      >
        <IconMessageCircleOff class="text-secondary ms-3" :size="100" />
        <h3 class="text-secondary ms-3">{{ $t("select_chat") }}</h3>
      </div>
      <div class="chat-container py-4 px-2" v-else>
        <template v-for="(message, index) in messages" :key="index + 'message'">
          <div class="message" :class="[message.sender?.id == user?.id ? 'you' : 'admin']">
            <div v-if="message.image" class="message-image">
              <img
                :src="assetUrl + message.image"
                alt=""
                @click="showImage(assetUrl + message.image)"
              />
            </div>
            <div class="message-content" v-if="message.content">
              {{ message.content }}
            </div>
            <small class="text-secondary">
              {{ moment(message.created_at).format("Y/MM/DD HH:mm:ss") }}
            </small>
          </div>
        </template>
      </div>
      <div v-if="messages.length">
        <div v-if="image">
          <div class="position-relative preview-img">
            <img :src="image" alt="" />
            <div class="remove" @click="removeImage">
              <IconX :size="16" />
            </div>
          </div>
        </div>
        <div class="message-send">
          <input
            type="file"
            ref="file"
            class="d-none"
            id="file"
            accept="image/*"
            @change="readUrl($event)"
          />
          <label for="file" class="select-image">
            <IconPhoto />
          </label>
          <input
            type="text"
            class="msg-input form-control"
            placeholder="Aa"
            v-model="message"
            @keyup.enter="handleSendMessage"
          />
          <div class="emoji-picker">
            <div class="btn-group dropup">
              <button
                type="button"
                class="btn emoji-picker-btn dropdown-toggle"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <IconMoodHappyFilled />
              </button>
              <div class="dropdown-menu p-0">
                <EmojiPicker
                  :native="true"
                  @select="onSelectEmoji"
                  :disable-skin-tones="true"
                />
              </div>
            </div>
          </div>
          <div class="send" @click="handleSendMessage">
            <IconSend />
          </div>
        </div>
      </div>
    </div>
  </div>
  <VueEasyLightbox :visible="visible" :imgs="imgs" @hide="onHide" />
</template>
<script>
import { socket } from "@/socket";
import { IconPhoto, IconSend } from "@tabler/icons-vue";
import { IconX } from "@tabler/icons-vue";
import { IconMessageCircleOff, IconMoodHappyFilled } from "@tabler/icons-vue";
import { mapActions, mapGetters } from "vuex";
import VueEasyLightbox from "vue-easy-lightbox/external-css";
import EmojiPicker from "vue3-emoji-picker";
import moment from "moment";

export default {
  components: {
    IconMessageCircleOff,
    IconSend,
    IconPhoto,
    IconX,
    VueEasyLightbox,
    IconMoodHappyFilled,
    EmojiPicker,
  },
  setup() {
    return {
      moment,
    };
  },
  computed: {
    ...mapGetters(["getDataConversations", "getUserData", "getDataMessages"]),
    conversations() {
      return this.getDataConversations;
    },
    user() {
      return this.getUserData;
    },
    messages() {
      return this.getDataMessages;
    },
  },
  data() {
    return {
      message: "",
      receiver: undefined,
      image: null,
      file: null,
      visible: false,
      imgs: "",
    };
  },
  beforeMount() {
    this.getChatList();
    this.setMessages([]);
  },
  mounted() {
    socket.on("message", (message) => {
      this.addMessage(message);
    });
  },
  beforeUnmount() {
    socket.off("message");
  },
  methods: {
    ...mapActions(["getChatList", "sendMessages", "setMessages", "addMessage"]),
    getSender(conversationMessages) {
      const lastMessage = conversationMessages[0];
      if (this.user && lastMessage?.sender?.id === this.user?.id) {
        return lastMessage.receiver;
      } else {
        return lastMessage.sender;
      }
    },
    showConversation(messages) {
      this.setMessages(messages);
      this.receiver = this.getSender(messages);
    },
    handleSendMessage() {
      const validateMessage = this.message.replace(/\s{2,}/g, " ");
      if ((validateMessage && validateMessage != " ") || this.file) {
        const formData = new FormData();
        formData.append("receiver_id", this.receiver.id);
        formData.append("content", validateMessage);
        if (this.file) {
          formData.append("image", this.file);
        }
        this.sendMessages(formData);
      }
      this.message = "";
      this.removeImage();
    },
    readUrl(event) {
      const input = event.target;
      if (input.files?.[0]) {
        const file = input.files[0];
        let reader = new FileReader();
        reader.onloadend = () => {
          this.image = reader.result;
        };
        reader.readAsDataURL(file);
        this.file = file;
      }
    },
    removeImage() {
      this.image = null;
      this.file = null;
      this.$refs.file.value = null;
    },
    showImage(image) {
      this.imgs = image;
      this.visible = true;
    },
    onHide() {
      this.visible = false;
      this.imgs = "";
    },
    onSelectEmoji(emoji) {
      this.message += emoji.i;
    },
    isHidden(messages) {
      const message = messages[0];
      return !message?.sender || !message?.receiver
    },
  },
};
</script>
<style lang="scss" scoped>
.chat-list {
  height: 100%;
  border-right: 2px solid #ccc;
  overflow-y: auto;
  overflow-x: hidden;
}

.chat-group {
  border-bottom: 1px solid #ccc;
  padding: 8px 16px;
  cursor: pointer;
  overflow: hidden;

  &:hover {
    background-color: #dfdfdf;
  }
}

.last-message {
  white-space: normal;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 100%;
}

.chat-container,
.chat-component {
  background-color: #ccc;
}
</style>
